import {ScrollProvider} from "./components/Scroll/Scroll";
import BackTop from "./pages/components/BackTop/BackTop";
import LanguageButton from "./pages/components/Lan/LanguageButton";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import './lang';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getUserByToken} from "./store/slices/userSlice";
import FullLoading from "./components/LoadingComponents/FullLoading";
import LoginPage from "./pages/Login/LoginPage";
import Platform from "./pages/Platform/Platform";
import {REACT_APP_NODE_ENV} from "./constants/env";
import {disableReactDevTools} from "@fvilers/disable-react-devtools";
import FormPage from "./pages/Form/FormPage";
import Contacts from "./pages/Contacts/Contacts";
import CertificatesPage from "./pages/Certificates/CertificatesPage";
import SignResult from "./pages/SignResult/SignResult";
import DocumentPage from "./pages/Document/DocumentPage";
import MBAPage from "./pages/MBA/MBAPage";
import CalculationPage from "./pages/Calculation/CalculationPage";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import NewHomePage from "./pages/Home/NewHomePage";
import MBADPage from "./pages/MBA/MBADPage";
import FoundationPage from "./pages/Foundation/FoundationPage";
import AdditionalMasterPage from "./pages/AdditionalMaster/AdditionalMasterPage";
import GeneralEnglishPage from "./pages/GeneralEnglish/GeneralEnglishPage";
import RoboticsPage from "./pages/Robotics/RoboticsPage";

if (REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
}
function App() {
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserByToken());
    }, []);

    if (isLoading) {
        return <FullLoading/>;
    }

    return (
        <ScrollProvider>
            <BackTop/>
            <LanguageButton/>
            <Router>
                <Routes>
                    <Route path='/' element={<NewHomePage/>}/>
                    <Route path='/contacts' element={<Contacts/>}/>
                    <Route path='/about-us' element={<AboutUsPage/>}/>
                    <Route path='/calculation' element={<CalculationPage/>}/>
                    <Route path='/signature/:signatureId' element={<SignResult/>}/>
                    <Route path='/document/:documentId' element={<DocumentPage/>}/>
                    <Route path='/certificates' element={<CertificatesPage/>}/>
                    <Route path='/mba-executive' element={<MBAPage/>}/>
                    <Route path='/mba-fintech' element={<MBADPage/>}/>
                    <Route path='/foundation' element={<FoundationPage/>}/>
                    <Route path='/robotics' element={<RoboticsPage/>}/>
                    <Route path='/general-english' element={<GeneralEnglishPage/>}/>
                    <Route path='/additional-master' element={<AdditionalMasterPage/>}/>
                    <Route path='/login' element={user === null ? <LoginPage/> : <Navigate to="/p/applications" replace />}/>
                    <Route path='/form/:formId' element={<FormPage/>}/>
                    <Route path='/p/*' element={user !== null ? <Platform/> : <Navigate to="/login" replace />}/>
                </Routes>
            </Router>
        </ScrollProvider>
    );
}

export default App;
