import {API_BASE_URL} from "../constants/api";
import axios from "axios";
import {ACCESS_TOKEN} from "../constants/auth";

const DocumentService = {};

DocumentService.getDocumentById = async function (id) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/document/get/${id}`,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

DocumentService.getResourceById = async function (id) {

    return axios({
        method: "get",
        url: API_BASE_URL + "/api/document/resource/" + id,
        responseType: "blob"
    });
}

export default DocumentService;