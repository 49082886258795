import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {Flex, Tabs} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import SendUserSign from "./Creation/SendUserSign";
import UserSignList from "./List/UserSignList";
import {getUserByToken} from "../../../../store/slices/userSlice";
import UserSignStudentList from "./List/UserSignStudentList";
import UserSignDirectorList from "./List/UserSignDirectorList";
import UserSignRectorList from "./List/UserSignRectorList";

const UserSignPage = () => {
    const dispatch = useDispatch();

    const {user} = useSelector(state => state.user)

    if (user === null) {
        dispatch(getUserByToken());
    }
    const onChangeTabs = async (key) => {
        if (key === '1') {

        }
        if (key === '2') {

        }
    };
    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: user?.role?.roleName === 'admin' ? <UserSignList/> :
                (user?.role?.roleName === 'director' ? <UserSignDirectorList/> :
                        (user?.role?.roleName === 'rector' ? <UserSignRectorList/> : <UserSignStudentList/>)
                )
        },
        user?.role?.roleName === 'admin' && {
            key: '3',
            label: <IntlMessage id={'userSignPage.sendStudent'}/>,
            children: <SendUserSign/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'userSignPage'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default UserSignPage;