import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";
import ApplicationService from "./ApplicationService";
import SignatureService from "./SignatureService";

const UserSignService = {};
UserSignService.sendUserSign = async function (body) {
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/user-sign/send`,
        data: body,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserSignService.getAllUserSignsPageable = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/user-sign/get`,
        params: {
            page: page - 1,
            limit: limit,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

UserSignService.getStudentUserSignsPageable = async function (id, page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/user-sign/get`,
        params: {
            id: id,
            page: page - 1,
            limit: limit,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

UserSignService.getDirectorUserSignsPageable = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/user-sign/getDirector`,
        params: {
            page: page - 1,
            limit: limit,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

UserSignService.getRectorUserSignsPageable = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/user-sign/getRector`,
        params: {
            page: page - 1,
            limit: limit,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

UserSignService.signUserSignByRector = async function (id) {
    let data = new FormData();
    data.append("id", id);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/user-sign/rector-sign`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserSignService.convertToXML = async function (id) {
    let data = new FormData();
    data.append("id", id);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/user-sign/to-xml`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserSignService.saveSignatureInUserSign = async function (xml, from, id) {
    let data = new FormData();
    data.append("xml", xml);
    data.append("from", from);
    data.append("id", id);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/user-sign/sign`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserSignService.approveUserSignByDirector = async function (id) {
    let data = new FormData();
    data.append("id", id);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/user-sign/approve-director`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default UserSignService;