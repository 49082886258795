import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import GroupService from "../../services/GroupService";
import JournalService from "../../services/JournalService";

let initialState = {
    group: [],
    journal: [],
    isLoading: false,
    hasMore: false,
    error: null,
}
export const getGroup = createAsyncThunk(
    'getGroup',
    async ({id}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await GroupService.getGroup(id);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const getJournal = createAsyncThunk(
    'getJournal',
    async ({id}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await JournalService.getJournal(id);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const teacherGroupSlice = createSlice({
    name: 'teacherGroupSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGroup.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getGroup.fulfilled, (state, action) => {
                state.isLoading = false;
                state.group = action.payload.data;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getGroup.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.group = [];
                state.hasMore = false;
            })
            .addCase(getJournal.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getJournal.fulfilled, (state, action) => {
                state.isLoading = false;
                state.journal = action.payload.data;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getJournal.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.journal = [];
                state.hasMore = false;
            })
    }
});

export const {} = teacherGroupSlice.actions;

export default teacherGroupSlice.reducer;