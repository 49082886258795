import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, Flex, Form, Image, Input, message, Modal, Popconfirm, Radio, Space, Table, Tag} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {clrs} from "../../../../../constants/colors";
import TableWithPagination from "../../../../../components/TableWithPagination/TableWithPagination";
import {LocalName} from "../../../../../utils/LocalName";
import {changeCurrentPage} from "../../../../../store/slices/tableController/ContractListTableControllerSlice";
import {getAllContract} from "../../../../../store/slices/ContractListSlice";
import classes from "../../Applications/application.module.css";
import cl from './style.module.css';
import ContractService from "../../../../../services/ContractService";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {EyeOutlined} from "@ant-design/icons";
import {Document, Page} from "react-pdf";
import MyText from "../../../../../components/UI/Text/MyText";
import {API_BASE_URL} from "../../../../../constants/api";
import HorizontalDivider from "../../../../../components/Divider/HorizontalDivider";
import {getAllContractCategories} from "../../../../../store/slices/ContractCategoriesListSlice";
import GreenButton from "../../../../../components/UI/Buttons/GreenButton";
import OrderService from "../../../../../services/OrderService";
import UserService from "../../../../../services/UserService";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";

const ContractsList = () => {
    const {contracts, hasMore, isLoading} = useSelector(state => state.contractList);

    const {currentPage, pageSize} = useSelector(state => state.contractListTableController);
    const contractCategoriesList = useSelector(state => state.contractCategoriesList);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openViewContractModal, setOpenViewContractModal] = useState(false);
    const [viewContract, setViewContract] = useState(null);

    const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const [filterForm] = Form.useForm();
    const name = Form.useWatch('name', filterForm);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const fetchData = async (params) => {
        return dispatch(getAllContract({...params, name, categoryId: selectedCategoryId}));
    }

    useEffect(() => {
        dispatch(getAllContractCategories())
    }, [])

    useEffect(() => {
        dispatch(getAllContract({page: currentPage, limit: pageSize, name, categoryId: selectedCategoryId}))
    }, [navigate, selectedCategoryId]);

    const saveCurrentPageSettings = (page, pageSize) => {
        dispatch(changeCurrentPage({page: page, pageSize: pageSize}));
    };

    async function handleChangeStatus(id, status) {
        message.loading(<IntlMessage id={'changing'}/>, 0);
        await ContractService.changeStatus(id, status)
            .then(() => {
                message.destroy();
                message.success(<IntlMessage id={'changed'}/>, 5);
                dispatch(getAllContract({page: currentPage, limit: pageSize, name, categoryId: selectedCategoryId}))
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    async function handleDownloadStudentsList() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await ContractService.getStudentsListAsExcel()
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                triggerDownload(r.data, "contract_students.xlsx")
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    function triggerDownload(blob, filename) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Set the download attribute with a filename
        document.body.appendChild(link);
        link.click();

        // Clean up and revoke the URL after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async function saveUsersByContract() {
        setIsSaveLoading(true);
        await UserService.saveUsersByContract(selectedRowKeys)
            .then((r) => {
                // message.success(<IntlMessage id={'success'}/>, 3)
                message.success("Из " +selectedRowKeys.length + " договоров, было создана " + r.data.length + " пользователей", 3)
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setSelectedRowKeys([])
                setIsSaveLoading(false);
            })
    }

    const columns = [
        {
            title: '',
            render: (_, record) => (
                <Button onClick={() => {
                    setOpenViewContractModal(true);
                    setViewContract(record);
                }}>
                    <EyeOutlined/>
                </Button>
            ),
            width: '3%',
        },
        {
            title: <IntlMessage id={'numberWithSymbol'}/>,
            render: (_, record) => (
                <p>{record.realNumber}</p>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'date'}/>,
            render: (_, record) => (
                <p>{new Date(record.createdAt).toLocaleDateString()}</p>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'fullNameListener'}/>,
            render: (_, record) => (
                <p>{record.fullName}</p>
            ),
            // width: '50%',
        },
        {
            title: <IntlMessage id={'telNumber'}/>,
            render: (_, record) => (
                <p>{record.form?.userData?.telNumber}</p>
            ),
            // width: '50%',
        },
        {
            title: <IntlMessage id={'categoryCourse'}/>,
            render: (_, record) => (
                <p>{LocalName.getName(record.contractCategory)}</p>
            ),
            width: '15%',
        },
        {
            title: <IntlMessage id={'status'}/>,
            render: (_, record) => (
                <Flex align={"center"} justify={"center"} gap={20} style={{width: "100%"}}>
                    {record.contractStatus === 'NOT_SIGNED' &&
                        <Tag color={clrs.grey}><IntlMessage id={'notSigned'}/></Tag>
                    }
                    {record.contractStatus === 'SIGNED' &&
                        <Tag color={clrs.blackerBlue}><IntlMessage id={'signed'}/></Tag>
                    }
                </Flex>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'actions'}/>,
            render: (_, record) => (
                <>
                    {record.contractStatus === 'NOT_SIGNED' &&
                        <Popconfirm
                            // icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={<IntlMessage id={'signed'}/>}
                            description={<IntlMessage id={'doYouWantChangeContractStatus'}/>}
                            onConfirm={() => {
                                handleChangeStatus(record.id, "SIGNED")
                            }}
                            className={cl.changeBlock}
                            okText={<IntlMessage id={'yes'}/>}
                            cancelText={<IntlMessage id={'no'}/>}
                        >
                            <p className={cl.changeBtn}><IntlMessage id={'signed'}/></p>
                        </Popconfirm>
                    }
                    {record.contractStatus === 'SIGNED' &&
                        <Popconfirm
                            // icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            title={<IntlMessage id={'notSigned'}/>}
                            description={<IntlMessage id={'doYouWantChangeContractStatus'}/>}
                            onConfirm={() => {
                                handleChangeStatus(record.id, "NOT_SIGNED")
                            }}
                            className={cl.changeBlock}
                            okText={<IntlMessage id={'yes'}/>}
                            cancelText={<IntlMessage id={'no'}/>}
                        >
                            <p className={cl.changeBtn}><IntlMessage id={'notSigned'}/></p>
                        </Popconfirm>
                    }
                </>
            ),
            width: '15%',
        },
    ];

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={20}>
                <GreyButton onClick={() => {
                    // setSelectedCategoryId(null);
                    dispatch(getAllContract({page: currentPage, limit: pageSize, name, categoryId: selectedCategoryId}))
                }} backgroundColor={clrs.whiter} fontColor={clrs.black}><IntlMessage id={'update'}/></GreyButton>
                <GreyButton onClick={handleDownloadStudentsList}><IntlMessage id={'download'}/></GreyButton>
                {
                    hasSelected &&
                    <GreenButton onClick={() => saveUsersByContract()}>
                        <IntlMessage id={'createAccounts'}/>
                    </GreenButton>
                }
            </Flex>
            <BlockLoading isLoading={isSaveLoading}/>
            <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20}>
                <Form style={{width: "100%"}} onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        dispatch(getAllContract({page: currentPage, limit: pageSize, name, categoryId: selectedCategoryId}))
                    }
                }} form={filterForm} className={classes.form}>
                    <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20}>
                        <Flex style={{width: "50%"}} vertical align={"flex-start"} justify={"flex-start"}>
                            <Divider className={classes.divider} orientation="left">
                                <IntlMessage id={'fullName'}/>
                            </Divider>
                            <Form.Item name="name" className={classes.formInputBlock}>
                                <Input className={classes.formInput} placeholder={"Азамат"}/>
                            </Form.Item>
                        </Flex>
                        <Flex style={{width: "50%"}} vertical align={"flex-start"} justify={"flex-start"}>
                            <Divider className={classes.divider} orientation="left"><IntlMessage
                                id={'category'}/></Divider>
                            <Form.Item name="categoryId">
                                <Radio.Group
                                    value={selectedCategoryId}
                                    defaultValue={null}
                                    onChange={(e) => {setSelectedCategoryId(e.target.value)}}>
                                    <Space wrap={"wrap"}>
                                        <Radio value={null} key="all">
                                            <IntlMessage id={"allCategories"}/>
                                        </Radio>
                                        {
                                            contractCategoriesList.categories.map((category, i) => {
                                                return (
                                                    <Radio value={category.id}
                                                           key={category.id}
                                                           checked={selectedCategoryId === category.id}
                                                    >{LocalName.getName(category)}</Radio>
                                                )
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Flex>
                    </Flex>
                </Form>
            </Flex>

            <MyTable
                isLoading={isLoading}
                dataSource={contracts}
                columns={columns}
                fetchData={fetchData}
                saveCurrentPage={saveCurrentPageSettings}
                initialPage={currentPage}
                initialPageSize={pageSize}
                hasMore={hasMore}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
            />

            <Modal
                width={800}
                maskClosable={true}
                title={<IntlMessage id={'contractsPage.details'}/>}
                open={openViewContractModal}
                okText={<IntlMessage id={'save'}/>}
                cancelText={<IntlMessage id={'close'}/>}
                style={{
                    top: 20,
                }}
                onOk={() => {
                    setOpenViewContractModal(false)
                }}
                onCancel={() => {
                    setOpenViewContractModal(false)
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setOpenViewContractModal(false)
                    }}>
                        OK
                    </Button>
                ]}
            >
                {
                    viewContract &&
                    <Flex style={{width: "100%", position: "relative"}} vertical gap={20}>
                        <Flex style={{
                            width: "100%",
                            position: "relative",
                            padding: 20,
                            border: `1px solid ${clrs.whiter2}`,
                            borderRadius: 10
                        }} gap={10} vertical>
                            <MyText strong size={"small"}>
                                <IntlMessage id={'personalDocuments'}/>
                            </MyText>
                            <FileList files={viewContract.form?.idCardFiles}/>
                            <HorizontalDivider/>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative"}} vertical gap={10}>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'firstNameStudentInDoc'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.firstName
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'middleNameStudentInDoc'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.middleName
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'lastNameStudentInDoc'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.lastName
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'birthDay'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.birthDay
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'address'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.address
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'telNumber'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.telNumber
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'email'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.email
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'iin'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.iin
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'idCardNumber'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.idCardNumber
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'idCardFromWhom'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.idCardFromWhom
                                    }
                                </MyText>
                            </Flex>
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'idCardDate'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewContract.form?.userData?.idCardDate
                                    }
                                </MyText>
                            </Flex>
                            <HorizontalDivider/>
                            {
                                viewContract.form?.userData?.yes18 === false &&
                                <>
                                    <Flex gap={5} wrap={"wrap"}>
                                        <MyText strong size={"small"}>
                                            <IntlMessage id={'fullNameParentInDoc'}/>
                                        </MyText>
                                        <span>:</span>
                                        <MyText size={"small"}>
                                            {
                                                viewContract.form?.userData?.parentFullName
                                            }
                                        </MyText>
                                    </Flex>
                                    <Flex gap={5} wrap={"wrap"}>
                                        <MyText strong size={"small"}>
                                            <IntlMessage id={'telNumberParent'}/>
                                        </MyText>
                                        <span>:</span>
                                        <MyText size={"small"}>
                                            {
                                                viewContract.form?.userData?.parentTelNumber
                                            }
                                        </MyText>
                                    </Flex>
                                    <Flex gap={5} wrap={"wrap"}>
                                        <MyText strong size={"small"}>
                                            <IntlMessage id={'iin_parent'}/>
                                        </MyText>
                                        <span>:</span>
                                        <MyText size={"small"}>
                                            {
                                                viewContract.form?.userData?.parentIin
                                            }
                                        </MyText>
                                    </Flex>
                                    <Flex gap={5} wrap={"wrap"}>
                                        <MyText strong size={"small"}>
                                            <IntlMessage id={'idCardNumber_parent'}/>
                                        </MyText>
                                        <span>:</span>
                                        <MyText size={"small"}>
                                            {
                                                viewContract.form?.userData?.parentIdCardNumber
                                            }
                                        </MyText>
                                    </Flex>
                                    <Flex gap={5} wrap={"wrap"}>
                                        <MyText strong size={"small"}>
                                            <IntlMessage id={'idCardFromWhom_parent'}/>
                                        </MyText>
                                        <span>:</span>
                                        <MyText size={"small"}>
                                            {
                                                viewContract.form?.userData?.parentIdCardFromWhom
                                            }
                                        </MyText>
                                    </Flex>
                                    <Flex gap={5} wrap={"wrap"}>
                                        <MyText strong size={"small"}>
                                            <IntlMessage id={'idCardDate_parent'}/>
                                        </MyText>
                                        <span>:</span>
                                        <MyText size={"small"}>
                                            {
                                                viewContract.form?.userData?.parentIdCardDate
                                            }
                                        </MyText>
                                    </Flex>
                                </>
                            }
                        </Flex>
                        {
                            (viewContract.form?.category?.nameEng !== 'General English' && viewContract.form?.category?.nameEng !== 'Mathematics') &&
                            <Flex style={{
                                width: "100%",
                                position: "relative",
                                padding: 20,
                                border: `1px solid ${clrs.whiter2}`,
                                borderRadius: 10
                            }} gap={10} vertical>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'preferentialDocuments'}/>
                                </MyText>
                                <FileList files={viewContract.form?.preferentialFiles}/>
                                <HorizontalDivider/>
                            </Flex>
                        }
                        <Flex style={{
                            width: "100%",
                            position: "relative",
                            padding: 20,
                            border: `1px solid ${clrs.whiter2}`,
                            borderRadius: 10
                        }} gap={10} vertical>
                            <MyText strong size={"small"}>
                                <IntlMessage id={'otherDocuments'}/>
                            </MyText>
                            <FileList files={viewContract.form?.otherFiles}/>
                            <HorizontalDivider/>
                        </Flex>
                    </Flex>
                }
            </Modal>
        </Flex>
    );
};

    const FileList = ({files}) => {
        const imgExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.heic', '.heif'];
        const pdfExtension = '.pdf';

        const {formId} = useParams();
        const dispatch = useDispatch();

        const [open, setOpen] = useState(false);
        const [pdfUrl, setPdfUrl] = useState('');

        const [numPages, setNumPages] = useState();
        const [pageNumber, setPageNumber] = useState(1);

        useEffect(() => {
            setPageNumber(1);
        }, [open]);

        function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
            setNumPages(numPages);
        }

        if (!files || files?.length === 0) {
            return '';
        }
        return (
            <Flex align={"flex-start"} vertical justify={"flex-start"} gap={10}
                  style={{width: "100%", position: "relative"}}>

                <Modal
                    maskClosable={true}
                    width={800}
                    open={open}
                    style={{
                        top: 20,
                    }}
                    onOk={() => {
                        setOpen(false)
                    }}
                    onCancel={() => {
                        setOpen(false)
                    }}
                    footer={[
                        <Button key="submit" type="primary" onClick={() => {
                            setOpen(false)
                        }}>
                            OK
                        </Button>
                    ]}
                >
                    <Flex align={"center"} justify={"center"}
                          style={{overflowX: "auto", overflowY: "hidden", width: "100%", position: "relative"}}>
                        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} width={700}/>
                        </Document>
                        <Flex style={{
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            top: 10,
                            zIndex: 100,
                            padding: "10px 20px",
                            backgroundColor: "white",
                            borderRadius: 10,
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                        }} gap={15} justify={"space-between"} align={"center"}>
                            <Button disabled={pageNumber === 1} onClick={() => {
                                setPageNumber(pageNumber - 1)
                            }}>
                                {'<'}
                            </Button>
                            <MyText size={"small"}>
                                {pageNumber} / {numPages}
                            </MyText>
                            <Button disabled={pageNumber === numPages} onClick={() => {
                                setPageNumber(pageNumber + 1)
                            }}>
                                {'>'}
                            </Button>
                        </Flex>
                    </Flex>

                </Modal>

                <Flex align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={15}
                      style={{width: "100%", position: "relative"}}>
                    {
                        files?.map(file => {
                            if (imgExtensions.includes(file.extension.toLowerCase())) {
                                return (
                                    <Flex style={{width: 100, height: 100, position: "relative"}}>
                                        <Image wrapperStyle={{maxHeight: 100, overflow: "hidden"}} width={100}
                                               src={API_BASE_URL + "/api/file/get/" + file.fileName}/>
                                    </Flex>

                                )
                            }
                        })
                    }
                </Flex>

                <HorizontalDivider/>
                <Flex align={"flex-start"} justify={"flex-start"} vertical gap={5}
                      style={{width: "100%", position: "relative"}}>
                    {
                        files?.map(file => {
                            if (!imgExtensions.includes(file.extension.toLowerCase())) {
                                return (
                                    <Flex align={"center"} justify={"flex-start"} gap={15}>
                                        {
                                            file.extension.toLowerCase() === pdfExtension &&
                                            <Flex onClick={() => {
                                                setPdfUrl(API_BASE_URL + "/api/file/get/" + file.fileName);
                                                setOpen(true);
                                            }} align={"center"} justify={"center"} className={classes.eyeFileItem}>
                                                <EyeOutlined/>
                                            </Flex>
                                        }

                                        <Link target={"_blank"} to={API_BASE_URL + "/api/file/get/" + file.fileName}>
                                            <MyText key={file.id} className={classes.fileItem} size={"small"}>
                                                {file.label}
                                            </MyText>
                                        </Link>
                                    </Flex>
                                )
                            }
                        })
                    }
                </Flex>

            </Flex>
        )
    }

const MyTable = ({
                     isLoading,
                     dataSource,
                     columns,
                     fetchData,
                     saveCurrentPage,
                     initialPage = 1,
                     initialPageSize = 100,
                     hasMore = true,
                     expandable,
                     selectedRowKeys,
                     setSelectedRowKeys
                 }) => {
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [currentPage, setCurrentPage] = useState(initialPage);
    const [currentPageSize, setCurrentPageSize] = useState(initialPageSize);
    const [hasNextPage, setHasNextPage] = useState(hasMore);

    useEffect(() => {
        setHasNextPage(hasMore);
    }, [hasMore]);

    useEffect(() => {
        setCurrentPage(initialPage);
        setCurrentPageSize(initialPageSize);
    }, [initialPage, initialPageSize]);

    // Handle pagination change by updating the current page and page size.
    // Then, fetch data using the provided fetchData function.
    const handlePaginationChange = useCallback(
        (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
            fetchData({page, limit: pageSize}).then((result) => {
                const hasMore = result.payload.hasMore;
                setHasNextPage(hasMore);
            });
            saveCurrentPage(page, pageSize);
        },
        [fetchData],
    );

    return (
        <Table
            rowSelection={rowSelection}
            bordered
            columns={columns}
            dataSource={dataSource}
            pagination={{
                current: currentPage,
                total: hasNextPage
                    ? currentPage * currentPageSize + 1
                    : currentPage * currentPageSize,
                defaultPageSize: initialPageSize,
                showSizeChanger: true,
                pageSize: currentPageSize,
                pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                onChange: handlePaginationChange,
                onShowSizeChange: handlePaginationChange,
            }}
            rowKey={record => record.id}
            loading={isLoading}
            style={{
                width: "100%",
            }}
            scroll={{x: 800}}
        />
    )
}

export default ContractsList;