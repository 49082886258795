import React, {useEffect} from 'react';
import classes from './PlatformNav.module.css';
import NavItem from "./NavItem";
import HorizontalDivider from "../../../../components/Divider/HorizontalDivider";
import NavLan from "./NavLan";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {useDispatch, useSelector} from "react-redux";
import {
    EditFilled,
    FileTextOutlined,
    HomeOutlined, LineChartOutlined,
    LogoutOutlined,
    ProfileFilled,
    ProfileOutlined, ReadOutlined, SettingOutlined, TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useLocation} from "react-router-dom";
import LogoBlack from "../../../components/Logo/LogoBlack";
import {Flex} from "antd";

const NavMenu = () => {
    const {user} = useSelector(state => state.user);

    return (
        <div className={classes.menu}>
            {/*<NavLan/>*/}
            {/*<BrainBrewText/>*/}
            <Flex style={{width: "100%", paddingTop: 20}} align={"center"} justify={"center"}>
                <LogoBlack/>
            </Flex>

            <div style={{paddingBottom: 25}}></div>
            <HorizontalDivider/>
            <RoleNav/>
            <HorizontalDivider/>
            {
                user &&
                <NavItem to={'logout'} icon={<LogoutOutlined />} text={<IntlMessage id={'logout'}/>}/>
            }
        </div>
    );
};

const RoleNav = () => {
    const {user, isLoading} = useSelector(state => state.user);

    if (user?.role.roleName === 'admin') {
        return (
            <>
                {/*<NavItem to={'profile'} icon={<UserOutlined />} text={<IntlMessage id={'profile'}/>}/>*/}
                <NavItem to={'applications'} loc={'/p/applications'} icon={<FileTextOutlined />} text={<IntlMessage id={'applications'}/>}/>
                <NavItem to={'courses'} loc={'/p/courses'} icon={<ReadOutlined />} text={<IntlMessage id={'courses'}/>}/>
                <NavItem to={'contracts'} loc={'/p/contracts'} icon={<EditFilled />} text={<IntlMessage id={'contracts'}/>}/>
                <NavItem to={'globalData'} loc={'/p/globalData'} icon={<ProfileOutlined />} text={<IntlMessage id={'globalData'}/>}/>
                {/*<NavItem to={'teachers'} loc={'/p/teachers'} icon={<UserOutlined />} text={<IntlMessage id={'teachers'}/>}/>*/}
                <NavItem to={'orders'} loc={'/p/orders'} icon={<UserOutlined />} text={<IntlMessage id={'orders'}/>}/>
                <NavItem to={'users'} loc={'/p/users'} icon={<UserOutlined />} text={<IntlMessage id={'users'}/>}/>
                <NavItem to={'groups'} loc={'/p/groups'} icon={<TeamOutlined />} text={<IntlMessage id={'ordersPage.groups'}/>}/>
                <NavItem to={'user-sign'} loc={'/p/user-sign'} icon={<FileTextOutlined />} text={<IntlMessage id={'userSignPage'}/>}/>
            </>
        )
    }

    if (user?.role.roleName === 'director' || user?.role.roleName === 'rector') {
        return (
            <>
                <NavItem to={'user-sign'} loc={'/p/user-sign'} icon={<FileTextOutlined />} text={<IntlMessage id={'userSignPage'}/>}/>
                <NavItem to={'applications'} loc={'/p/applications'} icon={<EditFilled />} text={<IntlMessage id={'contracts'}/>}/>
            </>
        )
    }

    if (user?.role.roleName === 'teacher') {
        return (
            <>
                <NavItem to={'groups'} loc={'/p/groups'} icon={<TeamOutlined />} text={<IntlMessage id={'ordersPage.groups'}/>}/>
                <NavItem to={'settings'} loc={'/p/settings'} icon={<SettingOutlined />} text={<IntlMessage id={'settings'}/>}/>            </>
        )
    }

    return (
        <>
            <NavItem to={'user-sign'} loc={'/p/user-sign'} icon={<FileTextOutlined />} text={<IntlMessage id={'userSignPage'}/>}/>
            <NavItem to={'settings'} loc={'/p/settings'} icon={<SettingOutlined />} text={<IntlMessage id={'settings'}/>}/>
        </>
    );
};

export default NavMenu;

