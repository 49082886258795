import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";


const svg = () => {
    return (
        <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd"
                  d="m15.2 16.0833h-3.1583c-2.23218 0-4.0417-1.8095-4.0417-4.0416 0-2.23218 1.80952-4.0417 4.0417-4.0417h5.9166c2.2322 0 4.0417 1.80952 4.0417 4.0417 0 2.5829-1.8078 4.3033-4.2688 5.4902-.8722.4206-1.756.7351-2.5312.9647zm-2 4.9167s.8163-.1102 2-.4259c3.122-.8326 8.8-3.0943 8.8-8.5324 0-3.33675-2.7049-6.0417-6.0417-6.0417h-5.9166c-3.33675 0-6.0417 2.70495-6.0417 6.0417 0 3.3367 2.70495 6.0416 6.0417 6.0416h1.1583zm3.3 7c0 1.1054-.8946 2-2 2s-2-.8946-2-2 .8946-2 2-2 2 .8946 2 2zm-2 4c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-6.5 8v-1.7333c-.00002-.009-.00007-.03.02596-.0804.0318-.0616.10536-.1706.26255-.3162.32935-.3052.88124-.6385 1.64391-.9452 1.52888-.6149 3.40018-.9249 4.56758-.9249s3.0387.31 4.5676.9249c.7626.3067 1.3145.64 1.6439.9452.1572.1456.2307.2546.2625.3162.0261.0504.026.0714.026.0804v1.7333zm-2-1.7333c0-2.8374 5.6631-4.2667 8.5-4.2667s8.5 1.4293 8.5 4.2667v3.7333h-17zm27.5-8.2667c1.1054 0 2-.8946 2-2s-.8946-2-2-2-2 .8946-2 2 .8946 2 2 2zm4-2c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4zm-10.5 12v-1.7333c0-.009-.0001-.03.026-.0804.0318-.0616.1053-.1706.2625-.3162.3294-.3052.8813-.6385 1.6439-.9452 1.5289-.6149 3.4002-.9249 4.5676-.9249s3.0387.31 4.5676.9249c.7626.3067 1.3145.64 1.6439.9452.1572.1456.2307.2546.2625.3162.0261.0504.026.0714.026.0804v1.7333zm-2-1.7333c0-2.8374 5.6631-4.2667 8.5-4.2667s8.5 1.4293 8.5 4.2667v3.7333h-17zm10.9583-21.1834h-2.3583v2.3124c-.5828-.2101-1.2182-.4796-1.8463-.8203-2.0827-1.13-3.7537-2.8266-3.7537-5.5337 0-2.2322 1.8095-4.0417 4.0417-4.0417h3.9166c2.2322 0 4.0417 1.8095 4.0417 4.0417 0 2.2321-1.8095 4.0416-4.0417 4.0416zm-2.3583 4.4225c1.1709.3683 2 .4942 2 .4942v-2.9167h.3583c3.3368 0 6.0417-2.7049 6.0417-6.0416 0-3.33675-2.7049-6.0417-6.0417-6.0417h-3.9166c-3.3368 0-6.0417 2.70495-6.0417 6.0417 0 5.2959 4.7866 7.5794 7.6 8.4641z"
                  fill="#333" fillRule="evenodd"/>
        </svg>
    );
};

const DiscussionIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default DiscussionIcon;