import React from 'react';
import Header from "../components/Header/Header";
import {Col, Flex, Image, Row} from "antd";
import PartnersSection from "../Home/components/PartnersSection/PartnersSection";
import Footer from "../components/Footer/Footer";
import {useWindowSize} from "@uidotdev/usehooks";
import img2 from './Ресурс 26@3x.png';
import img3 from './iqaa.svg';
import exec2 from './Ресурс 19@3x.png';
import exec4 from '../../images/exec4.png';
import teacher1 from './ad.png';
import teacher2 from './gt.png';
import teacher3 from './ml.png';
import teacher4 from './pe.png';
import teacher5 from './ay.png';
import teacher6 from './ab.png';
import styles from './mba.module.css'
import {clrs} from "../../constants/colors";
import {CalendarOutlined, FormOutlined, GlobalOutlined, TeamOutlined} from "@ant-design/icons";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import MyText from "../../components/UI/Text/MyText";
import NewBanner from "../Home/components/Banner/NewBanner";
import {Fade} from "react-awesome-reveal";

const MbaDPage = () => {
    const size = useWindowSize();

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    {/*<Banner/>*/}
                    <NewBanner/>
                    <Flex justify={"space-between"} vertical={size.width <= 1024}
                          style={{width: "100%", backgroundColor: "white"}} gap={"small"}>
                        <Flex vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <h1 style={{alignSelf: size.width <= 1024 ? "center" : "normal"}}>
                                    <MyText stronger size={"larger"} style={{color: clrs.blackerBlue}}>
                                        <IntlMessage id={"mba-fintech"}/></MyText>
                                </h1>
                                <h1 style={{alignSelf: size.width <= 1024 ? "center" : "normal"}}>
                                    <MyText stronger size={"larger"}><IntlMessage id={"mba-fin-h1"}/></MyText>
                                </h1>
                                <h3>
                                    <MyText medium size={"bigger"}><IntlMessage id={"mba-fin-h2"}/></MyText>
                                </h3>
                            </Fade>


                        </Flex>
                        <Flex align={"center"} gap={20} vertical={size.width <= 1024}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                <img src={img2} alt="" width={100}/>
                                <img src={img3} alt="" width={100}/>
                            </Fade>

                        </Flex>
                    </Flex>
                    <Flex gap={16} wrap={"wrap"} align={"center"} justify={"center"}
                          style={{width: "100%", position: "relative"}}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                            <div className={styles.box}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"up"} delay={200}>
                                    <h4>
                                        <CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                        <MyText uppercase><IntlMessage id={'trainDuration'}/></MyText>
                                    </h4>
                                    <h3>
                                        <MyText>24 <IntlMessage id={"months"}/></MyText>
                                    </h3>
                                </Fade>
                            </div>
                            <div className={styles.box}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"up"} delay={200}>
                                    <h4>
                                        <FormOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                        <MyText uppercase><IntlMessage id={'trainFormat'}/></MyText>
                                    </h4>
                                    <h3>
                                        <MyText><IntlMessage id={"modular"}/></MyText>
                                    </h3>
                                </Fade>
                            </div>
                            <div className={styles.box}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"up"} delay={200}>
                                    <h4>
                                        <GlobalOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                        {/*<CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>*/}
                                        <MyText uppercase><IntlMessage id={'trainLanguage'}/></MyText>
                                    </h4>
                                    <h3>
                                        <MyText><IntlMessage id={"kazakh"}/>,<IntlMessage id={"russian"}/></MyText>
                                    </h3>
                                </Fade>
                            </div>
                            <div className={styles.box}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"up"} delay={200}>
                                    <h4>
                                        <TeamOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>
                                        {/*<CalendarOutlined style={{fontSize: 24, color: clrs.white, marginRight: 10}}/>*/}
                                        <MyText uppercase><IntlMessage id={'classSchedule'}/></MyText>
                                    </h4>
                                    <h3>
                                        <MyText><IntlMessage id={"classScheduleTimeFin"}/></MyText>
                                    </h3>
                                </Fade>
                            </div>
                        </Fade>

                    </Flex>


                    {/*<StatsSection/>*/}

                </Flex>
            </Flex>
            <Flex style={{
                width: "100%",
                position: "relative",
                padding: "50px 20px 50px 20px",
                backgroundColor: "#EFEFEF"
            }} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Flex vertical justify={"space-between"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <p className='title' style={{margin: 0}}>
                                <p className={styles.sectionTitleText}><IntlMessage id={"programStructure"}/></p>
                            </p>
                        </Fade>

                        <Flex className={styles.program_structure} align={"flex-start"}>
                            <Flex className={styles.mandatory_block} vertical align={"center"} justify={"flex-start"} gap={"middle"}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <p className={styles.structureText}><IntlMessage id={"mandatoryDisp"}/></p>
                                </Fade>
                                <Fade triggerOnce={true} cascade damping={0.15} style={{height: "100%"}}>
                                    <Flex className={styles.mandatory_block_inner} vertical
                                          align={"flex-start"}
                                          justify={"space-evenly"} gap={10}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-1"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-2"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-3"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-4"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-5"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-6"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-7"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-8"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-9"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-10"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-11"}/></MyText>
                                            <MyText size={"default"}
                                                    style={{fontWeight: 500, textAlign: "left"}}>•
                                                <IntlMessage id={"mba-fin-md-12"}/></MyText>
                                        </Fade>

                                    </Flex>
                                </Fade>
                            </Flex>
                            <Flex className={styles.modules_block} vertical align={"center"} justify={"center"} gap={"middle"}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <p className={styles.structureText}><IntlMessage id={"chooseModules"}/></p>
                                </Fade>
                                <Flex className={styles.modules} vertical align={"center"} justify={"center"} gap={"middle"}>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div className={styles.module}
                                             style={{backgroundColor: "#FFE043", color: "black", justifyContent: "center"}}>
                                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                                <MyText strong size={"bigger"} style={{textAlign: "center"}}><IntlMessage id={"mba-fin-ch-1"}/></MyText>
                                            </Fade>
                                        </div>
                                    </Fade>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div className={styles.module}
                                             style={{backgroundColor: "#FFE043", color: "black"}}>
                                            <ul>
                                                <Fade triggerOnce={true} cascade damping={0.15}
                                                      direction={"down"} delay={200}>
                                                    <li><IntlMessage id={"mba-fin-ch-3-1"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-3-2"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-3-3"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-3-4"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-3-5"}/></li>
                                                </Fade>

                                            </ul>
                                        </div>
                                    </Fade>
                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div className={styles.module}
                                             style={{backgroundColor: "#FFE043", color: "black", justifyContent: "center"}}>
                                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                                <MyText strong size={"bigger"} style={{textAlign: "center"}}>
                                                    <IntlMessage id={"mba-fin-ch-2"}/></MyText>
                                            </Fade>
                                        </div>
                                    </Fade>

                                    <Fade triggerOnce={true} cascade damping={0.15}>
                                        <div className={styles.module}
                                             style={{backgroundColor: "#FFE043", color: "black"}}>
                                            <ul>
                                                <Fade triggerOnce={true} cascade damping={0.15}
                                                      direction={"down"} delay={200}>
                                                    <li><IntlMessage id={"mba-fin-ch-4-1"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-4-2"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-4-3"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-4-4"}/></li>
                                                    <li><IntlMessage id={"mba-fin-ch-4-5"}/></li>
                                                </Fade>

                                            </ul>
                                        </div>
                                    </Fade>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex style={{width: "100%", position: "relative", padding: "50px 20px 50px 20px"}} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Flex vertical justify={"center"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <p className='title' style={{margin: 0}}>
                                <p className={styles.sectionTitleText}><IntlMessage id={"ourTeachers"}/></p>
                            </p>
                        </Fade>

                        <Flex wrap gap={80} justify={"center"} align={"center"} style={{flexDirection: "row"}}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                                <div className={styles.teacherCard}>
                                    <img src={teacher1} alt="" width={'300px'} style={{borderRadius: 30}}/>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={150}>
                                        <h3>
                                            <strong><IntlMessage id={"mba-fin-tr-1n"}/></strong>
                                        </h3>
                                        <h5>
                                            <i>
                                                <IntlMessage id={"mba-fin-tr-1d"}/></i>
                                        </h5>
                                    </Fade>
                                </div>
                                <div className={styles.teacherCard}>
                                    <img src={teacher2} alt="" width={'300px'} style={{borderRadius: 30}}/>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={150}>
                                        <h3>
                                            <strong><IntlMessage id={"mba-fin-tr-2n"}/></strong>
                                        </h3>
                                        <h5>
                                            <i>
                                                <IntlMessage id={"mba-fin-tr-2d"}/>
                                            </i>
                                        </h5>
                                    </Fade>
                                </div>
                                <div className={styles.teacherCard}>
                                    <img src={teacher3} alt="" width={'300px'} style={{borderRadius: 30}}/>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={150}>
                                        <h3>
                                            <strong><IntlMessage id={"mba-fin-tr-3n"}/></strong>
                                        </h3>
                                        <h5>
                                            <i>
                                                <IntlMessage id={"mba-fin-tr-3d"}/>
                                            </i>
                                        </h5>
                                    </Fade>
                                </div>
                                <div className={styles.teacherCard}>
                                    <img src={teacher4} alt="" width={'300px'} style={{borderRadius: 30}}/>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={150}>
                                        <h3>
                                            <strong><IntlMessage id={"mba-fin-tr-4n"}/></strong>
                                        </h3>
                                        <h5>
                                            <i>
                                                <IntlMessage id={"mba-fin-tr-4d"}/>
                                            </i>
                                        </h5>
                                    </Fade>
                                </div>
                                <div className={styles.teacherCard}>
                                    <img src={teacher5} alt="" width={'300px'} style={{borderRadius: 30}}/>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={150}>
                                        <h3>
                                            <strong><IntlMessage id={"mba-fin-tr-5n"}/></strong>
                                        </h3>
                                        <h5>
                                            <i>
                                                <IntlMessage id={"mba-fin-tr-5d"}/>
                                            </i>
                                        </h5>
                                    </Fade>
                                </div>
                                <div className={styles.teacherCard}>
                                    <img src={teacher6} alt="" width={'300px'} style={{borderRadius: 30}}/>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={150}>
                                        <h3>
                                            <strong><IntlMessage id={"mba-fin-tr-6n"}/></strong>
                                        </h3>
                                        <h5>
                                            <i>
                                                <IntlMessage id={"mba-fin-tr-6d"}/>
                                            </i>
                                        </h5>
                                    </Fade>
                                </div>
                            </Fade>

                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex style={{
                width: "100%",
                position: "relative",
                padding: "50px 20px 50px 20px",
                backgroundColor: "#EFEFEF"
            }} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Flex vertical justify={"center"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <h1 style={{margin: 0}}>
                                <p className={styles.sectionTitleText}><IntlMessage id={"graduateDiplomas"}/></p>
                            </h1>
                        </Fade>

                        <Flex gap={size.width <= 1024 ? "large" : "middle"} justify={"space-between"} align={"center"}
                              style={{width: "100%"}} vertical={size.width <= 1024}>
                            <Fade triggerOnce={true} cascade damping={0.15}>
                                <Flex gap={10} vertical align={"center"} className={styles.documentCard}>
                                    <div style={{width: "100%", height: "100%", maxWidth: 250, maxHeight: 400}}>
                                        <img style={{maxWidth: "100%", maxHeight: "100%"}} src={exec2} alt=""/>
                                    </div>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={200}>
                                        <p>
                                            <IntlMessage id={"mba-fin-dip-1"}/>
                                        </p>
                                    </Fade>

                                </Flex>
                                <Flex gap={10} vertical align={"center"} className={styles.documentCard}>
                                    <div style={{width: "100%", height: "100%", maxWidth: 250, maxHeight: 400}}>
                                        <img style={{maxWidth: "100%", maxHeight: "100%"}} src={exec4} alt=""/>
                                    </div>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={200}>
                                        <p>
                                            <IntlMessage id={"mba-fin-dip-2"}/>
                                        </p>
                                    </Fade>
                                </Flex>
                            </Fade>

                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex style={{width: "100%", position: "relative", padding: "20px 20px 20px 20px"}} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <PartnersSection/>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    )
        ;
};

export default MbaDPage;