import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import FormService from "../../services/FormService";

let initialState = {
    forms: [],
    isLoading: false,
    hasMore: false,
    error: null,
}

export const getAllFormsPageable = createAsyncThunk(
    'getAllFormsPageable',
    async ({page, limit, type, categoryId}, { getState, rejectWithValue, dispatch }) => {
        try {
            const response = await FormService.getAllFormsPageableByType(page, limit, type, categoryId);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const formsListSlice = createSlice({
    name: 'formsListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllFormsPageable.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllFormsPageable.fulfilled, (state, action) => {
                state.forms = action.payload.data;
                state.isLoading = false;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getAllFormsPageable.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.hasMore = false;
                state.forms = [];
            })
    }
});

export const {} = formsListSlice.actions;

export default formsListSlice.reducer;