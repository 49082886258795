import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import TeacherService from "../../../../../services/TeacherService";
import {Flex, message, Table} from "antd";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import {LocalName} from "../../../../../utils/LocalName";
import UserService from "../../../../../services/UserService";
import {clrs} from "../../../../../constants/colors";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";

const TeachersList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);

    const [users, setUsers] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [initialLoading, setInitialLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchData();
        if (initialLoading) {
            setInitialLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPage === 1) {
            if (!initialLoading) {
                handleFetchData();
            }
        } else {
            setCurrentPage(1);
        }

    }, [pageSize]);

    async function handleFetchData() {
        setLoading(true);
        await UserService.getTeachers(currentPage, pageSize)
            .then((r) => {
                setUsers(r.data);
                setHasMore(r.hasMore);
                setInitialLoading(false);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    const columns = [
        {
            title: <span style={{fontWeight: 600}}><IntlMessage id={'fullName'}/> / <IntlMessage id={'username'}/></span>,
            render: (_, record) => (
                <p>{record.profile ? (record.profile.firstName + " " + record.profile.lastName) : record.username }</p>
            ),
        },
        {
            title: <span style={{fontWeight: 600}}><IntlMessage id={'email'}/></span>,
            render: (_, record) => (
                <p>{record.profile.email}</p>
            ),
        },
    ];

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <GreyButton onClick={() => {
                handleFetchData();
            }} backgroundColor={clrs.whiter} fontColor={clrs.black}><IntlMessage id={'update'}/></GreyButton>
            <Table
                bordered
                columns={columns}
                dataSource={users}
                pagination={{
                    current: currentPage,
                    total: hasMore
                        ? currentPage * pageSize + 1
                        : currentPage * pageSize,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                    onChange: handlePaginationChange,
                    // onShowSizeChange: handlePaginationChange,
                }}
                rowKey={record => record.id}
                loading={isLoading}
                style={{
                    width: "100%",
                }}
                scroll={{ x: 800 }}
            />
        </Flex>
    );
};

export default TeachersList;