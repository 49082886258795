import React, {useCallback, useEffect, useState} from 'react';
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import {Button, Divider, Flex, Form, message, Modal, Radio, Space, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {changeCurrentPage} from "../../../../../store/slices/tableController/ApplicationsListTableController";
import classes from "../application.module.css";
import {LocalName} from "../../../../../utils/LocalName";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import GreenButton from "../../../../../components/UI/Buttons/GreenButton";
import {getAllFormsPageable} from "../../../../../store/slices/FormsListSlice";
import {getAllCategories} from "../../../../../store/slices/CategoriesListSlice";
import FormService from "../../../../../services/FormService";
import MyText from "../../../../../components/UI/Text/MyText";
import {Document, Page} from "react-pdf";
import {API_BASE_URL} from "../../../../../constants/api";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import DocumentService from "../../../../../services/DocumentService";

const DirectorApplicationList = () => {
    const {user} = useSelector(state => state.user)
    const {forms, hasMore, isLoading} = useSelector(state => state.formsList)
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const formSave = useSelector(state => state.formSave);

    const {currentPage, pageSize} = useSelector(state => state.applicationsListTableController);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;

    const [filterForm] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openViewContractModal, setOpenViewContractModal] = useState(false);
    const [viewContract, setViewContract] = useState(null);

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingDocument, setLoadingDocument] = useState(true);

    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages);
        setLoadingDocument(false);
    }

    function triggerDownload(blob, filename) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Set the download attribute with a filename
        document.body.appendChild(link);
        link.click();

        // Clean up and revoke the URL after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async function downloadDocument(documentId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await DocumentService.getResourceById(documentId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                triggerDownload(r.data, viewContract?.docDetails !== null ? viewContract?.docDetails + ".pdf" : "dogovor.pdf")
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    useEffect(() => {
        dispatch(getAllFormsPageable({
            page: currentPage,
            limit: pageSize,
            type: user.role.roleName,
            categoryId: selectedCategoryId
        }))
    }, [navigate, selectedCategoryId]);


    const categoriesList = useSelector(state => state.categoriesList);
    useEffect(() => {
        dispatch(getAllCategories());
    }, []);
    const fetchData = async (params) => {
        return dispatch(getAllFormsPageable({...params, type: user.role.roleName, categoryId: selectedCategoryId}));
    }

    const saveCurrentPageSettings = (page, pageSize) => {
        dispatch(changeCurrentPage({page: page, pageSize: pageSize}));
    };

    async function handleApprove(formId) {
        message.loading(<IntlMessage id="changing"/>, 0);
        try {
            let results;
            if (!formId) {
                results = await Promise.allSettled(selectedRowKeys.map(id => FormService.directorApprove(id)));

                const allSuccess = results.every(res => res.status === "fulfilled");
                if (!allSuccess) {
                    throw new Error("Some requests failed");
                }
            } else {
                await FormService.directorApprove(formId);
            }

            message.success(<IntlMessage id="success"/>, 5);
        } catch (error) {
            message.error(<IntlMessage id="error"/>, 5);
        } finally {
            message.destroy();
            dispatch(
                getAllFormsPageable({
                    page: currentPage,
                    limit: pageSize,
                    type: user.role.roleName,
                    categoryId: selectedCategoryId
                })
            );
            setSelectedRowKeys([]);
            filterForm.resetFields();
        }
    }

    const columns = [
        {
            title: <IntlMessage id={'categoryCourse'}/>,
            render: (_, record) => (
                <p>{LocalName.getName(record.category)}</p>
            ),
            width: '25%',
        },
        {
            title: <IntlMessage id={'date'}/>,
            render: (_, record) => (
                <p>{new Date(record.updatedAt).toLocaleDateString()}</p>
            ),
            width: '25%',
        },
        {
            title: <IntlMessage id={'fullName'}/>,
            render: (_, record) => (
                <p>{record.userData.fullName}</p>
            ),
            width: '25%',
        },

        {
            title: <IntlMessage id={'form'}/>,
            render: (_, record) => (
                <>
                    <Flex style={{width: "100%"}} align={"center"} justify={"center"} wrap={"wrap"} gap={20}>
                        <p onClick={() => {
                            setViewContract(record);
                            setOpenViewContractModal(true);
                        }} className={classes.copyLinkBtn}><IntlMessage id={'view'}/></p>
                        <p onClick={() => {
                            handleApprove(record.id)
                        }} className={classes.copyLinkBtn}><IntlMessage id={'approve'}/></p>

                    </Flex>

                </>
            ),
            width: '25%',
        },
    ];

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <BlockLoading isLoading={isLoading}/>
            <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={20} wrap={"wrap"}>
                {
                    hasSelected &&
                    <GreenButton onClick={() => handleApprove(null)}>
                        <IntlMessage id={'approve'}/>
                    </GreenButton>
                }

            </Flex>
            <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
                <Form onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        dispatch(getAllFormsPageable({
                            page: currentPage,
                            limit: pageSize,
                            type: user.role.roleName,
                            categoryId: selectedCategoryId
                        }))
                    }
                }} form={filterForm} className={classes.form}>
                    <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"flex-start"}>
                        <Flex style={{width: "50%"}} vertical align={"flex-start"} justify={"flex-start"}>
                            <Divider className={classes.divider} orientation="left"><IntlMessage
                                id={'category'}/></Divider>
                            <Form.Item name="categoryId">
                                <Radio.Group value={selectedCategoryId}
                                             defaultValue={null}
                                             onChange={(e) => {
                                                 setSelectedCategoryId(e.target.value)
                                             }}>>
                                    <Space wrap={"wrap"}>
                                        <Radio value={null} key="all">
                                            <IntlMessage id={"allCategories"}/>
                                        </Radio>
                                        {
                                            categoriesList.categories.map((category, i) => {
                                                return (
                                                    <Radio value={category.id}
                                                           key={category.id}>{LocalName.getName(category)}</Radio>
                                                )
                                            })
                                        }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Flex>
                    </Flex>
                </Form>
            </Flex>


            <MyTable
                isLoading={isLoading}
                dataSource={forms}
                columns={columns}
                fetchData={fetchData}
                saveCurrentPage={saveCurrentPageSettings}
                initialPage={currentPage}
                initialPageSize={pageSize}
                hasMore={hasMore}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
            />

            <Modal
                width={800}
                maskClosable={true}
                // title={<IntlMessage id={'contractsPage.details'}/>}
                open={openViewContractModal}
                okText={<IntlMessage id={'save'}/>}
                cancelText={<IntlMessage id={'close'}/>}
                style={{
                    top: 20,
                }}
                onOk={() => {
                    setOpenViewContractModal(false)
                }}
                onCancel={() => {
                    setOpenViewContractModal(false)
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setOpenViewContractModal(false)
                    }}>
                        OK
                    </Button>
                ]}
            >
                {
                    viewContract &&
                    <Flex gap={50}
                          style={{
                              width: "100%",
                              minHeight: "calc(100vh - 300px)",
                              position: "relative",
                              padding: "0px 20px 20px 0px",
                          }}
                          vertical
                          align={"flex-start"}
                          justify={"flex-start"}>
                        <Flex align={"center"} justify={"center"} vertical
                              style={{overflowX: "auto", overflowY: "hidden", width: "100%"}}>
                            <Flex style={{
                                zIndex: 1,
                                padding: "10px 20px",
                                backgroundColor: "white",
                                borderRadius: 10,
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                            }} gap={15} justify={"space-between"} align={"center"}>
                                <Button disabled={pageNumber === 1} onClick={() => {
                                    setPageNumber(pageNumber - 1)
                                }}>
                                    {'<'}
                                </Button>
                                <MyText size={"small"}>
                                    {pageNumber} / {numPages}
                                </MyText>
                                <Button disabled={pageNumber === numPages} onClick={() => {
                                    setPageNumber(pageNumber + 1)
                                }}>
                                    {'>'}
                                </Button>
                            </Flex>
                            <Document file={API_BASE_URL + "/api/document/resource/" + viewContract?.documentId}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      loading={<IntlMessage id={'documentIsLoading'}/>}
                            >
                                <Page pageNumber={pageNumber} width={700}/>
                            </Document>
                        </Flex>
                        <Flex align={"flex-start"} justify={"center"} gap={20} style={{width: "100%"}}>
                            <GreyButton onClick={() => downloadDocument(viewContract?.documentId)}>
                                <IntlMessage id={'downloadContract'}/>
                            </GreyButton>
                        </Flex>
                    </Flex>
                }
            </Modal>
        </Flex>
    );
};

const MyTable = ({
                     isLoading,
                     dataSource,
                     columns,
                     fetchData,
                     saveCurrentPage,
                     initialPage = 1,
                     initialPageSize = 100,
                     hasMore = true,
                     expandable,
                     selectedRowKeys,
                     setSelectedRowKeys
                 }) => {
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [currentPage, setCurrentPage] = useState(initialPage);
    const [currentPageSize, setCurrentPageSize] = useState(initialPageSize);
    const [hasNextPage, setHasNextPage] = useState(hasMore);

    useEffect(() => {
        setHasNextPage(hasMore);
    }, [hasMore]);

    useEffect(() => {
        setCurrentPage(initialPage);
        setCurrentPageSize(initialPageSize);
    }, [initialPage, initialPageSize]);

    // Handle pagination change by updating the current page and page size.
    // Then, fetch data using the provided fetchData function.
    const handlePaginationChange = useCallback(
        (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
            fetchData({page, limit: pageSize}).then((result) => {
                const hasMore = result.payload.hasMore;
                setHasNextPage(hasMore);
            });
            saveCurrentPage(page, pageSize);
        },
        [fetchData],
    );

    return (
        <Table
            rowSelection={rowSelection}
            bordered
            columns={columns}
            dataSource={dataSource}
            pagination={{
                current: currentPage,
                total: hasNextPage
                    ? currentPage * currentPageSize + 1
                    : currentPage * currentPageSize,
                defaultPageSize: initialPageSize,
                showSizeChanger: true,
                pageSize: currentPageSize,
                pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                onChange: handlePaginationChange,
                onShowSizeChange: handlePaginationChange,
            }}
            rowKey={record => record.id}
            loading={isLoading}
            style={{
                width: "100%",
            }}
            scroll={{x: 800}}
        />
    )
}

export default DirectorApplicationList;