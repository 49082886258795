import React, {useEffect, useState} from 'react';
import {useWindowSize} from "@uidotdev/usehooks";
import Header from "../components/Header/Header";
import {Flex, Form, Input, message, Popover, Select, Table, Tag} from "antd";
import NewBanner from "../Home/components/Banner/NewBanner";
import {Fade} from "react-awesome-reveal";
import MyText from "../../components/UI/Text/MyText";
import {clrs} from "../../constants/colors";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import Footer from "../components/Footer/Footer";
import computer from "../../images/computer.svg";
import hat from "../../images/hat.svg";
import person from "../../images/person.svg";
import people from "../../images/people-yellow.png";
import classes from './robotics.module.css';
import {
    BankOutlined,
    CalendarOutlined,
    CaretRightOutlined,
    MergeOutlined, MinusOutlined,
    OpenAIOutlined,
    PlusOutlined
} from "@ant-design/icons";
import GraduateIcon from "../../components/icons/GraduateIcon";
import DiscussionIcon from "../../components/icons/DiscussionIcon";
import DurationIcon from "../../components/icons/DurationIcon";
import BannerForm from "../Home/components/Banner/BannerForm";
import cl from "../Home/components/Banner/Banner.module.css";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import {EmailValidator} from "../../utils/EmailValidator";
import InputMask from "react-input-mask";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import ApplicationService from "../../services/ApplicationService";
import CategoryService from "../../services/CategoryService";
import {LocalName} from "../../utils/LocalName";
import {CLIENT_BASE_URL} from "../../constants/api";

const RoboticsPage = () => {
    const size = useWindowSize();

    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);
    const email = Form.useWatch('email', form);
    const telNumber = Form.useWatch('telNumber', form);
    const categoryId = Form.useWatch('categoryId', form);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesListManager, setCategoriesListManager] = useState([]);

    const [loading, setLoading] = useState(true);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setLoading(true);
                await ApplicationService.saveApplication(
                    name,
                    email,
                    telNumber.endsWith('_') ? telNumber.slice(0, -1) : telNumber,
                    categoryId)
                    .then(() => {
                        message.destroy();
                        if (categoriesListManager.includes(categoryId)) {
                            message.success(<IntlMessage id={'successApplicationSendManager'}/>, 5);
                        } else {
                            message.success(<IntlMessage id={'successApplicationSend'}/>, 5);
                        };
                        form.resetFields();
                        setLoading(false);
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setLoading(false);
                setCategoriesList(r.data);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
        setCategoriesListManager(
            categoriesList
                .filter(category => category.formType !== "FOUNDATION")
                .map(category => category.id)
        );
    }, [categoriesList]);

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    {/*<NewBanner/>*/}
                    <Flex gap={80} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                          align={"flex-start"} justify={"flex-start"}>
                        <Flex
                            align={"center"}
                            justify={"center"}
                            style={{width: "100%", padding: 20, backgroundColor: clrs.blackerBlue, borderRadius: 20}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText stronger uppercase center style={{fontSize: size.width > 800 ? "3rem" : "1.5rem", color: clrs.yellow}}>
                                    <IntlMessage id={'roboticsPage.robotics'}/>
                                </MyText>
                                <MyText stronger uppercase style={{fontSize: "1.2rem", color: clrs.white, textAlign: "center"}}>
                                    Astana IT University
                                </MyText>
                            </Fade>

                        </Flex>
                        <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                {/*<MyText size={"larger"} strong>*/}
                                {/*    Foundation Astana IT University*/}
                                {/*</MyText>*/}
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <IntlMessage id={'roboticsPage.description'}/>
                                </MyText>
                            </Fade>

                        </Flex>

                        <Flex
                            gap={30}
                            align={"flex-start"}
                            justify={"space-around"}
                            style={{
                                width: "100%",
                                border: "1px solid #D7D7D7",
                                borderRadius: 20,
                                padding: "30px 15px",
                                textAlign: "center"
                            }} wrap={"wrap"}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <InfoCard text={<IntlMessage id={'roboticsPage.upperCardBlock.1.text'}/>} title={<IntlMessage id={'roboticsPage.upperCardBlock.1.title'}/>} icon={<CalendarOutlined style={{fontSize: "2rem", width: 40, height: 40}}/>}/>
                                <InfoCard text={<IntlMessage id={'roboticsPage.upperCardBlock.2.text'}/>} title={<IntlMessage id={'roboticsPage.upperCardBlock.2.title'}/>} icon={<GraduateIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                <InfoCard text={<IntlMessage id={'roboticsPage.upperCardBlock.3.text'}/>} title={<IntlMessage id={'roboticsPage.upperCardBlock.3.title'}/>} icon={<DiscussionIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                <InfoCard text={<IntlMessage id={'roboticsPage.upperCardBlock.4.text'}/>} title={<IntlMessage id={'roboticsPage.upperCardBlock.4.title'}/>} icon={<DurationIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                            </Fade>

                        </Flex>

                        <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"center"} vertical
                              gap={50}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"large"} strong>
                                    <IntlMessage id={'roboticsPage.program'}/>
                                </MyText>
                            </Fade>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.1'}/>
                                    </Flex>
                                </Fade>
                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.1.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.1.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.1.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.1.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.1.body.4'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.1.body.5'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.1.body.6'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.2'}/>
                                    </Flex>
                                </Fade>
                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.2.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.2.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.2.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.2.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.2.body.4'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.3'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.3.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.3.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.3.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.3.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.3.body.4'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.3.body.5'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.4'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.4.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.4.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.4.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.4.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.4.body.4'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.4.body.5'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.5'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.5.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.5.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.5.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.5.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.5.body.4'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.5.body.5'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.6'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.6.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.6.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.6.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.6.body.3'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.6.body.4'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.7'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.7.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.7.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.7.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.7.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.7.body.4'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.7.body.5'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.8'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.8.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.8.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.8.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.8.body.3'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.8.body.4'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.9'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.9.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.9.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.9.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.9.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.9.body.4'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.9.body.5'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.9.body.6'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.10'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.10.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.10.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.10.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.10.body.3'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.10.body.4'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.10.body.5'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.10.body.6'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.11'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.11.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.11.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.11.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.11.body.3'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.12'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.12.header'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.12.body.1'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.12.body.2'}/>
                                        </MyText>
                                        <MyText style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.12.body.3'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.13'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.13.header'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.13.body.1'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.14'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.14.header'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.14.body.1'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <div style={{
                                    height: "calc(100% + 50px)",
                                    position: "absolute",
                                    width: 2,
                                    top: 0,
                                    left: 60,
                                    zIndex: -1,
                                    backgroundColor: clrs.whiter2
                                }}></div>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.15'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.15.header'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.15.body.1'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>
                            <Flex gap={25} style={{width: "100%", maxWidth: 650, position: "relative"}}
                                  align={"flex-start"} justify={"center"}>
                                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <Flex style={{
                                        fontWeight: "bold",
                                        width: 120,
                                        padding: 10,
                                        backgroundColor: clrs.yellow,
                                        borderRadius: 8
                                    }} align={"center"} justify={"center"}>
                                        <IntlMessage id={'roboticsPage.program.16'}/>
                                    </Flex>
                                </Fade>

                                <Flex style={{width: "100%", position: "relative", paddingTop: 10}}
                                      align={"flex-start"}
                                      justify={"flex-start"} vertical gap={5}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <MyText strong size={"default"}>
                                            <IntlMessage id={'roboticsPage.program.16.header'}/>
                                        </MyText>
                                        <MyText italic style={{fontWeight: 400}}>
                                            <IntlMessage id={'roboticsPage.program.16.body.1'}/>
                                        </MyText>
                                    </Fade>

                                </Flex>
                            </Flex>

                        {/**/}
                        </Flex>

                        <Flex
                            gap={30}
                            align={"flex-start"}
                            justify={"space-around"}
                            style={{
                                width: "100%",
                                border: "1px solid #D7D7D7",
                                borderRadius: 20,
                                padding: "30px 15px",
                                textAlign: "center"
                            }} wrap={size.width > 940 ? "nowrap" : "wrap"}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <InfoCard text={<IntlMessage id={'roboticsPage.cardBlock2.1.top'}/>}
                                          title={<IntlMessage id={'roboticsPage.cardBlock2.1.bottom'}/>}/>
                                <InfoCard text={<IntlMessage id={'roboticsPage.cardBlock2.2.top'}/>}
                                          title={<IntlMessage id={'roboticsPage.cardBlock2.2.bottom'}/>}/>
                            </Fade>

                        </Flex>

                    </Flex>
                    <Flex style={{width: "100%", position: "relative"}} gap={20} align={"center"} justify={"center"}>
                        <Fade triggerOnce={true} cascade style={{width: '100%'}}>
                            <Form form={form} onSubmit={handleSubmit}>
                                <BlockLoading isLoading={loading}/>
                                <div>
                                    <p className={cl.bannerFormText}><IntlMessage id={'bannerText2'}/></p>
                                    <p className={cl.bannerFormText}><IntlMessage id={'bannerText3'}/></p>
                                    <br/>
                                </div>

                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormNameError'}/>,
                                },
                                ]} className={classes.formInputBlock} name="name">
                                    <Input rootClassName={classes.formInput}
                                           placeholder={IntlMessageText.getText({id: 'name'})}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormEmailError'}/>,
                                },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (EmailValidator.isValid(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t('emailRules')));
                                        },
                                    }),
                                ]} className={classes.formInputBlock} name="email">
                                    <Input rootClassName={classes.formInput}
                                           placeholder={IntlMessageText.getText({id: 'email'})}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormTelError'}/>,
                                },
                                ]} className={classes.formInputBlock} name="telNumber">
                                    <InputMask
                                        mask="+9 (999) 999-9999"
                                        className={classes.formInput}
                                        placeholder={IntlMessageText.getText({id: 'telNumber'})}
                                    >
                                        {(inputProps) => <Input {...inputProps} />}
                                    </InputMask>
                                    {/*<Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'telNumber'})}/>*/}
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'requiredField'}/>,
                                },
                                ]} className={classes.formInput} style={{width: "100%", padding: 0}} name="categoryId">
                                    <Select
                                        options={categories}
                                        placeholder={<IntlMessage id={'categoryCourse'}/>}
                                    />
                                </FormItem>
                                <FormItem className={classes.formInputBlock}>
                                    <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                                        <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                                            <IntlMessage id={'send'}/>
                                        </GreyButton>
                                    </Flex>
                                </FormItem>
                            </Form>
                        </Fade>
                    </Flex>

                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

const InfoCard = ({title, text, icon}) => {
    return (
        <Flex gap={20} align={"flex-start"} justify={"center"}>
            {icon}
            <Flex gap={10} vertical align={"center"} justify={"center"}>
                <MyText strong style={{color: clrs.blackerBlue}}>
                    {text}
                </MyText>
                <MyText style={{color: clrs.whiterBlacker, maxWidth: 200, textAlign: "center"}}>
                    {title}
                </MyText>

            </Flex>
        </Flex>
    )
}

const GridTable = ({ columns, data }) => {
    const gridTemplate = columns
        .map((col) => col.width || "auto")
        .join(" ");

    return (
        <div
            style={{
                width: "100%",
                border: "0.5px solid #D7D7D7",
                borderRadius: "20px",
                overflow: "hidden",
                minWidth: 500,
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: gridTemplate,
                    backgroundColor: clrs.yellow,
                    color: "black",
                    borderBottom: "1px solid #D7D7D7"
                }}
            >
                {columns.map((col, i) => (
                    <div
                        key={i}
                        style={{
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold"
                        }}
                    >
                        {col.title}
                    </div>
                ))}
            </div>

            {data.map((record, rowIndex) => (
                <div
                    key={rowIndex}
                    style={{
                        display: "grid",
                        gridTemplateColumns: gridTemplate,
                        borderBottom:
                            rowIndex !== data.length - 1
                                ? "1px solid #D7D7D7"
                                : "none"
                    }}
                >
                    {columns.map((col, colIndex) => (
                        <div
                            key={colIndex}
                            style={{
                                padding: "8px",
                                textAlign: "center"
                            }}
                        >
                            {col.render
                                ? col.render(null, record)
                                : record[col.dataIndex]}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};


export default RoboticsPage;