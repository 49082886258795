import React, {useEffect, useState} from 'react';
import {useWindowSize} from "@uidotdev/usehooks";
import Header from "../components/Header/Header";
import {Flex, Form, Input, message, Popover, Select, Table, Tag} from "antd";
import NewBanner from "../Home/components/Banner/NewBanner";
import {Fade} from "react-awesome-reveal";
import MyText from "../../components/UI/Text/MyText";
import {clrs} from "../../constants/colors";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import Footer from "../components/Footer/Footer";
import computer from "../../images/computer.svg";
import hat from "../../images/hat.svg";
import person from "../../images/person.svg";
import people from "../../images/people-yellow.png";
import classes from './foundation.module.css';
import {
    BankOutlined,
    CalendarOutlined,
    CaretRightOutlined,
    MergeOutlined,
    OpenAIOutlined,
    PlusOutlined
} from "@ant-design/icons";
import GraduateIcon from "../../components/icons/GraduateIcon";
import DiscussionIcon from "../../components/icons/DiscussionIcon";
import DurationIcon from "../../components/icons/DurationIcon";
import BannerForm from "../Home/components/Banner/BannerForm";
import cl from "../Home/components/Banner/Banner.module.css";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import {EmailValidator} from "../../utils/EmailValidator";
import InputMask from "react-input-mask";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import ApplicationService from "../../services/ApplicationService";
import CategoryService from "../../services/CategoryService";
import {LocalName} from "../../utils/LocalName";
import {CLIENT_BASE_URL} from "../../constants/api";

const FoundationPage = () => {
    const size = useWindowSize();

    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);
    const email = Form.useWatch('email', form);
    const telNumber = Form.useWatch('telNumber', form);
    const categoryId = Form.useWatch('categoryId', form);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesListManager, setCategoriesListManager] = useState([]);

    const [loading, setLoading] = useState(true);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setLoading(true);
                await ApplicationService.saveApplication(
                    name,
                    email,
                    telNumber.endsWith('_') ? telNumber.slice(0, -1) : telNumber,
                    categoryId)
                    .then(() => {
                        message.destroy();
                        if (categoriesListManager.includes(categoryId)) {
                            message.success(<IntlMessage id={'successApplicationSendManager'}/>, 5);
                        } else {
                            message.success(<IntlMessage id={'successApplicationSend'}/>, 5);
                        };
                        form.resetFields();
                        setLoading(false);
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setLoading(false);
                setCategoriesList(r.data);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
        setCategoriesListManager(
            categoriesList
                .filter(category => category.formType !== "FOUNDATION")
                .map(category => category.id)
        );
    }, [categoriesList]);

    const columns = [
        {
            title: <MyText center strong><IntlMessage id={'subjects'}/></MyText>,
            render: (_, record) => (
                <MyText center strong={Number(record.key) > 6}><IntlMessage id={record.subject}/></MyText>
            ),
            width: '30%',
        },
        {
            title: <MyText center strong><IntlMessage id={'numOfHours'}/></MyText>,
            render: (_, record) => (
                record.numOfHours ? <MyText center strong={Number(record.key) > 6}>{record.numOfHours}</MyText> : ""
            ),
            width: '20%',
        },
        {
            title: <MyText center strong><IntlMessage id={'coursePrice'}/></MyText>,
            render: (_, record) => (
                record.courseCost ? <MyText center strong={Number(record.key) > 6}>{record.courseCost} <IntlMessage id={"tenge"}/></MyText> : ""
            ),
            width: '25%',
        },

        {
            title: <MyText center strong><IntlMessage id={'coursePriceInTrimestre'}/></MyText>,
            render: (_, record) => (
                record.trimesterCost ? <MyText center strong={Number(record.key) > 6}>{record.trimesterCost} <IntlMessage id={"tenge"}/></MyText> : ""
            ),
            width: '25%',
        },
    ];
    const data = [
        {
            key: '1',
            subject: "foundationPage.leftBlock.1",
            numOfHours: '30',
            courseCost: '54 000',
            trimesterCost: '18 000',
        },
        {
            key: '2',
            subject: "foundationPage.leftBlock.2",
            numOfHours: '30',
            courseCost: '54 000',
            trimesterCost: '18 000',
        },
        {
            key: '3',
            subject: "foundationPage.leftBlock.3",
            numOfHours: '60',
            courseCost: '129 000',
            trimesterCost: '43 000',
        },
        {
            key: '4',
            subject: "foundationPage.leftBlock.4",
            numOfHours: '90',
            courseCost: '269 000',
            trimesterCost: '89 700',
        },
        {
            key: '5',
            subject: "foundationPage.leftBlock.5",
            numOfHours: '90',
            courseCost: '228 000',
            trimesterCost: '76 000',
        },
        {
            key: '6',
            subject: "foundationPage.rightBlock.1",
            numOfHours: '120',
            courseCost: '270 000',
            trimesterCost: '90 000',
        },
        {
            key: '7',
            subject: "studyPrice",
            numOfHours: '420',
            courseCost: '1 004 000',
            trimesterCost: '334 700',
        },
        {
            key: '8',
            subject: "foundationPage.cardBlock2.3.top",
            courseCost: '803 200',
        },
    ];

    const columns2 = [
        {
            title: <MyText center strong><IntlMessage id={'discountType'}/></MyText>,
            render: (_, record) => (
                <MyText center><IntlMessage id={record.label}/></MyText>
            ),
            width: '80%',
        },
        {
            title: <MyText center strong><IntlMessage id={'discountSize'}/></MyText>,
            render: (_, record) => (
                <MyText center>{record.value}</MyText>
            ),
            width: '20%',
        },
    ];

    const data2 = [
        {
            key: 'family_disabled',
            value: 20,
            label: 'foundationPage.discountOne',
        },
        {
            key: 'large_family',
            value: 20,
            label: 'foundationPage.discountTwo',
        },
        {
            key: 'orphans',
            value: 50,
            label: "foundationPage.discountThree"
        }
    ]

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <NewBanner/>
                    <Flex gap={80} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                          align={"flex-start"} justify={"flex-start"}>
                        <Flex
                            align={"center"}
                            justify={"center"}
                            style={{width: "100%", padding: 20, backgroundColor: clrs.blackerBlue, borderRadius: 20}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText stronger uppercase style={{fontSize: size.width > 800 ? "4rem" : "2rem", color: clrs.yellow}}>
                                    Foundation
                                </MyText>
                                <MyText stronger uppercase style={{fontSize: "1.5rem", color: clrs.white, textAlign: "center"}}>
                                    Astana IT University
                                </MyText>
                            </Fade>

                        </Flex>
                        <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                {/*<MyText size={"larger"} strong>*/}
                                {/*    Foundation Astana IT University*/}
                                {/*</MyText>*/}
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <IntlMessage id={'foundationPage.description'}/>
                                </MyText>
                            </Fade>

                        </Flex>
                        <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={size.width > 800 ? "larger" : "large"} strong>
                                    <IntlMessage id={'foundationPage.purpose'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <IntlMessage id={'foundationPage.purpose.description'}/>
                                </MyText>
                            </Fade>
                        </Flex>

                        <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={size.width > 800 ? "larger" : "large"} strong>
                                    <IntlMessage id={'foundationPage.aboutCourse'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.aboutCourse.1'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.aboutCourse.2'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.aboutCourse.3'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.aboutCourse.4'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        {
                            size.width > 940 ?
                                <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical>
                                    <Flex
                                        gap={30}
                                        align={"flex-start"}
                                        justify={"space-around"}
                                        style={{
                                            width: "100%",
                                            borderLeft: "1px solid #D7D7D7",
                                            borderRight: "1px solid #D7D7D7",
                                            borderTop: "1px solid #D7D7D7",
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 20,
                                            padding: "30px 15px",
                                            textAlign: "center"
                                        }}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.1.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.1.title'}/>} icon={<CalendarOutlined style={{fontSize: "2rem", width: 40, height: 40}}/>}/>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.2.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.2.title'}/>} icon={<GraduateIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.3.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.3.title'}/>} icon={<DiscussionIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.4.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.4.title'}/>} icon={<DurationIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                        </Fade>

                                    </Flex>
                                    <Flex style={{width: "100%", height: 400, position: "relative",}}>
                                        <Flex style={{
                                            width: "50vw",
                                            position: "absolute",
                                            height: 400,
                                            backgroundColor: clrs.yellow, left: "calc(0px - (100vw - 100%) / 2)",
                                            paddingLeft: "calc((100vw - 100%) / 2)",
                                            paddingRight: 20,
                                            paddingTop: 50,
                                            paddingBottom: 50
                                        }} vertical gap={20}>
                                            <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                                <MyText size={"bigger"} strong>
                                                    <IntlMessage id={'foundationPage.leftBlock.header'}/>
                                                </MyText>
                                                <MyText>
                                                    1. <IntlMessage id={'foundationPage.leftBlock.1'}/>
                                                </MyText>
                                                <MyText>
                                                    2. <IntlMessage id={'foundationPage.leftBlock.2'}/>
                                                </MyText>
                                                <MyText>
                                                    3. <IntlMessage id={'foundationPage.leftBlock.3'}/>
                                                </MyText>
                                                <MyText>
                                                    4. <IntlMessage id={'foundationPage.leftBlock.4'}/>
                                                </MyText>
                                                <MyText>
                                                    5. <IntlMessage id={'foundationPage.leftBlock.5'}/>
                                                </MyText>
                                            </Fade>

                                        </Flex>
                                        <Flex
                                            style={{
                                                width: "50vw",
                                                position: "absolute",
                                                height: 400,
                                                backgroundColor: clrs.blackerBlue,
                                                left: "calc(0px - (100vw - 100%) / 2 - 2.5px + 50vw)",
                                                paddingLeft: 50,
                                                paddingRight: "calc((100vw - 100%) / 2)",
                                                paddingTop: 50,
                                                paddingBottom: 50,
                                                color: "white"
                                            }} vertical gap={20}>
                                            <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                                                <MyText size={"bigger"} strong>
                                                    <IntlMessage id={'foundationPage.rightBlock.header'}/>
                                                </MyText>
                                                <MyText>
                                                    1. <IntlMessage id={'foundationPage.rightBlock.1'}/>
                                                </MyText>
                                                <MyText>
                                                    2. <IntlMessage id={'foundationPage.rightBlock.2'}/>
                                                </MyText>
                                            </Fade>

                                        </Flex>
                                    </Flex>
                                </Flex>
                                :
                                <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical>
                                    <Flex
                                        gap={30}
                                        align={"flex-start"}
                                        justify={"space-around"}
                                        style={{
                                            width: "100%",
                                            borderLeft: "1px solid #D7D7D7",
                                            borderRight: "1px solid #D7D7D7",
                                            borderTop: "1px solid #D7D7D7",
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 20,
                                            padding: "30px 15px",
                                            textAlign: "center"
                                        }} wrap={"wrap"}>
                                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.1.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.1.title'}/>} icon={<CalendarOutlined style={{fontSize: "2rem", width: 40, height: 40}}/>}/>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.2.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.2.title'}/>} icon={<GraduateIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.3.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.3.title'}/>} icon={<DiscussionIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                            <InfoCard text={<IntlMessage id={'foundationPage.upperCardBlock.4.text'}/>} title={<IntlMessage id={'foundationPage.upperCardBlock.4.title'}/>} icon={<DurationIcon style={{fontSize: "2.5rem", width: 40, height: 40}}/>}/>
                                        </Fade>

                                    </Flex>
                                    <Flex style={{width: "100%", position: "relative",}} vertical align={"center"} justify={"center"}>
                                        <Flex style={{
                                            width: "100%",
                                            position: "relative",
                                            height: 400,
                                            backgroundColor: clrs.yellow,
                                            paddingLeft: 50,
                                            paddingRight: 20,
                                            paddingTop: 50,
                                            paddingBottom: 50
                                        }} vertical gap={20}>
                                            <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                                <MyText size={"bigger"} strong>
                                                    <IntlMessage id={'foundationPage.leftBlock.header'}/>
                                                </MyText>
                                                <MyText>
                                                    1. <IntlMessage id={'foundationPage.leftBlock.1'}/>
                                                </MyText>
                                                <MyText>
                                                    2. <IntlMessage id={'foundationPage.leftBlock.2'}/>
                                                </MyText>
                                                <MyText>
                                                    3. <IntlMessage id={'foundationPage.leftBlock.3'}/>
                                                </MyText>
                                                <MyText>
                                                    4. <IntlMessage id={'foundationPage.leftBlock.4'}/>
                                                </MyText>
                                                <MyText>
                                                    5. <IntlMessage id={'foundationPage.leftBlock.5'}/>
                                                </MyText>
                                            </Fade>

                                        </Flex>
                                        <Flex
                                            style={{
                                                width: "100%",
                                                position: "relative",
                                                height: 400,
                                                backgroundColor: clrs.blackerBlue,
                                                paddingLeft: 50,
                                                paddingRight: 50,
                                                paddingTop: 50,
                                                paddingBottom: 50,
                                                borderBottomRightRadius: 20,
                                                borderBottomLeftRadius: 20,
                                                color: "white"
                                            }} vertical gap={20}>
                                            <Fade triggerOnce={true} cascade damping={0.15} direction={"right"}>
                                                <MyText size={"bigger"} strong>
                                                    <IntlMessage id={'foundationPage.rightBlock.header'}/>
                                                </MyText>
                                                <MyText>
                                                    1. <IntlMessage id={'foundationPage.rightBlock.1'}/>
                                                </MyText>
                                                <MyText>
                                                    2. <IntlMessage id={'foundationPage.rightBlock.2'}/>
                                                </MyText>
                                            </Fade>

                                        </Flex>
                                    </Flex>
                                </Flex>
                        }


                        <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={size.width > 800 ? "larger" : "large"} strong>
                                    <IntlMessage id={'foundationPage.ourTeachers'}/>
                                </MyText>
                                <MyText italic style={{maxWidth: 500, fontWeight: 400}}>
                                    <IntlMessage id={'foundationPage.ourTeachers.hint'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.ourTeachers.1'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.ourTeachers.2'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.ourTeachers.3'}/>
                                </MyText>
                                <MyText size={"bigger"} strong italic>
                                    <IntlMessage id={'foundationPage.ourTeachers.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={size.width > 800 ? "larger" : "large"} strong>
                                    <IntlMessage id={'foundationPage.formatStudy'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.formatStudy.1'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.formatStudy.2'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.formatStudy.3'}/>
                                </MyText>
                            </Fade>
                        </Flex>

                        <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={size.width > 800 ? "larger" : "large"} strong>
                                    <IntlMessage id={'foundationPage.schedule'}/>
                                </MyText>
                                <MyText size={"bigger"} strong>
                                    <IntlMessage id={'foundationPage.schedule.strong'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.schedule.1'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.schedule.2'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.schedule.3'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.schedule.4'}/>
                                </MyText>
                                <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                                    <CaretRightOutlined style={{color: clrs.yellow}}/>
                                    <IntlMessage id={'foundationPage.schedule.5'}/>
                                </MyText>
                            </Fade>
                        </Flex>

                        <Flex style={{width: "100%", position: "relative"}} align={"flex-start"} justify={"center"} vertical gap={20}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong>
                                    <IntlMessage id={'foundationPage.formatLesson'}/>
                                </MyText>
                                <MyText >
                                    <IntlMessage id={'foundationPage.formatLesson.1'}/>
                                </MyText>
                                <MyText >
                                    <IntlMessage id={'foundationPage.formatLesson.2'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        <Flex
                            gap={30}
                            align={"flex-start"}
                            justify={"space-around"}
                            style={{
                            width: "100%",
                            border: "1px solid #D7D7D7",
                            borderRadius: 20,
                            padding: "30px 15px",
                            textAlign: "center"
                        }} wrap={size.width > 940 ? "nowrap" : "wrap"}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <InfoCard text={<IntlMessage id={'foundationPage.cardBlock2.1.top'}/>} title={<IntlMessage id={'foundationPage.cardBlock2.1.bottom'}/>}/>
                                <InfoCard text={<IntlMessage id={'foundationPage.cardBlock2.2.top'}/>} title={<IntlMessage id={'foundationPage.cardBlock2.2.bottom'}/>}/>
                                <InfoCard text={<IntlMessage id={'foundationPage.cardBlock2.3.top'}/>} title={<IntlMessage id={'foundationPage.cardBlock2.3.bottom'}/>}/>
                            </Fade>

                        </Flex>
                        <Flex style={{width: "100%"}}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} style={{width: "100%", overflowX: "auto"}}>
                                {/*<Table*/}
                                {/*    bordered*/}
                                {/*    columns={columns}*/}
                                {/*    dataSource={data}*/}
                                {/*    rowKey={record => record.id}*/}
                                {/*    style={{*/}
                                {/*        width: "100%",*/}
                                {/*        border: "0.5px solid #D7D7D7"*/}
                                {/*    }}*/}
                                {/*    scroll={{x: 800}}*/}
                                {/*    pagination={false}*/}
                                {/*/>*/}
                                <GridTable columns={columns} data={data} />
                            </Fade>
                        </Flex>
                        <Flex style={{width: "100%"}}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} style={{width: "100%", overflowX: "auto"}}>
                                {/*<Table*/}
                                {/*    bordered*/}
                                {/*    columns={columns}*/}
                                {/*    dataSource={data}*/}
                                {/*    rowKey={record => record.id}*/}
                                {/*    style={{*/}
                                {/*        width: "100%",*/}
                                {/*        border: "0.5px solid #D7D7D7"*/}
                                {/*    }}*/}
                                {/*    scroll={{x: 800}}*/}
                                {/*    pagination={false}*/}
                                {/*/>*/}
                                <GridTable columns={columns2} data={data2} />
                            </Fade>
                        </Flex>
                        <Flex
                            gap={30}
                            align={"flex-start"}
                            justify={"center"}
                            style={{
                                width: "100%",
                                border: "1px solid #D7D7D7",
                                borderRadius: 20,
                                padding: "30px 15px"
                            }}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <Flex gap={10} vertical align={"center"} justify={"center"}>
                                    <MyText strong size={"large"} style={{color: clrs.blackerBlue, textAlign: "center"}}>
                                        <IntlMessage id={'foundationPage.contactToCall'}/>
                                    </MyText>
                                    <MyText size={"bigger"} style={{color: clrs.whiterBlacker, maxWidth: 200, textAlign: "center"}}>
                                        +7 (702)-069-30-55 +7 (7172)-645-714
                                    </MyText>

                                </Flex>
                            </Fade>

                        </Flex>

                    </Flex>
                    <Flex style={{width: "100%", position: "relative"}} gap={20} align={"center"} justify={"center"}>
                        <Fade triggerOnce={true} cascade style={{width: '100%'}}>
                            <Form form={form} onSubmit={handleSubmit}>
                                <BlockLoading isLoading={loading}/>
                                <div>
                                    <p className={cl.bannerFormText}><IntlMessage id={'bannerText2'}/></p>
                                    <p className={cl.bannerFormText}><IntlMessage id={'bannerText3'}/></p>
                                    <br/>
                                </div>

                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormNameError'}/>,
                                },
                                ]} className={classes.formInputBlock} name="name">
                                    <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'name'})}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormEmailError'}/>,
                                },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (EmailValidator.isValid(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t('emailRules')));
                                        },
                                    }),
                                ]} className={classes.formInputBlock} name="email">
                                    <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'email'})}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormTelError'}/>,
                                },
                                ]} className={classes.formInputBlock} name="telNumber">
                                    <InputMask
                                        mask="+9 (999) 999-9999"
                                        className={classes.formInput}
                                        placeholder={IntlMessageText.getText({id: 'telNumber'})}
                                    >
                                        {(inputProps) => <Input {...inputProps} />}
                                    </InputMask>
                                    {/*<Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'telNumber'})}/>*/}
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'requiredField'}/>,
                                },
                                ]} className={classes.formInput} style={{width: "100%", padding: 0}} name="categoryId">
                                    <Select
                                        options={categories}
                                        placeholder={<IntlMessage id={'categoryCourse'}/>}
                                    />
                                </FormItem>
                                <FormItem className={classes.formInputBlock}>
                                    <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                                        <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                                            <IntlMessage id={'send'}/>
                                        </GreyButton>
                                    </Flex>
                                </FormItem>
                            </Form>
                        </Fade>
                    </Flex>

                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

const InfoCard = ({title, text, icon}) => {
    return (
        <Flex gap={20} align={"flex-start"} justify={"center"}>
            {icon}
            <Flex gap={10} vertical align={"center"} justify={"center"}>
                <MyText strong style={{color: clrs.blackerBlue}}>
                    {text}
                </MyText>
                <MyText style={{color: clrs.whiterBlacker, maxWidth: 200, textAlign: "center"}}>
                    {title}
                </MyText>

            </Flex>
        </Flex>
    )
}

const GridTable = ({ columns, data }) => {
    const gridTemplate = columns
        .map((col) => col.width || "auto")
        .join(" ");

    return (
        <div
            style={{
                width: "100%",
                border: "0.5px solid #D7D7D7",
                borderRadius: "20px",
                overflow: "hidden",
                minWidth: 500,
            }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: gridTemplate,
                    backgroundColor: clrs.yellow,
                    color: "black",
                    borderBottom: "1px solid #D7D7D7"
                }}
            >
                {columns.map((col, i) => (
                    <div
                        key={i}
                        style={{
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold"
                        }}
                    >
                        {col.title}
                    </div>
                ))}
            </div>

            {data.map((record, rowIndex) => (
                <div
                    key={rowIndex}
                    style={{
                        display: "grid",
                        gridTemplateColumns: gridTemplate,
                        borderBottom:
                            rowIndex !== data.length - 1
                                ? "1px solid #D7D7D7"
                                : "none"
                    }}
                >
                    {columns.map((col, colIndex) => (
                        <div
                            key={colIndex}
                            style={{
                                padding: "8px",
                                textAlign: "center"
                            }}
                        >
                            {col.render
                                ? col.render(null, record)
                                : record[col.dataIndex]}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};


export default FoundationPage;