import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Card, Flex, Form, Input, message, Radio, Select, Steps, Table, Tabs} from "antd";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import HorizontalDivider from "../../../../../components/Divider/HorizontalDivider";
import {useDispatch, useSelector} from "react-redux";
import {LocalName} from "../../../../../utils/LocalName";
import classes from "../../Applications/application.module.css";
import FormItem from "antd/es/form/FormItem";
import MyText from "../../../../../components/UI/Text/MyText";
import OrderService from "../../../../../services/OrderService";
import TeacherService from "../../../../../services/TeacherService";
import {clrs} from "../../../../../constants/colors";
import {getAllApplicationsPageable} from "../../../../../store/slices/ApplicationsListSlice";
import studentsListForOrder, {getAllStudentsForOrder} from "../../../../../store/slices/StudentListForOrderSlice";
import {addStudent, remoteStudent} from "../../../../../store/slices/GroupStudentListForOrderSlice";
import {getAllTeachersForOrder} from "../../../../../store/slices/TeachersListForOrderSlice";
import {addGroups} from "../../../../../store/slices/RealGroupsForOrderSlice";
import GroupService from "../../../../../services/GroupService";
import {EyeOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import cl from './ordercreate.module.css';
import FullLoading from "../../../../../components/LoadingComponents/FullLoading";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";

const OrderCreate = () => {

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const categoryId = Form.useWatch('categoryId', form);

    const [currentStep, setCurrentStep] = useState(0);

    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const [categories, setCategories] = useState([]);
    const categoriesList = useSelector((state) => state.categoriesList);

    const {t} = useTranslation();

    const [selectedGroups, setSelectedGroups] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCategories(
            categoriesList.categories.map((category) => {
                return {value: category.id, label: LocalName.getName(category)};
            })
        );
    }, [categoriesList.categories]);

    function isUUID(str) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return uuidRegex.test(str);
    }

    async function isNextStepAvailable(nextStep) {
        if (currentStep === 0) {
            console.log(categoryId)
            if (categoryId === null || categoryId === undefined) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            if (!categoryId) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            if (!isUUID(categoryId)) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            if (selectedGroups.length === 0) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            setSelectedCategoryId(categoryId);
        }
        if (nextStep === 2) {
            console.log(form.getFieldsValue())
            let createOrderRequestBody = {
                categoryId: selectedCategoryId,
                groups: []
            }
            for (const group of selectedGroups) {
                if (form.getFieldValue("group." + group.id + ".startDate") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".endDate") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                // ------------------
                if (form.getFieldValue("group." + group.id + ".isGeneralTime") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                // ------------------
                if (form.getFieldValue("group." + group.id + ".isGeneralTime") === "true") {
                    if (form.getFieldValue("group." + group.id + ".generalTime1") === undefined) {
                        message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                        return false;
                    }
                    if (form.getFieldValue("group." + group.id + ".generalTime2") === undefined) {
                        message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                        return false;
                    }
                } else {
                    if (form.getFieldValue("group." + group.id + ".isGeneralTime") === "false") {
                        if (form.getFieldValue("group." + group.id + ".isMonday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".mondayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".mondayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }

                        if (form.getFieldValue("group." + group.id + ".isTuesday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".tuesdayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".tuesdayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }

                        if (form.getFieldValue("group." + group.id + ".isWednesday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".wednesdayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".wednesdayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }

                        if (form.getFieldValue("group." + group.id + ".isThursday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".thursdayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".thursdayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }

                        if (form.getFieldValue("group." + group.id + ".isFriday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".fridayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".fridayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }

                        if (form.getFieldValue("group." + group.id + ".isSaturday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".saturdayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".saturdayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }

                        if (form.getFieldValue("group." + group.id + ".isSunday") === "true") {
                            if (form.getFieldValue("group." + group.id + ".sundayTime1") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                            if (form.getFieldValue("group." + group.id + ".sundayTime2") === undefined) {
                                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                return false;
                            }
                        }
                    } else {
                        message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                        return false;
                    }
                }
                // ------------------
                if (form.getFieldValue("group." + group.id + ".isMonday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".isTuesday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".isWednesday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".isThursday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".isFriday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".isSaturday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group.id + ".isSunday") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                // ------------------

                if (form.getFieldValue("group." + group.id + ".isMonday") === "false") {
                    if (form.getFieldValue("group." + group.id + ".isTuesday") === "false") {
                        if (form.getFieldValue("group." + group.id + ".isWednesday") === "false") {
                            if (form.getFieldValue("group." + group.id + ".isThursday") === "false") {
                                if (form.getFieldValue("group." + group.id + ".isFriday") === "false") {
                                    if (form.getFieldValue("group." + group.id + ".isSaturday") === "false") {
                                        if (form.getFieldValue("group." + group.id + ".isSunday") === "false") {
                                            message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                                            return false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                const i = selectedGroups.indexOf(group);
                let groupRequest = {
                    groupId: group.id
                }
                // ------------------
                if (form.getFieldValue("group." + group.id + ".isGeneralTime") === "true") {
                    groupRequest["generalTime1"] = form.getFieldValue("group." + group.id + ".generalTime1");
                    groupRequest["generalTime2"] = form.getFieldValue("group." + group.id + ".generalTime2");
                } else {
                    if (form.getFieldValue("group." + group.id + ".isGeneralTime") === "false") {
                        if (form.getFieldValue("group." + group.id + ".isMonday") === "true") {
                            groupRequest["mondayTime1"] = form.getFieldValue("group." + group.id + ".mondayTime1");
                            groupRequest["mondayTime2"] = form.getFieldValue("group." + group.id + ".mondayTime2");
                        }

                        if (form.getFieldValue("group." + group.id + ".isTuesday") === "true") {
                            groupRequest["tuesdayTime1"] = form.getFieldValue("group." + group.id + ".tuesdayTime1");
                            groupRequest["tuesdayTime2"] = form.getFieldValue("group." + group.id + ".tuesdayTime2");
                        }

                        if (form.getFieldValue("group." + group.id + ".isWednesday") === "true") {
                            groupRequest["wednesdayTime1"] = form.getFieldValue("group." + group.id + ".wednesdayTime1");
                            groupRequest["wednesdayTime2"] = form.getFieldValue("group." + group.id + ".wednesdayTime2");
                        }

                        if (form.getFieldValue("group." + group.id + ".isThursday") === "true") {
                            groupRequest["thursdayTime1"] = form.getFieldValue("group." + group.id + ".thursdayTime1");
                            groupRequest["thursdayTime2"] = form.getFieldValue("group." + group.id + ".thursdayTime2");
                        }

                        if (form.getFieldValue("group." + group.id + ".isFriday") === "true") {
                            groupRequest["fridayTime1"] = form.getFieldValue("group." + group.id + ".fridayTime1");
                            groupRequest["fridayTime2"] = form.getFieldValue("group." + group.id + ".fridayTime2");
                        }

                        if (form.getFieldValue("group." + group.id + ".isSaturday") === "true") {
                            groupRequest["saturdayTime1"] = form.getFieldValue("group." + group.id + ".saturdayTime1");
                            groupRequest["saturdayTime2"] = form.getFieldValue("group." + group.id + ".saturdayTime2");
                        }

                        if (form.getFieldValue("group." + group.id + ".isSunday") === "true") {
                            groupRequest["sundayTime1"] = form.getFieldValue("group." + group.id + ".sundayTime1");
                            groupRequest["sundayTime2"] = form.getFieldValue("group." + group.id + ".sundayTime2");
                        }
                    }
                }
                // ------------------
                groupRequest["isMonday"] = form.getFieldValue("group." + group.id + ".isMonday");
                groupRequest["isTuesday"] = form.getFieldValue("group." + group.id + ".isTuesday");
                groupRequest["isWednesday"] = form.getFieldValue("group." + group.id + ".isWednesday");
                groupRequest["isThursday"] = form.getFieldValue("group." + group.id + ".isThursday");
                groupRequest["isFriday"] = form.getFieldValue("group." + group.id + ".isFriday");
                groupRequest["isSaturday"] = form.getFieldValue("group." + group.id + ".isSaturday");
                groupRequest["isSunday"] = form.getFieldValue("group." + group.id + ".isSunday");
                groupRequest["isGeneralTime"] = form.getFieldValue("group." + group.id + ".isGeneralTime");

                groupRequest["startDate"] = form.getFieldValue("group." + group.id + ".startDate");
                groupRequest["endDate"] = form.getFieldValue("group." + group.id + ".endDate");

                createOrderRequestBody.groups.push(groupRequest);
            }
            console.log(createOrderRequestBody);
            setLoading(true);
            message.loading(<IntlMessage id={'loading'}/>, 0);
            await OrderService.saveOrder(createOrderRequestBody)
                .then((r) => {
                    message.destroy();
                    message.success(<IntlMessage id={'success'}/>, 5);
                    console.log(r);
                    form.resetFields();
                    setSelectedGroups([]);
                    setSelectedCategoryId(null);
                    setCurrentStep(0);
                })
                .catch((r) => {
                    message.destroy();
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
                .finally(() => {
                    setLoading(false);
                })
            return false;
        }
        return true;
    }

    const items = [
        {
            key: 1,
            title: <IntlMessage id={'ordersPage.groups'}/>,
            disabled: currentStep > 0
        },
        {
            key: 2,
            title: <IntlMessage id={'ordersPage.groups.2'}/>
        },
        {
            key: 3,
            title: <IntlMessage id={'ordersPage.finish'}/>
        },
    ]

    const steps = [
        {
            key: 1,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical gap={20}>
                    <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                              label={t('category')}
                              labelCol={{span: 24}}
                              className={classes.formInput} style={{width: '100%', padding: 0}} name="categoryId">
                        <Select options={categories} placeholder={IntlMessageText.getText({id: "categoryCourse"})}/>
                    </FormItem>
                    {categoryId &&
                        <GetGroupsTable form={form} selectedGroups={selectedGroups}
                                        setSelectedGroups={setSelectedGroups}/>}
                </Flex>
            </>,
        },
        {
            key: 2,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical>
                    <Tabs
                        type="card"
                        defaultActiveKey={"1"}
                        tabPosition={"top"}
                        items={selectedGroups.map((group, i) => {
                            return {
                                label: LocalName.getName(group) + " " + group.number,
                                key: new Date() + group + i,
                                children: <GetStep3GroupContent group={group} form={form}/>
                            };
                        })}
                    />
                </Flex>
            </>,
        },
        {
            key: 3,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical align={"center"} justify={"center"}>

                </Flex>
            </>,
        },
    ];

    return (
        <Flex style={{width: "100%", position: "relative", minWidth: 800}} gap={20} vertical>
            <BlockLoading isLoading={loading}/>
            <Steps
                onChange={async (value) => {
                    if (value > currentStep + 1) {
                        message.warning(<IntlMessage id={'ordersPage.stepError'}/>, 5);
                    } else {
                        if (value === currentStep + 1) {
                            if (await isNextStepAvailable(value)) {
                                if (value !== 2) {
                                    setCurrentStep(value)
                                }
                            }

                        } else {
                            setCurrentStep(value)
                        }

                        // if (currentStep > 1 && value < 2) {
                        //     message.warning(<IntlMessage id={'ordersPage.step.1.2.Error'}/>, 5);
                        // } else {
                        //     if (value === currentStep + 1) {
                        //         if (isNextStepAvailable(value)) {
                        //             setCurrentStep(value)
                        //         }
                        //     } else {
                        //         setCurrentStep(value)
                        //     }
                        // }
                    }
                }}
                current={currentStep} items={items} labelPlacement={"vertical"} size={"small"}/>
            <HorizontalDivider style={{backgroundColor: "#e5e5e5"}}/>
            <Form form={form} className={classes.form}>
                {steps[currentStep].content}
            </Form>
        </Flex>
    );
};

const GetGroupsTable = ({form, selectedGroups, setSelectedGroups}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const [initialLoading, setInitialLoading] = useState(true);

    const [groups, setGroups] = useState([]);

    const categoryId = Form.useWatch('categoryId', form);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        if (categoryId) {
            setCurrentPage(1);
            handleFetchData(1, pageSize);
            setInitialLoading(false);
        }
    }, [categoryId]);

    useEffect(() => {
        if (categoryId) {
            if (!initialLoading) {
                handleFetchData(currentPage, pageSize);
            }
        }
    }, [currentPage, pageSize]);

    async function handleFetchData(currentPage, pageSize) {
        setLoading(true);
        await GroupService.getGroups(currentPage, pageSize, categoryId)
            .then((r) => {
                setGroups(r.data);
                setHasMore(r.hasMore);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const columns = [
        {
            title: <IntlMessage id={'name2'}/>,
            render: (_, record) => (
                <p>{LocalName.getName(record) + " " + record.number}</p>
            ),
            // width: '5%',
        },
        {
            title: <IntlMessage id={'ordersPage.teacher'}/>,
            render: (_, record) => (
                <p>{record.teacher.profile.fullName}</p>
            ),
            // width: '5%',
        },
        {
            title: <IntlMessage id={'ordersPage.academicYear'}/>,
            render: (_, record) => (
                <p>{record.academicYear}</p>
            ),
            // width: '5%',
        },
    ];

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    const onSelectChange = (record, selected, selectedRows) => {
        // setSelectedRowKeys(newSelectedRowKeys);
        if (selected) {
            setSelectedRowKeys([...selectedRowKeys, record.id]);
            setSelectedGroups([...selectedGroups, record]);
        } else {
            setSelectedRowKeys(selectedRowKeys.filter(r => r !== record.id));
            setSelectedGroups(selectedGroups.filter(g => g.id !== record.id));
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectChange,
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setSelectedRowKeys([...selectedRowKeys, ...changeRows.map(r => r.id)]);
                setSelectedGroups([...selectedGroups, ...changeRows]);
            } else {
                setSelectedRowKeys(selectedRowKeys.filter(r => !changeRows.map(r => r.id).includes(r)));
                setSelectedGroups(selectedGroups.filter(g => !changeRows.map(r => r.id).includes(g.id)));
            }
        }
    };

    return (
        <Table
            rowSelection={rowSelection}
            bordered
            columns={columns}
            dataSource={groups}
            pagination={{
                current: currentPage,
                total: hasMore
                    ? currentPage * pageSize + 1
                    : currentPage * pageSize,
                defaultPageSize: pageSize,
                showSizeChanger: true,
                pageSize: pageSize,
                pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                onChange: handlePaginationChange,
                // onShowSizeChange: handlePaginationChange,
            }}
            rowKey={record => record.id}
            loading={loading}
            style={{
                width: "100%",
            }}
            scroll={{x: 800}}
        />
    )
}
const GetStep3GroupContent = memo(({group, form}) => {
    const isGeneral = Form.useWatch('group.' + group.id + '.isGeneralTime', form);
    const generalTime1 = Form.useWatch('group.' + group.id + '.generalTime1', form);
    const generalTime2 = Form.useWatch('group.' + group.id + '.generalTime2', form);
    const mondayTime1 = Form.useWatch('group.' + group.id + '.mondayTime1', form);
    const mondayTime2 = Form.useWatch('group.' + group.id + '.mondayTime2', form);
    const tuesdayTime1 = Form.useWatch('group.' + group.id + '.tuesdayTime1', form);
    const tuesdayTime2 = Form.useWatch('group.' + group.id + '.tuesdayTime2', form);
    const wednesdayTime1 = Form.useWatch('group.' + group.id + '.wednesdayTime1', form);
    const wednesdayTime2 = Form.useWatch('group.' + group.id + '.wednesdayTime2', form);
    const thursdayTime1 = Form.useWatch('group.' + group.id + '.thursdayTime1', form);
    const thursdayTime2 = Form.useWatch('group.' + group.id + '.thursdayTime2', form);
    const fridayTime1 = Form.useWatch('group.' + group.id + '.fridayTime1', form);
    const fridayTime2 = Form.useWatch('group.' + group.id + '.fridayTime2', form);
    const saturdayTime1 = Form.useWatch('group.' + group.id + '.saturdayTime1', form);
    const saturdayTime2 = Form.useWatch('group.' + group.id + '.saturdayTime2', form);
    const sundayTime1 = Form.useWatch('group.' + group.id + '.sundayTime1', form);
    const sundayTime2 = Form.useWatch('group.' + group.id + '.sundayTime2', form);

    const isMonday = Form.useWatch("group." + group.id + ".isMonday", form);
    const isTuesday = Form.useWatch('group.' + group.id + '.isTuesday', form);
    const isWednesday = Form.useWatch('group.' + group.id + '.isWednesday', form);
    const isThursday = Form.useWatch('group.' + group.id + '.isThursday', form);
    const isFriday = Form.useWatch('group.' + group.id + '.isFriday', form);
    const isSaturday = Form.useWatch('group.' + group.id + '.isSaturday', form);
    const isSunday = Form.useWatch('group.' + group.id + '.isSunday', form);

    const init = Form.useWatch('group.' + group.id + '.init', form);

    useEffect(() => {
        if (init !== undefined) {
            if (isGeneral === "true") {
                form.setFieldValue("group." + group.id + ".mondayTime1", "");
                form.setFieldValue("group." + group.id + ".mondayTime2", "");
                form.setFieldValue("group." + group.id + ".tuesdayTime1", "");
                form.setFieldValue("group." + group.id + ".tuesdayTime2", "");
                form.setFieldValue("group." + group.id + ".wednesdayTime1", "");
                form.setFieldValue("group." + group.id + ".wednesdayTime2", "");
                form.setFieldValue("group." + group.id + ".thursdayTime1", "");
                form.setFieldValue("group." + group.id + ".thursdayTime2", "");
                form.setFieldValue("group." + group.id + ".fridayTime1", "");
                form.setFieldValue("group." + group.id + ".fridayTime2", "");
                form.setFieldValue("group." + group.id + ".saturdayTime1", "");
                form.setFieldValue("group." + group.id + ".saturdayTime2", "");
                form.setFieldValue("group." + group.id + ".sundayTime1", "");
                form.setFieldValue("group." + group.id + ".sundayTime2", "");
                // console.log("here")

            } else {
                form.setFieldValue("group." + group.id + ".generalTime1", "");
                form.setFieldValue("group." + group.id + ".generalTime2", "");
            }
        } else {
            form.setFieldValue("group." + group.id + ".init", "false");
        }

        // console.log(isGeneral)
        // console.log(isMonday)
        // console.log(form.getFieldValue("group." + group.id + ".isMonday"));
        // console.log(form.getFieldsValue());
        console.log(form.getFieldsValue())
    }, [isGeneral]);

    useEffect(() => {
        if (init === undefined) {
            form.setFieldValue("group." + group.id + ".isMonday", "false");
            form.setFieldValue("group." + group.id + ".isTuesday", "false");
            form.setFieldValue("group." + group.id + ".isWednesday", "false");
            form.setFieldValue("group." + group.id + ".isThursday", "false");
            form.setFieldValue("group." + group.id + ".isFriday", "false");
            form.setFieldValue("group." + group.id + ".isSaturday", "false");
            form.setFieldValue("group." + group.id + ".isSunday", "false");
            form.setFieldValue("group." + group.id + ".isGeneralTime", "true");
        }
    }, []);

    const options = [
        {
            label: <IntlMessage id={'ordersPage.generalTime'}/>,
            value: "true",
        },
        {
            label: <IntlMessage id={'ordersPage.timeDyDay'}/>,
            value: "false",
        },
    ];

    return (
        <Flex style={{width: "100%", position: "relative"}} gap={20} vertical>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + group.id + ".startDate"}
                      label={<IntlMessage id={'ordersPage.startDate'}/>}
                      labelCol={{ span: 24 }}
            >
                <input className={classes.formInput} style={{border: `1px solid ${clrs.whiter2}`, borderRadius: 8}} type={"date"}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + group.id + ".endDate"}
                      label={<IntlMessage id={'ordersPage.endDate'}/>}
                      labelCol={{ span: 24 }}
            >
                <input className={classes.formInput} style={{border: `1px solid ${clrs.whiter2}`, borderRadius: 8}} type={"date"}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + group.id + ".isGeneralTime"}
                      // label={<IntlMessage id={'ordersPage.endDate'}/>}
                      // labelCol={{ span: 24 }}
            >
                <Radio.Group
                    block
                    options={options}
                    defaultValue="true"
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(e) => form.setFieldValue("group." + group.id + ".isGeneralTime", e.target.value)}
                />
            </FormItem>
            <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={20}>
                <FormItem style={{margin: 0}} name={"group." + group.id + ".generalTime1"}>
                    <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                           placeholder={"Start Time general"} disabled={isGeneral !== "true"}/>
                </FormItem>
                <FormItem style={{margin: 0}} name={"group." + group.id + ".generalTime2"}>
                    <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                           placeholder={"End Time general"} disabled={isGeneral !== "true"}/>
                </FormItem>

            </Flex>
            <Flex style={{width: "100%"}} wrap={"wrap"} gap={20} align={"flex-start"} justify={"flex-start"}>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isMonday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isMonday", newValue);
                        }} style={{width: "100%", height: "100%", backgroundColor: isMonday === "true" ? clrs.blueForIcon : clrs.white, color: isMonday === "true" ? clrs.white : clrs.black}} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.monday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150,
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".mondayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".mondayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isTuesday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isTuesday", newValue);
                        }} style={{
                            width: "100%", height: "100%",
                            backgroundColor: isTuesday === "true" ? clrs.blueForIcon : clrs.white,
                            color: isTuesday === "true" ? clrs.white : clrs.black
                        }} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.tuesday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".tuesdayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".tuesdayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isWednesday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isWednesday", newValue);
                        }} style={{
                            width: "100%", height: "100%",
                            backgroundColor: isWednesday === "true" ? clrs.blueForIcon : clrs.white,
                            color: isWednesday === "true" ? clrs.white : clrs.black
                        }} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.wednesday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".wednesdayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".wednesdayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isThursday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isThursday", newValue);
                        }} style={{
                            width: "100%", height: "100%",
                            backgroundColor: isThursday === "true" ? clrs.blueForIcon : clrs.white,
                            color: isThursday === "true" ? clrs.white : clrs.black
                        }} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.thursday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".thursdayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".thursdayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isFriday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isFriday", newValue);
                        }} style={{
                            width: "100%", height: "100%",
                            backgroundColor: isFriday === "true" ? clrs.blueForIcon : clrs.white,
                            color: isFriday === "true" ? clrs.white : clrs.black
                        }} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.friday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".fridayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".fridayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isSaturday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isSaturday", newValue);
                        }} style={{
                            width: "100%", height: "100%",
                            backgroundColor: isSaturday === "true" ? clrs.blueForIcon : clrs.white,
                            color: isSaturday === "true" ? clrs.white : clrs.black
                        }} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.saturday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".saturdayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".saturdayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
                <Card
                    title={
                        <Flex onClick={() => {
                            const newValue = isSunday === "true" ? "false" : "true";
                            form.setFieldValue("group." + group.id + ".isSunday", newValue);
                        }} style={{
                            width: "100%", height: "100%",
                            backgroundColor: isSunday === "true" ? clrs.blueForIcon : clrs.white,
                            color: isSunday === "true" ? clrs.white : clrs.black
                        }} align={"center"} justify={"center"} >
                            <MyText size={"small"} style={{textAlign: "center"}}><IntlMessage id={'ordersPage.sunday'}/></MyText>
                        </Flex>
                    }
                    variant={"borderless"}
                    style={{
                        width: "100%",
                        maxWidth: 150
                    }}
                    className={cl.myCardBlock}
                >
                    <Flex style={{width: "100%", height: isGeneral === "true" ? 0 : 85, overflow: "hidden"}} vertical align={"center"} justify={"center"} gap={20}>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".sundayTime1"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"Start Time"} disabled={isGeneral === "true"}/>
                        </FormItem>
                        <FormItem style={{margin: 0}} name={"group." + group.id + ".sundayTime2"}>
                            <input className={classes.formInput} style={{padding: "2px 10px"}} type={"time"}
                                   placeholder={"End Time"} disabled={isGeneral === "true"}/>
                        </FormItem>

                    </Flex>
                </Card>
            </Flex>

            {/*<FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}*/}
            {/*          className={classes.formInputBlock} name={"group." + groupName + ".time.kz"}*/}
            {/*          label={<IntlMessage id={'ordersPage.time'}/>}*/}
            {/*          labelCol={{ span: 24 }}*/}
            {/*>*/}
            {/*    <Input.TextArea rootClassName={classes.formInput} autoSize={{ minRows: 6, maxRows: 10 }}/>*/}
            {/*</FormItem>*/}
            {/*<FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}*/}
            {/*          className={classes.formInputBlock} name={"group." + groupName + ".time.ru"}*/}
            {/*          label={<IntlMessage id={'ordersPage.time'}/>}*/}
            {/*          labelCol={{ span: 24 }}*/}
            {/*>*/}
            {/*    <Input.TextArea rootClassName={classes.formInput} autoSize={{ minRows: 6, maxRows: 10 }}/>*/}
            {/*</FormItem>*/}
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isMonday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isTuesday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isWednesday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isThursday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isFriday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isSaturday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".isSunday"}></FormItem>
            <FormItem style={{margin: 0}} name={"group." + group.id + ".init"}></FormItem>
        </Flex>
    )
})


export default OrderCreate;