import React from 'react';
import {message} from "antd";
import FormService from "../services/FormService";
import {NCA_LAYER_URL} from "../constants/api";
import IntlMessage from "../components/IntlMessage/IntlMessage";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import SignatureService from "../services/SignatureService";
import {ALLOWED_STORAGES, DIRECTOR_FIO, EXT_KEY_USAGE_OIDS} from "../constants/ncaLayer";
import i18n from "i18next";
import UserSignService from "../services/UserSignService";

const currentLocale = i18n.language;

export async function signUserSign(whom, iin, id) {
    let webSocket;
    try {
        webSocket = await connect();
        if (!webSocket) throw new Error("WebSocket is undefined after connection attempt");
    } catch (connectError) {
        message.error(<IntlMessage id="NCAOpenError"/>, 5);
        throw new Error("NCAOpenError");
    }

    const rs = await generateUserSignXML(id);
    let signData = rs;
    const signXml = {
        module: "kz.gov.pki.knca.basics",
        method: "sign",
        args: {
            allowedStorages: ALLOWED_STORAGES,
            format: "xml",
            data: signData,
            signingParams: {
                decode: "false",
                encapsulate: "true",
                digested: "false",
                tsaProfile: null,
            },
            signerParams: {
                extKeyUsageOids: EXT_KEY_USAGE_OIDS,
                iin: iin,
                bin: "",
                serialNumber: "",
                chain: null,
            },
            locale: currentLocale,
        },
    };
    webSocket.send(JSON.stringify(signXml));
    const response = await new Promise((resolve, reject) => {
        webSocket.onmessage = ({data}) => {
            try {
                // console.log(data)
                const response = JSON.parse(data);
                if (!response) return reject("Invalid WebSocket response");

                if (response.status) {
                    resolve(response.body);
                } else {
                    reject(`Error code: ${response.code}`);
                }
            } catch (error) {
                reject("Error parsing WebSocket response");
            }
        };
    });

    webSocket.onmessage = null;
    if (response?.result) {
        const xmlBase64 = btoa(new TextEncoder().encode(response.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const saveResponse = await UserSignService.saveSignatureInUserSign(xmlBase64, whom, id);

        if (saveResponse?.status === 200) {
            message.success(<IntlMessage id="success"/>, 5);
        } else {
            console.error("Save signature failed:", saveResponse);
            message.error(<IntlMessage id="error"/>, 5);
        }
    } else {
        throw new Error("Invalid result from WebSocket response");
    }
}


async function generateUserSignXML(id) {
    const rs = await UserSignService.convertToXML(id);
    if (!!rs && !!rs?.status && rs.status === 200 && !!rs.data) {
        return rs.data;
    }
}

export async function signContract(signatureType, getForm, formId, setIsLoadingDiscount) {
    if (signatureType === 'director') {
        const rs = await generateSignaturePayload(signatureType, getForm, formId);
        let signData;
        if (signatureType === 'director' && formId === null) {
            signData = rs.map(signature => signature.xml);
        } else if (rs) {
            signData = rs.xml;
        } else {
            throw new Error("Invalid response from SignatureService");
        }
        if (Array.isArray(signData)) {
            const xmlBase64Array = signData.map(xml => btoa(new TextEncoder().encode(xml).reduce((data, byte) => data + String.fromCharCode(byte), '')));
            const saveResponses = await Promise.allSettled(
                xmlBase64Array.map(xmlBase64 =>
                    SignatureService.signDirector(
                        xmlBase64
                    )
                )
            );

            const allSuccess = saveResponses.every(res => res?.status === 200 || res?.status === "fulfilled");
            if (allSuccess) {
                message.success(<IntlMessage id="success"/>, 5);
            } else {
                console.error("Не все подписи удалось сохранить:", saveResponses);
                message.error(<IntlMessage id="error"/>, 5);
            }
        } else {
            const xmlBase64 = btoa(new TextEncoder().encode(signData).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            const saveResponse = await SignatureService.signDirector(xmlBase64);
            if (saveResponse?.status === 200) {
                message.success(<IntlMessage id="success"/>, 5);
            } else {
                console.error("Save signature failed:", saveResponse);
                message.error(<IntlMessage id="error"/>, 5);
            }
        }
    } else {
        let webSocket;
        try {
            try {
                webSocket = await connect();
                if (!webSocket) throw new Error("WebSocket is undefined after connection attempt");
            } catch (connectError) {
                message.error(<IntlMessage id="NCAOpenError"/>, 5);
                throw new Error("NCAOpenError");
            }

            const rs = await generateSignaturePayload(signatureType, getForm, formId);
            let signData;
            if (signatureType === 'director' && formId === null) {
                signData = rs.map(signature => signature.xml);
            } else if (rs) {
                signData = rs.xml;
            } else {
                throw new Error("Invalid response from SignatureService");
            }
            const signXml = {
                module: "kz.gov.pki.knca.basics",
                method: "sign",
                args: {
                    allowedStorages: ALLOWED_STORAGES,
                    format: "xml",
                    data: signData,
                    signingParams: {
                        decode: "false",
                        encapsulate: "true",
                        digested: "false",
                        tsaProfile: null,
                    },
                    signerParams: {
                        extKeyUsageOids: EXT_KEY_USAGE_OIDS,
                        iin: signatureType !== 'director' ? rs?.iinToXML : '',
                        bin: "",
                        serialNumber: "",
                        chain: null,
                    },
                    locale: currentLocale,
                },
            };
            webSocket.send(JSON.stringify(signXml));
            const response = await new Promise((resolve, reject) => {
                webSocket.onmessage = ({data}) => {
                    try {
                        // console.log(data)
                        const response = JSON.parse(data);
                        if (!response) return reject("Invalid WebSocket response");

                        if (response.status) {
                            resolve(response.body);
                        } else {
                            reject(`Error code: ${response.code}`);
                        }
                    } catch (error) {
                        reject("Error parsing WebSocket response");
                    }
                };
            });

            webSocket.onmessage = null;
            if (response?.result) {
                if (signatureType === 'director' && Array.isArray(response.result)) {
                    const xmlBase64Array = response.result.map(xml => btoa(new TextEncoder().encode(xml).reduce((data, byte) => data + String.fromCharCode(byte), '')));
                    const saveResponses = await Promise.allSettled(
                        xmlBase64Array.map(xmlBase64 =>
                            FormService.saveAdminSignatureInForm(
                                xmlBase64
                            )
                        )
                    );

                    const allSuccess = saveResponses.every(res => res?.status === 200 || res?.status === "fulfilled");
                    if (allSuccess) {
                        message.success(<IntlMessage id="success"/>, 5);
                    } else {
                        console.error("Не все подписи удалось сохранить:", saveResponses);
                        message.error(<IntlMessage id="error"/>, 5);
                    }
                } else {
                    const xmlBase64 = btoa(new TextEncoder().encode(response.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                    const saveResponse = await FormService.saveSignatureInForm(formId, rs.iinToXML, rs.fullNameToXML, rs.docTemplate, rs.docDetails, rs.date, xmlBase64);

                    if (saveResponse?.status === 200) {
                        message.success(<IntlMessage id="success"/>, 5);
                    } else {
                        console.error("Save signature failed:", saveResponse);
                        message.error(<IntlMessage id="error"/>, 5);
                    }
                }
            } else {
                throw new Error("Invalid result from WebSocket response");
            }
        } catch (error) {
            console.error("Error during signing process:", error);
            message.error(<IntlMessage id="error"/>, 5);
            throw error;
        } finally {
            if (webSocket) webSocket.close();
            // if (setIsLoadingDiscount) setIsLoadingDiscount(false);
        }
    }
}

async function generateSignaturePayload(signatureType, getForm, formId) {
    if (signatureType === 'director') {
        if (formId !== null) {
            const rs = await SignatureService.convertToXML(DIRECTOR_FIO, getForm.form.docTemplate, getForm.form.docDetails, getForm.form.documentId)
            if (rs?.status === 200 && !!rs.data && !!rs.data.xml) {
                return {
                    xml: rs.data.xml
                };
            }
        } else {
            const rs = await SignatureService.convertApplicationsToFormXmls(getForm);
            if (rs?.status === 200 && Array.isArray(rs.data) && rs.data.length > 0) {
                return rs.data.map(item => ({
                    xml: item
                }));
            }
        }
    } else {
        const {fullName, iin, parentIin, parentFullName} = getForm.form?.userData || {};
        const {docDetails, docTemplate, documentId} = getForm.form || {};
        const isParent = signatureType === 'parent';

        const fullNameToXML = isParent ? parentFullName : fullName;
        const iinToXML = isParent ? parentIin : iin;

        const rs = await SignatureService.convertToXML(fullNameToXML, docTemplate, docDetails, documentId);
        if (!!rs && !!rs?.status && rs.status === 200 && !!rs.data) {
            return {
                docDetails,
                docTemplate,
                fullNameToXML,
                iinToXML,
                xml: rs?.data?.xml,
                date: rs?.data?.signDate,
            }
        }
    }
}

export function connect() {
    if (connect.webSocket && connect.webSocket.readyState < 2) {
        // console.log("Reusing existing socket connection [state = " + connect.webSocket.readyState + "]: " + connect.webSocket.url);
        return Promise.resolve(connect.webSocket);
    }

    return new Promise(function (resolve, reject) {
        try {
            connect.webSocket = new WebSocket(NCA_LAYER_URL);
        } catch (error) {
            message.error(<IntlMessage id="NCAOpenError"/>, 5);
            throw new Error("NCAOpenError");
        }

        connect.webSocket.onopen = function () {
            // console.log("WebSocket connection opened [state = " + connect.webSocket.readyState + "]: " + connect.webSocket.url);
            resolve(connect.webSocket);
        };

        connect.webSocket.onerror = function (err) {
            message.error(<IntlMessage id="NCAOpenError"/>, 5);
            reject(err);
        };

        connect.webSocket.onclose = function (event) {
            if (event.wasClean) {
                // console.log("WebSocket connection closed cleanly.");
            } else {
                console.error("WebSocket connection closed unexpectedly.");
            }
            // console.log('Code: ' + event.code + ' Reason: ' + event.reason);
        };
    });
}


export async function generateDataToConvert(signatureType, getForm) {
    if (signatureType !== 'director') {
        const {fullName, iin, parentIin, parentFullName} = getForm.form?.userData || {};
        const docDetails = getForm.form?.docDetails;
        const docTemplate = getForm.form?.docTemplate;

        const {fullNameToXML, iinToXML} = signatureType === 'parent' ? {
            fullNameToXML: parentFullName,
            iinToXML: parentIin
        } : {fullNameToXML: fullName, iinToXML: iin};

        const rs = await SignatureService.convertToXML(fullNameToXML, docTemplate, docDetails);
        if (rs.status === 200 && rs.data?.xml && rs.data?.date) {
            return {
                docDetails: docDetails,
                docTemplate: docTemplate,
                fullNameToXML: fullNameToXML,
                iinToXML: iinToXML,
                xml: rs?.data?.xml
            };
        }
    } else {
        return {}
    }
}