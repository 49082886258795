import antdKkKZ from 'antd/es/locale/kk_KZ';
import antdRuRU from 'antd/es/locale/ru_RU';
import antdEnUS from 'antd/es/locale/en_US';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import kk from './locales/kk_KZ.json';
import ru from './locales/ru_RU.json';
import en from './locales/en_US.json';
import moment from 'moment';
import "moment/locale/ru";
import "moment/locale/kk";
import "moment/locale/en-gb";

export const resources = {
    ru: {
        translation: ru,
        antd: antdRuRU,
    },
    kk: {
        translation: kk,
        antd: antdKkKZ,
    },
    en: {
        translation: en,
        antd: antdEnUS,
    },
};

let lan = localStorage.getItem('lan') === "ENG" ? 'en' :
    (localStorage.getItem('lan') === "РУС" ? 'ru' :
        (localStorage.getItem('lan') === "ҚАЗ" ? 'kk' : 'ru'));

const setMomentLocale = (language) => {
    switch (language) {
        case 'kk':
            moment.locale('kk'); // Казахская локализация
            break;
        case 'ru':
            moment.locale('ru'); // Русская локализация
            break;
        case 'en':
        default:
            moment.locale('en'); // Английская локализация (по умолчанию)
            break;
    }
};


i18n.use(initReactI18next).init({
    resources,
    fallbackLng: lan,
    lng: lan,
    interpolation: {
        escapeValue: false,
    },
    detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
    }
});

// Устанавливаем локаль moment.js после инициализации
setMomentLocale(lan);

// Подписка на изменения языка
i18n.on('languageChanged', (lng) => {
    setMomentLocale(lng);
});

export default i18n;
