import React, {useEffect, useState} from 'react';
import {Flex, Form, Input, message} from "antd";
import classes from "../../Courses/CreateCourse/CreateCourse.module.css";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {clrs} from "../../../../../constants/colors";
import {useDispatch, useSelector} from "react-redux";
import InputMask from "react-input-mask";
import {getUserByToken} from "../../../../../store/slices/userSlice";
import FullLoading from "../../../../../components/LoadingComponents/FullLoading";
import UserService from "../../../../../services/UserService";

const PersonalData = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const firstName = Form.useWatch('firstName', form);
    const lastName = Form.useWatch('lastName', form);
    const middleName = Form.useWatch('middleName', form);
    const password = Form.useWatch('password', form);
    const confirmPassword = Form.useWatch('confirmPassword', form);
    const birthDay = Form.useWatch('birthDay', form);
    const address = Form.useWatch('address', form);
    const telNumber = Form.useWatch('telNumber', form);
    const iin = Form.useWatch('iin', form);
    const idCardNumber = Form.useWatch('idCardNumber', form);
    const idCardFromWhom = Form.useWatch('idCardFromWhom', form);
    const idCardDate = Form.useWatch('idCardDate', form);
    const firstNameInEnglish = Form.useWatch('firstNameInEnglish', form);
    const lastNameInEnglish = Form.useWatch('lastNameInEnglish', form);

    const fullNameParent = Form.useWatch('fullNameParent', form);
    const telNumberParent = Form.useWatch('telNumberParent', form);
    const iin_parent = Form.useWatch('iin_parent', form);
    const idCardNumber_parent = Form.useWatch('idCardNumber_parent', form);
    const idCardFromWhom_parent = Form.useWatch('idCardFromWhom_parent', form);
    const idCardDate_parent = Form.useWatch('idCardDate_parent', form);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const {user, isLoading} = useSelector(state => state.user);

    useEffect(() => {
        if (user === null) {
            dispatch(getUserByToken());
        }
    }, []);

    const handleInputChange = (e, fieldName) => {
        const inputValue = e.target.value;
        const formattedInput = inputValue.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        form.setFieldsValue(
            {[fieldName]: formattedInput}
        );
    };

    function handleCreate() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setIsLoadingCreate(true);
                await UserService.editUser(user.id, firstName, lastName, middleName, firstNameInEnglish, lastNameInEnglish, birthDay, address, iin, telNumber, idCardNumber, idCardDate, idCardFromWhom, fullNameParent, telNumberParent, iin_parent, idCardNumber_parent, idCardFromWhom_parent, idCardDate_parent)
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        form.resetFields();
                        dispatch(getUserByToken());
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoadingCreate(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    if (isLoading) {
        return <FullLoading/>;
    }


    return (
        <Form form={form} className={classes.form}
              initialValues={{
                  firstName: user.profile?.firstName,
                  middleName: user.profile?.middleName,
                  lastName: user.profile?.lastName,
                  firstNameInEnglish: user.profile?.firstNameInEnglish,
                  lastNameInEnglish: user.profile?.lastNameInEnglish,
                  birthDay: user.profile?.birthDay,
                  address: user.profile?.address,
                  telNumber: user.profile?.telNumber,
                  iin: user.profile?.iin,
                  idCardNumber: user.profile?.idCardNumber,
                  idCardFromWhom: user.profile?.idCardFromWhom || user.profile?.idCardFromWhom == '' ? user.profile?.idCardFromWhom : "МВД РК",
                  idCardDate: user.profile?.idCardDate,
                  fullNameParent: user.profile?.parentFullName,
                  telNumberParent: user.profile?.parentTelNumber,
                  iin_parent: user.profile?.parentIin,
                  idCardNumber_parent: user.profile?.parentIdCardNumber,
                  idCardFromWhom_parent: user.profile?.parentIdCardFromWhom || user.profile?.parentIdCardFromWhom == '' ? user.profile?.parentIdCardFromWhom : "МВД РК",
                  idCardDate_parent: user.profile?.parentIdCardDate
              }}>
            <BlockLoading isLoading={isLoadingCreate}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'firstName'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="firstName">
                <Input onChange={(e) => {
                    handleInputChange(e, "firstName")
                }} rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "firstName"})}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'lastName'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="lastName">
                <Input onChange={(e) => {
                    handleInputChange(e, "lastName")
                }} rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "lastName"})}/>
            </FormItem>
            <FormItem rules={[{
                required: false,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'middleName'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="middleName">
                <Input onChange={(e) => {
                    handleInputChange(e, "middleName")
                }} rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "middleName"})}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'firstNameInEnglish'}/>} labelCol={{span: 24}}
                      className={classes.formInputBlock} name="firstNameInEnglish">
                <Input onChange={(e) => {
                    handleInputChange(e, "firstNameInEnglish")
                }} rootClassName={classes.formInput}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'lastNameInEnglish'}/>} labelCol={{span: 24}}
                      className={classes.formInputBlock} name="lastNameInEnglish">
                <Input onChange={(e) => {
                    handleInputChange(e, "lastNameInEnglish")
                }}
                       rootClassName={classes.formInput}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'birthDay'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="birthDay">
                <Input type={'date'} rootClassName={classes.formInput}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'address'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="address">
                <Input rootClassName={classes.formInput}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'telNumber'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="telNumber">
                <InputMask
                    mask="+9 (999) 999-9999"
                    className={classes.formInput}
                >
                    {(inputProps) => <Input{...inputProps} />}
                </InputMask>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'iin'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="iin">
                <InputMask
                    mask="999999999999"
                    className={classes.formInput}
                >
                    {(inputProps) => <Input{...inputProps} />}
                </InputMask>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'idCardNumber'}/>} labelCol={{span: 24}}
                      className={classes.formInputBlock} name="idCardNumber">
                <Input rootClassName={classes.formInput}/>
            </FormItem>
            <FormItem rules={[{
                required: idCardNumber,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'idCardFromWhom'}/>} labelCol={{span: 24}}
                      className={classes.formInputBlock} name="idCardFromWhom">
                <Input rootClassName={classes.formInput}/>
            </FormItem>
            <FormItem rules={[{
                required: idCardNumber,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'idCardDate'}/>} labelCol={{span: 24}} className={classes.formInputBlock}
                      name="idCardDate">
                <Input type={'date'} rootClassName={classes.formInput}/>
            </FormItem>
            {
                user?.role.roleName === 'student' &&
                <>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'fullNameParentInDoc'}/>} labelCol={{span: 24}}
                              className={classes.formInputBlock} name="fullNameParent">
                        <Input onChange={(e) => {
                            handleInputChange(e, "fullNameParent")
                        }}
                               rootClassName={classes.formInput}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'telNumberParent'}/>} labelCol={{span: 24}}
                              className={classes.formInputBlock} name="telNumberParent">
                        <InputMask
                            mask="+9 (999) 999-9999"

                            className={classes.formInput}
                        >
                            {(inputProps) => <Input
                                {...inputProps} />}
                        </InputMask>
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'iin_parent'}/>} labelCol={{span: 24}}
                              className={classes.formInputBlock} name="iin_parent">
                        <Input
                            rootClassName={classes.formInput}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'idCardNumber_parent'}/>} labelCol={{span: 24}}
                              className={classes.formInputBlock} name="idCardNumber_parent">
                        <Input
                            rootClassName={classes.formInput}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'idCardFromWhom_parent'}/>} labelCol={{span: 24}}
                              className={classes.formInputBlock} name="idCardFromWhom_parent">
                        <Input
                            rootClassName={classes.formInput}/>
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'idCardDate_parent'}/>} labelCol={{span: 24}}
                              className={classes.formInputBlock} name="idCardDate_parent">
                        <Input  type={'date'}
                                rootClassName={classes.formInput}/>
                    </FormItem>
                </>
            }
            {/*<FormItem rules={[{*/}
            {/*    required: false,*/}
            {/*    message: <IntlMessage id={'requiredField'}/>,*/}
            {/*},*/}
            {/*]} label={<IntlMessage id={'password'}/>} labelCol={{span: 24}} className={classes.formInputBlock}*/}
            {/*          name="password">*/}
            {/*    <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "password"})}/>*/}
            {/*</FormItem>*/}
            {/*<FormItem rules={[{*/}
            {/*    required: password,*/}
            {/*    message: <IntlMessage id={'requiredField'}/>,*/}
            {/*},*/}
            {/*]} label={<IntlMessage id={'confirmPassword'}/>} labelCol={{span: 24}} className={classes.formInputBlock}*/}
            {/*          name="confirmPassword">*/}
            {/*    <Input rootClassName={classes.formInput}*/}
            {/*           placeholder={IntlMessageText.getText({id: "confirmPassword"})}/>*/}
            {/*</FormItem>*/}
            <FormItem className={classes.formInputBlock}>
                <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleCreate} backgroundColor={clrs.blackerBlue}
                                fontColor={clrs.white}><IntlMessage id={'edit'}/></GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default PersonalData;