import React, {useEffect, useState} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {useTranslation} from "react-i18next";
import "moment/locale/ru";
import "moment/locale/kk";
import "moment/locale/en-gb";

// Устанавливаем локализацию Moment.js
const localizer = momentLocalizer(moment);

const CalendarComponent = () => {

    const { t, i18n } = useTranslation();

    const messages = {
        today: "Сегодня",
        previous: "<",
        next: ">",
        month: "Месяц",
    };

    // Устанавливаем момент при смене языка
    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    const [events, setEvents] = useState([
        {
            title: "English 101",
            start: new Date(2025, 2, 4, 11, 0),
            end: new Date(2025, 2, 4, 12, 0),
            desc: "Class 300",
            color: "#E0D7FF", // Фиолетовый фон
        },
        {
            title: "English 101",
            start: new Date(2025, 2, 4, 11, 0),
            end: new Date(2025, 2, 4, 12, 0),
            desc: "Class 300",
            color: "#E0D7FF", // Фиолетовый фон
        },
    ]);

    const eventStyleGetter = (event) => {
        const style = {
            backgroundColor: event.color || "#3174ad",
            color: "#000",
            borderRadius: "5px",
            padding: "5px",
            marginBottom: "10px",
        };
        return { style };
    };

    return (
        <div style={{ width: "100%" }}>
            <h2>Ваш Расписание</h2>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ width: "100%", height: 700 }}
                eventPropGetter={eventStyleGetter}
                messages={messages}
                views={{ month: true }}
            />
        </div>
    );
};

export default CalendarComponent;
