import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import DocumentService from "../../../../../services/DocumentService";
import {API_BASE_URL} from "../../../../../constants/api";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import {Button, Flex, message, Modal, Table} from "antd";
import MyText from "../../../../../components/UI/Text/MyText";
import {Document, Page} from "react-pdf";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {useDispatch, useSelector} from "react-redux";
import classes from "../../DirectorApplications/application.module.css";
import GreenButton from "../../../../../components/UI/Buttons/GreenButton";
import UserSignService from "../../../../../services/UserSignService";
import {signContract, signUserSign} from "../../../../../utils/signContract";

const UserSignStudentList = () => {
    const {user} = useSelector(state => state.user);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openViewContractModal, setOpenViewContractModal] = useState(false);
    const [viewContract, setViewContract] = useState(null);

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingDocument, setLoadingDocument] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);

    const [usersSigns, setUsersSigns] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        handleFetchData();
        if (initialLoading) {
            setInitialLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPage === 1) {
            if (!initialLoading) {
                handleFetchData();
            }
        } else {
            setCurrentPage(1);
        }

    }, [pageSize]);


    async function handleFetchData() {
        setLoading(true);
        await UserSignService.getStudentUserSignsPageable(user.id, currentPage, pageSize)
            .then((r) => {
                setUsersSigns(r.data);
                setHasMore(r.hasMore);
                setInitialLoading(false);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages);
        setLoadingDocument(false);
    }

    function triggerDownload(blob, filename) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Set the download attribute with a filename
        document.body.appendChild(link);
        link.click();

        // Clean up and revoke the URL after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async function downloadDocument(documentId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await DocumentService.getResourceById(documentId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                triggerDownload(r.data, viewContract?.docDetails !== null ? viewContract?.docDetails + ".pdf" : "dogovor.pdf")
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    async function handleSign(id, whom) {
        message.loading(<IntlMessage id={'changing'}/>, 0);
        setInitialLoading(true);
        await signUserSign(whom, user?.profile?.iin, id)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                handleFetchData();
            })
            .catch((reason) => {
                message.destroy();
                if (reason instanceof Error) {
                    const errorMessage = reason.message;
                    if (errorMessage === "NCAOpenError") {
                        message.error(<IntlMessage id="NCAOpenError"/>, 5);
                    } else {
                        message.error(<IntlMessage id={'error'}/>, 5);
                    }
                } else {
                    message.error(<IntlMessage id={'error'}/>, 5);
                }
            })
            .finally(() => {
                setInitialLoading(false);
            });
    }

    const columns = [
        {
            title: <IntlMessage id={'name2'}/>,
            render: (_, record) => (
                <p>{record.docDetails}</p>
            ),
            width: '40%',
        },
        {
            title: <IntlMessage id={'date'}/>,
            render: (_, record) => (
                <p>{new Date(record.updatedAt).toLocaleDateString()}</p>
            ),
            width: '10%',
        },

        {
            title: <IntlMessage id={'actions'}/>,
            render: (_, record) => (
                <>
                    <Flex style={{width: "100%"}} align={"center"} justify={"center"} wrap={"wrap"} gap={20}>
                        <p onClick={() => {
                            setViewContract(record);
                            setOpenViewContractModal(true);
                        }} className={classes.copyLinkBtn}><IntlMessage id={'view'}/></p>
                        {!record.isStudentSigned &&
                            <p onClick={() => {
                                handleSign(record.id, "student")
                            }} className={classes.copyLinkBtn}><IntlMessage id={'sign'}/></p>
                        }
                        {!record.isParentSigned && !!record?.user?.profile?.parentIin &&
                            <p onClick={() => {
                                handleSign(record.id, "parent")
                            }} className={classes.copyLinkBtn}><IntlMessage id={'signParentContract'}/></p>
                        }
                    </Flex>

                </>
            ),
            width: '20%',
        },
    ];

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <BlockLoading isLoading={isLoading}/>
            <Table
                bordered
                columns={columns}
                dataSource={usersSigns}
                pagination={{
                    current: currentPage,
                    total: hasMore
                        ? currentPage * pageSize + 1
                        : currentPage * pageSize,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                    onChange: handlePaginationChange,
                    // onShowSizeChange: handlePaginationChange,
                }}
                rowKey={record => record.id}
                loading={isLoading}
                style={{
                    width: "100%",
                }}
                scroll={{x: 800}}
            />

            <Modal
                width={800}
                maskClosable={true}
                // title={<IntlMessage id={'contractsPage.details'}/>}
                open={openViewContractModal}
                okText={<IntlMessage id={'save'}/>}
                cancelText={<IntlMessage id={'close'}/>}
                style={{
                    top: 20,
                }}
                onOk={() => {
                    setOpenViewContractModal(false)
                }}
                onCancel={() => {
                    setOpenViewContractModal(false)
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setOpenViewContractModal(false)
                    }}>
                        OK
                    </Button>
                ]}
            >
                {
                    viewContract &&
                    <Flex gap={50}
                          style={{
                              width: "100%",
                              minHeight: "calc(100vh - 300px)",
                              position: "relative",
                              padding: "0px 20px 20px 0px",
                          }}
                          vertical
                          align={"flex-start"}
                          justify={"flex-start"}>
                        <Flex align={"center"} justify={"center"} vertical
                              style={{overflowX: "auto", overflowY: "hidden", width: "100%"}}>
                            <Flex style={{
                                zIndex: 1,
                                padding: "10px 20px",
                                backgroundColor: "white",
                                borderRadius: 10,
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                            }} gap={15} justify={"space-between"} align={"center"}>
                                <Button disabled={pageNumber === 1} onClick={() => {
                                    setPageNumber(pageNumber - 1)
                                }}>
                                    {'<'}
                                </Button>
                                <MyText size={"small"}>
                                    {pageNumber} / {numPages}
                                </MyText>
                                <Button disabled={pageNumber === numPages} onClick={() => {
                                    setPageNumber(pageNumber + 1)
                                }}>
                                    {'>'}
                                </Button>
                            </Flex>
                            <Document file={API_BASE_URL + "/api/document/resource/" + viewContract?.documentId}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      loading={<IntlMessage id={'documentIsLoading'}/>}
                            >
                                <Page pageNumber={pageNumber} width={700}/>
                            </Document>
                        </Flex>
                        <Flex align={"flex-start"} justify={"center"} gap={20} style={{width: "100%"}}>
                            <GreyButton onClick={() => downloadDocument(viewContract?.documentId)}>
                                <IntlMessage id={'downloadContract'}/>
                            </GreyButton>
                        </Flex>
                    </Flex>
                }
            </Modal>
        </Flex>
    );
};

export default UserSignStudentList;