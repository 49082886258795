import React from 'react';
import {useDispatch} from "react-redux";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import PersonalData from "./PersonalData/PersonalData";
import MyText from "../../../../components/UI/Text/MyText";
import {Flex, Tabs} from "antd";

const SettingsPage = () => {

    const dispatch = useDispatch();
    const onChangeTabs = async (key) => {
        if (key === '1') {

        }
    };
    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'personalData'}/>,
            children: <PersonalData/>,
        }
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'settings'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default SettingsPage;