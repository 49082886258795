import React, {useEffect} from 'react';
import {Flex, Tabs} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import JournalPage from "./Journal/JournalPage";
import TasksListPage from "./Tasks/TasksListPage";
import TestsListPage from "./Tests/TestsListPage";
import {LocalName} from "../../../../utils/LocalName";
import {getGroup, getJournal} from "../../../../store/slices/TeacherGroupSlice";
import {LeftOutlined} from "@ant-design/icons";

const TeacherGroupPage = () => {
    const {id} = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {group, isLoading} = useSelector(state => state.teacherGroup);

    // useEffect(() => {
    //     setLoading(true);
    //     handleFetchData();
    // }, []);

    useEffect(() => {
        dispatch(getGroup({id}));
        dispatch(getJournal({id}));
    }, []);

    const onChangeTabs = async (key) => {
        if (key === '1') {

        }
    };

    const tabItems = [
        {
            key: '3',
            label: <IntlMessage id={'journal'}/>,
            children: <JournalPage/>,
        },
        {
            key: '4',
            label: <IntlMessage id={'tasks'}/>,
            children: <TasksListPage/>,
        },
        {
            key: '5',
            label: <IntlMessage id={'tests'}/>,
            children: <TestsListPage/>,
        },
    ]

    // async function handleFetchData() {
    //     setLoading(true);
    //     await GroupService.getGroup(id)
    //         .then((r) => {
    //             setGroup(r.data);
    //         })
    //         .catch(() => {
    //             message.destroy();
    //             message.error(<IntlMessage id={'error'}/>, 5);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         })
    // }


    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <Flex gap={"middle"}>
                <LeftOutlined style={{cursor: "pointer"}} onClick={() => navigate("/p/groups")}/>
                <MyText uppercase strong size={"large"}>{LocalName.getName(group) + " " + group?.number}</MyText>
            </Flex>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default TeacherGroupPage;