import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Flex, message, Modal, Table} from "antd";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import GroupService from "../../../../../services/GroupService";
import {LocalName} from "../../../../../utils/LocalName";
import {EyeOutlined} from "@ant-design/icons";
import MyText from "../../../../../components/UI/Text/MyText";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {clrs} from "../../../../../constants/colors";

const GroupsList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);

    const [groups, setGroups] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const [openViewStudentsModal, setOpenViewStudentsModal] = useState(false);
    const [viewStudents, setViewStudents] = useState(null);

    const [openViewTeacherModal, setOpenViewTeacherModal] = useState(false);
    const [viewTeacher, setViewTeacher] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchData();
        if (initialLoading) {
            setInitialLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPage === 1) {
            if (!initialLoading) {
                handleFetchData();
            }
        } else {
            setCurrentPage(1);
        }

    }, [pageSize]);

    async function handleFetchData() {
        setLoading(true);
        await GroupService.getGroups(currentPage, pageSize)
            .then((r) => {
                setGroups(r.data);
                setHasMore(r.hasMore);
                setInitialLoading(false);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    const columns = [
        {
            title: <IntlMessage id={'name2'}/>,
            render: (_, record) => (
                <p>{LocalName.getName(record)}</p>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'number'}/>,
            render: (_, record) => (
                <p>{record.number}</p>
            ),
            width: '5%',
        },
        {
            title: <IntlMessage id={'ordersPage.teacher'}/>,
            render: (_, record) => (
                <Flex justify={"space-between"} align={"center"}>
                    <p>{record.teacher ? (record.teacher.profile.firstName + " " + record.teacher.profile.lastName) : record.teacher.username}</p>
                    <Button onClick={() => {
                        setOpenViewTeacherModal(true);
                        setViewTeacher(record.teacher);
                    }}>
                        <EyeOutlined/>
                    </Button>
                </Flex>
            ),
            width: '15%',
        },
        {
            title: <IntlMessage id={'ordersPage.students'}/>,
            render: (_, record) => (
                <Flex justify={"space-between"} align={"center"}>
                    <p>{record.students.length}</p>
                    {record.students.length > 0 &&
                        <Button onClick={() => {
                            setOpenViewStudentsModal(true);
                            setViewStudents(record.students);
                        }}>
                            <EyeOutlined/>
                        </Button>
                    }
                </Flex>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'ordersPage.academicYear'}/>,
            render: (_, record) => (
                <p>{record.academicYear}</p>
            ),
            width: '10%',
        },
        {
            title: <IntlMessage id={'category'}/>,
            render: (_, record) => (
                <p>{LocalName.getName(record.category)}</p>
            ),
            width: '15%',
        },
    ];

    const studentsColumns = [
        {
            title: <IntlMessage id={'fullName'}/>,
            render: (_, record) => (
                <p>{record.profile.firstName + " " + record.profile.lastName + (record.profile?.middleName ? (" " + record.profile?.middleName) : "")}</p>
            ),
            width: '50%'
        },
        {
            title: <IntlMessage id={'email'}/>,
            render: (_, record) => (
                <p>{record.profile.email}</p>
            ),
            width: '50%'
        },
    ];

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <GreyButton onClick={() => {
                handleFetchData();
            }} backgroundColor={clrs.whiter} fontColor={clrs.black}><IntlMessage id={'update'}/></GreyButton>
            <Table
                bordered
                columns={columns}
                dataSource={groups}
                pagination={{
                    current: currentPage,
                    total: hasMore
                        ? currentPage * pageSize + 1
                        : currentPage * pageSize,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                    onChange: handlePaginationChange,
                    // onShowSizeChange: handlePaginationChange,
                }}
                rowKey={record => record.id}
                loading={isLoading}
                style={{
                    width: "100%",
                }}
                scroll={{x: 800}}
            />
            <Modal
                width={800}
                maskClosable={true}
                title={<IntlMessage id={'ordersPage.students'}/>}
                open={openViewStudentsModal}
                okText={<IntlMessage id={'save'}/>}
                cancelText={<IntlMessage id={'close'}/>}
                style={{
                    top: 20,
                }}
                onOk={() => {
                    setOpenViewStudentsModal(false)
                }}
                onCancel={() => {
                    setOpenViewStudentsModal(false)
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setOpenViewStudentsModal(false)
                    }}>
                        OK
                    </Button>
                ]}
            >
                {
                    !!viewStudents &&
                    <Table
                        dataSource={viewStudents}
                        columns={studentsColumns}
                        pagination={{
                            defaultPageSize: 5,
                        }}
                    />
                }
            </Modal>
            <Modal
                width={800}
                maskClosable={true}
                title={<IntlMessage id={'ordersPage.teacher'}/>}
                open={openViewTeacherModal}
                okText={<IntlMessage id={'save'}/>}
                cancelText={<IntlMessage id={'close'}/>}
                style={{
                    top: 20,
                }}
                onOk={() => {
                    setOpenViewTeacherModal(false)
                }}
                onCancel={() => {
                    setOpenViewTeacherModal(false)
                }}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        setOpenViewTeacherModal(false)
                    }}>
                        OK
                    </Button>
                ]}
            >
                {
                    openViewTeacherModal &&
                    <Flex style={{width: "100%", position: "relative"}} vertical gap={10}>
                        <Flex gap={5} wrap={"wrap"}>
                            <MyText strong size={"small"}>
                                <IntlMessage id={'firstName'}/>
                            </MyText>
                            <span>:</span>
                            <MyText size={"small"}>
                                {
                                    viewTeacher?.profile.firstName
                                }
                            </MyText>
                        </Flex>
                        {viewTeacher?.profile.middleName &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'middleName'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher?.profile.middleName
                                    }
                                </MyText>
                            </Flex>
                        }
                        <Flex gap={5} wrap={"wrap"}>
                            <MyText strong size={"small"}>
                                <IntlMessage id={'lastName'}/>
                            </MyText>
                            <span>:</span>
                            <MyText size={"small"}>
                                {
                                    viewTeacher.profile.lastName
                                }
                            </MyText>
                        </Flex>
                        {viewTeacher?.profile.birthDay &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'birthDay'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.birthDay
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.address &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'address'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.address
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.telNumber &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'telNumber'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.telNumber
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.email &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'email'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.email
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.iin &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'iin'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.iin
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.idCardNumber &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'idCardNumber'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.idCardNumber
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.idCardFromWhom &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'idCardFromWhom'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.idCardFromWhom
                                    }
                                </MyText>
                            </Flex>
                        }
                        {viewTeacher.profile.idCardDate &&
                            <Flex gap={5} wrap={"wrap"}>
                                <MyText strong size={"small"}>
                                    <IntlMessage id={'idCardDate'}/>
                                </MyText>
                                <span>:</span>
                                <MyText size={"small"}>
                                    {
                                        viewTeacher.profile.idCardDate
                                    }
                                </MyText>
                            </Flex>
                        }
                    </Flex>
                }
            </Modal>
        </Flex>
    );
};

export default GroupsList;