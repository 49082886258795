export const clrs = {
    white: "#ffffff",
    whiter: "#F5F5F5",
    whiter2: "#e8e8e8",
    black: "#000000",
    grey: "#6F8695",
    blackerBlue: "#163269",
    whiterGreen: "#60AB9A",
    whiterRed: "#FF6B6B",
    blacker: "#292929",
    whiterBlacker: "#8c8b8b",
    blue: "#10A8CE",
    green: "#87d068",
    darkerBlue :"#004E64",
    blueForIcon : "#1677ff",

    yellow: "#ffdd57"

}