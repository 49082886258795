import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const JournalService = {};

JournalService.getJournal = async function (groupId) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/journal/get-group`,
        params: {
            id: groupId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

JournalService.getTopics = async function (id) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/group/get-topics`,
        params: {
            id: id
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

JournalService.saveLesson = async (topic, date) => {
    let data = new FormData();
    data.append("topic", topic);
    data.append("date", date);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/journal/lesson/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

JournalService.updateLesson = async (id, date) => {
    let data = new FormData();
    data.append("id", id);
    if (date === undefined) {
        data.append("isDone", true);
    } else {
        data.append("date", date);
    }

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/journal/lesson/update",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

JournalService.saveAttendances = async (attendances) => {
    return axios({
        method: "post",
        url: API_BASE_URL + "/api/journal/attendance/save",
        data: attendances,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default JournalService;