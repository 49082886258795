import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";


const svg = () => {
    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="m15 19h2v2h-2z"/>
            <path d="m15 23h2v2h-2z"/>
            <path
                d="m23 11.67v-7.67h3v-2h-20v2h3v7.67a2 2 0 0 0 .4 1.2l2.35 3.13-2.35 3.13a2 2 0 0 0 -.4 1.2v7.67h-3v2h20v-2h-3v-7.67a2 2 0 0 0 -.4-1.2l-2.35-3.13 2.35-3.13a2 2 0 0 0 .4-1.2zm-2-7.67v7h-10v-7zm0 16.33v7.67h-10v-7.67l3.25-4.33-2.25-3h8l-2.25 3z"/>
            <path d="m0 0h32v32h-32z" fill="none"/>
        </svg>
    );
};

const DurationIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default DurationIcon;