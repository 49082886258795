import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const UserService = {};
UserService.getUserByToken = async () => {
    let data = new FormData();
    const token = localStorage.getItem(ACCESS_TOKEN);
    data.append("token", token);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/user/get/token",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.getUserByUsername = async function (username) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/user/get/${username}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.getUsers = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/user/get?page=${page - 1}&limit=${limit}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return {data, hasMore};
}

UserService.getTeachers = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/user/getTeachers?page=${page - 1}&limit=${limit}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return {data, hasMore};
}

UserService.getStudents = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/user/getStudents?page=${page - 1}&limit=${limit}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return {data, hasMore};
}
UserService.getStudentsSearchable = async function (page, limit, name) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/user/getStudents?page=${page - 1}&limit=${limit}&name=${name}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return {data, hasMore};
}

UserService.saveUser = async (firstName, lastName, email, password, role) => {
    let data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("username", email);
    data.append("password", password);
    data.append("role", role);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/user/saveUser",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.saveUsersByContract = async (ids) => {
    let data = new FormData();
    data.append("ids", ids);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/user/saveUsers/contracts",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

UserService.editUser = async (id, firstName, lastName, middleName, firstNameInEnglish, lastNameInEnglish, birthDay, address, iin, telNumber, idCardNumber, idCardDate, idCardFromWhom, fullNameParent, telNumberParent, iin_parent, idCardNumber_parent, idCardFromWhom_parent, idCardDate_parent) => {
    let data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);

    if (middleName) {
        data.append("middleName", middleName);
    }

    if (firstNameInEnglish) {
        data.append("firstNameInEnglish", firstNameInEnglish);
    }

    if (lastNameInEnglish) {
        data.append("lastNameInEnglish", lastNameInEnglish);
    }

    if (birthDay) {
        data.append("birthDate", birthDay);
    }

    if (address) {
        data.append("address", address);
    }

    if (iin) {
        data.append("iin", iin);
    }

    if (telNumber) {
        data.append("telNumber", telNumber);
    }

    if (idCardNumber) {
        data.append("idCardNumber", idCardNumber);
    }

    if (idCardDate) {
        data.append("idCardDate", idCardDate);
    }

    if (idCardFromWhom) {
        data.append("idCardFromWhom", idCardFromWhom);
    }

    if (fullNameParent) {
        data.append("fullNameParent", fullNameParent);
    }

    if (telNumberParent) {
        data.append("telNumberParent", telNumberParent);
    }

    if (iin_parent) {
        data.append("iin_parent", iin_parent);
    }

    if (idCardNumber_parent) {
        data.append("idCardNumber_parent", idCardNumber_parent);
    }

    if (idCardFromWhom_parent) {
        data.append("idCardFromWhom_parent", idCardFromWhom_parent);
    }

    if (idCardDate_parent) {
        data.append("idCardDate_parent", idCardDate_parent);
    }


    return axios({
        method: "patch",
        url: API_BASE_URL + `/api/user/${id}/edit`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default UserService;