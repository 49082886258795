import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Checkbox, Flex, Form, Input, message, Table} from 'antd';
import {createStyles} from 'antd-style';
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import MyText from "../../../../../components/UI/Text/MyText";
import classes from "../../Applications/application.module.css";
import JournalService from "../../../../../services/JournalService";
import {getJournal} from "../../../../../store/slices/TeacherGroupSlice";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";

const useStyle = createStyles(({css, token}) => {
    const {antCls} = token;
    return {
        customTable: css`
            ${antCls}-table {
                ${antCls}-table-container {
                    ${antCls}-table-body,
                    ${antCls}-table-content {
                        scrollbar-width: thin;
                        scrollbar-color: #eaeaea transparent;
                        scrollbar-gutter: stable;
                    }
                }
            }
        `,
    };
});
const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'date' ? <Input type={'date'}/> : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{margin: 0}}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const JournalPage = () => {
    const {id} = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {journal, isLoading} = useSelector(state => state.teacherGroup);

    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);
    const date = Form.useWatch('date', form);
    const moduleName = Form.useWatch('moduleName', form);

    const [formAttendance] = Form.useForm();
    const attendanceValues = Form.useWatch([], formAttendance); // следим за всей формой


    const [editingKey, setEditingKey] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedAttendances, setSelectedAttendances] = useState([]);
    const isEditing = record => record.key === editingKey;
    const getAttendanceFieldName = id => `attendance-${id}`;

    function formatDateDDMMYYYY(dateInput) {
        const date = new Date(dateInput);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы с 0
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    }

    const edit = record => {

        const initValues = {
            ...record,
            date: record.date ? formatDateDDMMYYYY(record.date) : null,
        };

        form.setFieldsValue(initValues);
        setEditingKey(record.key);
    };
    const cancel = () => {
        setEditingKey('');
    };
    const save = async key => {
        form.validateFields().then(async () => {
            await JournalService.updateLesson(key, date)
                .then(() => {
                    message.success(<IntlMessage id={'success'}/>, 5);
                    form.resetFields();
                })
                .catch(() => {
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
                .finally(() => {
                    dispatch(getJournal({id}));
                    setEditingKey('');
                })
        })
            .catch(() => {
                console.log("Validation error!")
            })
    };

    const updateLesson = async key => {
        await JournalService.updateLesson(key)
            .then(() => {
                message.success(<IntlMessage id={'success'}/>, 5);
                form.resetFields();
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                dispatch(getJournal({id}));
                setEditingKey('');
            })
    }


    const {styles} = useStyle();
    const baseColumn = {
        title: <MyText center><IntlMessage id={"fullName"}/></MyText>,
        width: 300,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    };
    const lessonColumns = journal?.lessons
        ?.filter(lesson => lesson.date !== null) // ✅ отсекаем null-даты
        .map(lesson => ({
            title: (
                <Flex style={{cursor: "pointer"}} align={"center"} justify={"center"} gap={"small"} onClick={() => {
                    const currentValues = formAttendance.getFieldsValue();
                    const allIds = lesson.attendances.map(att => getAttendanceFieldName(att.id));
                    const allChecked = allIds.every(id => currentValues[id]);
                    lesson.attendances.forEach(att => {
                        formAttendance.setFieldValue(getAttendanceFieldName(att.id), !allChecked);
                    });
                }}>
                    <MyText center>
                        {new Date(lesson.date).toLocaleDateString("ru-RU")}
                    </MyText>
                </Flex>
            ),
            dataIndex: lesson.number,
            key: lesson.number
        })) || [];

    // const lessonColumns = journal?.lessons?.map((lesson) => ({
    //     title: (
    //         <MyText center>
    //             {lesson.date !== null
    //                 ? new Date(lesson.date).toLocaleDateString("ru-RU")
    //                 : ""}
    //         </MyText>
    //     ),
    //     dataIndex: lesson.number,
    //     key: lesson.number,
    //     render: (value, row) => (
    //         <Checkbox
    //             checked={value}
    //             onChange={(e) => console.log(e.target.checked, row, lesson)}
    //         />
    //     ),
    // })) || [];

    const columns1 = [baseColumn, ...lessonColumns];
    const columns2 = [
        {
            title: <MyText center><IntlMessage id={"date"}/></MyText>,
            width: '20%',
            dataIndex: 'date',
            key: 'date',
            editable: true,
            render: (_, record) => (
                <p>
                    {record.date ? new Date(record.date).toLocaleDateString('kk-KZ') : ""}
                </p>
            ),
        },
        {
            title: <MyText center><IntlMessage id={"name2"}/></MyText>,
            width: '30%',
            dataIndex: 'name',
            key: 'name',
            editable: false
        },
        {
            title: <MyText center><IntlMessage id={"name2"}/></MyText>,
            width: '30%',
            dataIndex: 'moduleName',
            key: 'moduleName',
            editable: false
        },
        {
            title: <MyText center><IntlMessage id={"actions"}/></MyText>,
            key: 'action',
            fixed: 'right',
            width: '20%',
            render: (_, record) => {
                const editable = isEditing(record);
                return record.isDone ? <IntlMessage id={'finished'}/>
                    : editable ? (
                        <span>
                        <p onClick={() => save(record.key)} className={classes.copyLinkBtn}>
                        <IntlMessage id={'save'}/>
                        </p>
                        <p onClick={() => cancel()} className={classes.rejectBtn}>
                        <IntlMessage id={'cancel'}/>
                        </p>
                    </span>
                    ) : (
                        <span>
                         <p onClick={() => edit(record)} className={classes.copyLinkBtn}>
                        <IntlMessage id={'edit'}/></p>
                            {
                                record.date !== null &&
                                <p onClick={() => updateLesson(record.key)} className={classes.copyLinkBtn}>
                                    <IntlMessage id={'check'}/></p>
                            }
                    </span>
                    );

            },
        },
    ];
    const mergedColumns = columns2.map(col => {
        if (!col.editable) return col;

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'date' ? 'date' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const dataSource1 = journal?.group?.students?.map((student, index) => {
        const row = {
            key: String(index + 1),
            name: `${student?.profile?.fullName}`, // если имя разбито
        };

        journal.lessons?.forEach((lesson) => {
            const attendance = lesson.attendances.find(a => a.user.id === student.id);
            if (lesson?.date !== null && !lesson?.isDone) {
                row[lesson.number] =
                    <FormItem valuePropName={"checked"} name={getAttendanceFieldName(attendance.id)}
                              initialValue={attendance.isPresent}>
                        <Checkbox/>
                    </FormItem>
            } else {
                row[lesson.number] = attendance ? (attendance.isPresent ? '✅' : '❌') : '-';
            }
        });

        return row;
    }) || [];

    const dataSource2 = journal?.lessons?.map((lesson, index) => {
        const row = {
            key: lesson.id,
            date: lesson?.date,
            // : <MyText center>{new Date(lesson.date).toLocaleDateString()}</MyText>,
            name: `${lesson?.journalTopic?.name}`,
            moduleName: `${lesson?.journalTopic?.moduleName}`,
            isDone: lesson.isDone
        };
        return row;
    }) || [];


    /* const tableRef1 = useRef();
    // const tableRef2 = useRef();
    //
    // const enableHorizontalDragScroll = (container: HTMLDivElement | null) => {
    //     if (!container) return;
    //
    //     const scrollEl = container.querySelector('.ant-table-content');
    //     if (!scrollEl) return;
    //
    //     let isDown = false;
    //     let startX = 0;
    //     let scrollLeft = 0;
    //
    //     const onMouseDown = (e: MouseEvent) => {
    //         isDown = true;
    //         startX = e.pageX - scrollEl.offsetLeft;
    //         scrollLeft = scrollEl.scrollLeft;
    //         scrollEl.style.cursor = 'grabbing';
    //         scrollEl.style.userSelect = 'none';
    //     };
    //
    //     const endDrag = () => {
    //         isDown = false;
    //         scrollEl.style.cursor = '';
    //         scrollEl.style.userSelect = '';
    //     };
    //
    //     const onMouseMove = (e: MouseEvent) => {
    //         if (!isDown) return;
    //         e.preventDefault();
    //         const x = e.pageX - scrollEl.offsetLeft;
    //         const walk = x - startX;
    //         scrollEl.scrollLeft = scrollLeft - walk;
    //     };
    //
    //     scrollEl.addEventListener('mousedown', onMouseDown);
    //     scrollEl.addEventListener('mouseleave', endDrag);
    //     scrollEl.addEventListener('mouseup', endDrag);
    //     scrollEl.addEventListener('mousemove', onMouseMove);
    //
    //     // очистка
    //     return () => {
    //         scrollEl.removeEventListener('mousedown', onMouseDown);
    //         scrollEl.removeEventListener('mouseleave', endDrag);
    //         scrollEl.removeEventListener('mouseup', endDrag);
    //         scrollEl.removeEventListener('mousemove', onMouseMove);
    //     };
    // };
    //
    // useEffect(() => {
    //     const cleanups: (() => void)[] = [];
    //     [tableRef1.current, tableRef2.current].forEach((ref) => {
    //         const cleanup = enableHorizontalDragScroll(ref);
    //         if (cleanup) cleanups.push(cleanup);
    //     });
    //
    //     return () => {
    //         cleanups.forEach((fn) => fn());
    //     };
    // }, []);

     */

    const tableRef = useRef();

    useEffect(() => {
        const tableContainer = tableRef.current?.querySelector('.ant-table-content');
        if (!tableContainer) return;

        let isDown = false;
        let startX = 0;
        let scrollLeft = 0;

        const onMouseDown = (e: MouseEvent) => {
            isDown = true;
            startX = e.pageX - tableContainer.offsetLeft;
            scrollLeft = tableContainer.scrollLeft;
            tableContainer.style.cursor = 'grabbing';
            tableContainer.style.userSelect = 'none';
        };

        const onMouseLeave = () => {
            isDown = false;
            tableContainer.style.cursor = '';
            tableContainer.style.userSelect = '';
        };

        const onMouseUp = () => {
            isDown = false;
            tableContainer.style.cursor = '';
            tableContainer.style.userSelect = '';
        };

        const onMouseMove = (e: MouseEvent) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - tableContainer.offsetLeft;
            const walk = (x - startX) * 1; // скорость прокрутки
            tableContainer.scrollLeft = scrollLeft - walk;
        };

        tableContainer.addEventListener('mousedown', onMouseDown);
        tableContainer.addEventListener('mouseleave', onMouseLeave);
        tableContainer.addEventListener('mouseup', onMouseUp);
        tableContainer.addEventListener('mousemove', onMouseMove);

        return () => {
            tableContainer.removeEventListener('mousedown', onMouseDown);
            tableContainer.removeEventListener('mouseleave', onMouseLeave);
            tableContainer.removeEventListener('mouseup', onMouseUp);
            tableContainer.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    const initialAttendanceValues = {};

    // useEffect(() => {
    //     journal?.lessons?.forEach(lesson => {
    //         lesson.attendances.forEach(att => {
    //             initialAttendanceValues[`attendance-${att.id}`] = att.isPresent;
    //         });
    //     });
    //     console.log(initialAttendanceValues)
    // }, [journal])

    useEffect(() => {
        if (!journal) return;

        const initialValues = {};
        journal.lessons?.forEach(lesson => {
            lesson.attendances.forEach(att => {
                initialValues[`attendance-${att.id}`] = att.isPresent;
            });
        });

        formAttendance.setFieldsValue(initialValues); // обновляем форму вручную
    }, [journal]);


    async function saveAttendances() {
        const values = formAttendance.getFieldsValue(); // { attendance-id: true/false }


        // const selectedIds = Object.entries(values)
        //     .filter(([key, value]) => key.startsWith('attendance-') && value)
        //     .map(([key]) => key.replace('attendance-', ''));
        const attendanceMap = Object.entries(values)
            .filter(([key, value]) => key.startsWith('attendance-') && value !== null && value !== undefined)
            .reduce((acc, [key, value]) => {
                const id = key.replace('attendance-', '');
                acc[id] = value;
                return acc;
            }, {});

        await JournalService.saveAttendances(attendanceMap)
            .then(() => {
                message.success(<IntlMessage id="success"/>, 5);
            })
            .catch(() => {
                message.error(<IntlMessage id="error"/>, 5);
            })
            .finally(() => {
                formAttendance.resetFields(); // сбросить форму (чекбоксы)
                dispatch(getJournal({id}));
            });
    }

    const onSelectAttendanceChange = id => {
        const exists = selectedAttendances.some(a => a === id);
        if (exists) {
            setSelectedAttendances(selectedAttendances.filter(a => a !== id));
        } else {
            setSelectedAttendances([...selectedAttendances, id]);
        }
    };
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // };
    // const hasSelected = selectedRowKeys.length > 0;

    return (
        <div style={{width: '100%', display: "flex", gap: 20}}>
            <BlockLoading isLoading={isLoading}/>
            <Form form={formAttendance} initialValues={initialAttendanceValues}
                  style={{display: "flex", flex: 1, width: '50%', flexDirection: "column", gap: 20}}>
                <div ref={tableRef}>
                    <Table
                        bordered
                        className={styles.customTable}
                        pagination={false}
                        columns={columns1}
                        dataSource={dataSource1}
                        scroll={{x: true}}
                        size="small"
                    />
                </div>
                <Button type="primary" onClick={saveAttendances}
                    // disabled={!Object.values(formAttendance.getFieldsValue()).every(val => !val)}
                    //     disabled={!Object.values(attendanceValues || {}).some(val => val)}
                >
                    {/*<Button type="primary" onClick={saveAttendances} disabled={selectedAttendances.length === 0}>*/}
                    <IntlMessage id={'save'}/>
                </Button>
            </Form>
            <div style={{flex: 1, width: '50%'}}>
                <Form form={form} component={false}>
                    <Table
                        bordered
                        components={{
                            body: {cell: EditableCell},
                        }}
                        className={styles.customTable}
                        pagination={false}
                        columns={mergedColumns}
                        dataSource={dataSource2}
                        scroll={{x: true}}
                        size="small"
                    />
                </Form>
            </div>
        </div>
    );
};

export default JournalPage;