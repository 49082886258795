import React from 'react';
import {getAllCategories} from "../../../../store/slices/CategoriesListSlice";
import {useDispatch} from "react-redux";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import CoursesList from "../Courses/List/CoursesList";
import CreateCategory from "../Courses/CreateCategory/CreateCategory";
import CreateCourse from "../Courses/CreateCourse/CreateCourse";
import {Flex, Tabs} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import CreateUsers from "./Creation/CreateUsers";
import TeachersList from "./List/TeachersList";
import StudentsList from "./List/StudentsList";

const UsersPage = () => {
    const dispatch = useDispatch();
    const onChangeTabs = async (key) => {
        if (key === '1') {

        }
        if (key === '2') {

        }
    };
    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'teachersList'}/>,
            children: <TeachersList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'studentsList'}/>,
            children: <StudentsList/>,
        },
        {
            key: '3',
            label: <IntlMessage id={'create'}/>,
            children: <CreateUsers/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'users'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default UsersPage;