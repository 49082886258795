import React from 'react';

import {Flex} from "antd";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import MyText from "../../../../components/UI/Text/MyText";
import DirectorApplicationList from "./List/DirectorApplicationList";
import {useDispatch, useSelector} from "react-redux";
import RectorApplicationList from "./List/RectorApplicationList";
import {getUserByToken} from "../../../../store/slices/userSlice";

const DirectorApplicationsPage = () => {
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.user)
    if (user === null) {
        dispatch(getUserByToken());
    }
    return (
        <Flex style={{width: "100%"}} gap={20} align={"flex-start"} justify={"flex-start"} vertical>
            <MyText size={"large"} uppercase strong><IntlMessage id={'contracts'}/></MyText>
            {
                user?.role?.roleName === 'director' ? <DirectorApplicationList/> : <RectorApplicationList/>
            }
        </Flex>
    );
};

export default DirectorApplicationsPage;