import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Card, Flex, Form, Input, message, Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import TeacherService from "../../../../../services/TeacherService";
import classes from "../../Courses/CreateCourse/CreateCourse.module.css";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {clrs} from "../../../../../constants/colors";
import UserService from "../../../../../services/UserService";
import debounce from 'lodash/debounce';
import CategoryService from "../../../../../services/CategoryService";
import {LocalName} from "../../../../../utils/LocalName";
import ContractService from "../../../../../services/ContractService";
import MyText from "../../../../../components/UI/Text/MyText";
import cl from "../../Orders/Creation/ordercreate.module.css";
import {useTranslation} from "react-i18next";
import UserSignService from "../../../../../services/UserSignService";

const SendUserSign = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const category = Form.useWatch('category', form);
    const type = Form.useWatch('type', form);
    const student = Form.useWatch('student', form);
    const contract = Form.useWatch('contract', form);
    const orderNumber = Form.useWatch('orderNumber', form);
    const applicationDate = Form.useWatch('applicationDate', form);

    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [students, setStudents] = useState([]);
    const [studentsList, setStudentsList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [contractsList, setContractsList] = useState([]);

    const [courses, setCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);

    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setCategoriesList(r.data);
            })
            .catch(() => {

            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
    }, [categoriesList]);

    useEffect(() => {
        UserService.getStudentsSearchable(1, 100, "")
            .then((r) => {
                setStudentsList(r.data);
            })
            .catch(() => {

            })
    }, []);

    useEffect(() => {
        setStudents(
            studentsList.map(student => {
                return { value: student.username, label: student.profile.fullName }
            })
        )
    }, [studentsList]);

    useEffect(() => {
        ContractService.getAllContractsPageable(1, 100, null, null, student)
            .then((r) => {
                setContractsList(r.data);
            })
    }, [student]);

    useEffect(() => {
        setContracts(
            contractsList.map(contract => {
                return { value: contract.id, label: contract.realNumber + " | " + contract.fullName }
            })
        )
    }, [contractsList]);

    useEffect(() => {
        if (category) {
            setCourses(categoriesList.find(c => c.id === category).courses);
            setSelectedCourses([]);
        }
    }, [category]);

    function handleSend() {
        form.validateFields()
            .then(async () => {
                if (selectedCourses.length === 0) {
                    message.warning(<IntlMessage id={'userSignPage.noCoursesError'}/>, 5);
                    return;
                }
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setIsLoading(true);

                const body = {
                    username: student,
                    type: type,
                    contractId: contract,
                    applicationDate: applicationDate,
                    orderNumber: orderNumber,
                    courseListItems: []
                }

                for (const course of selectedCourses) {
                    body.courseListItems.push({courseId: course.id, hours: form.getFieldValue(course.id + ".hoursNumber")})
                }

                await UserSignService.sendUserSign(body)
                    .then(() => {
                        setIsLoading(false);
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        setIsLoading(false);
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })

            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={isLoading}/>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0, marginBottom: 0}} name="type"
                      label={<IntlMessage id={'userSignPage.type'}/>} labelCol={{ span: 24 }}
            >
                <Select
                    options={[
                        { value: 'CANCELLATION', label: <IntlMessage id={"userSignPage.CANCELLATION"}/> },
                        { value: 'ADDITIONAL_AGREEMENT', label: <IntlMessage id={"userSignPage.ADDITIONAL_AGREEMENT"}/> }
                    ]}
                    placeholder={IntlMessageText.getText({id: "userSignPage.type"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0, marginBottom: 0}} name="student"
                      label={<IntlMessage id={'userSignPage.whoToSend'}/>} labelCol={{ span: 24 }}
            >
                <Select
                    options={students}
                    placeholder={IntlMessageText.getText({id: "userSignPage.whoToSend"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0, marginBottom: 0}} name="contract"
                      label={<IntlMessage id={'userSignPage.contract'}/>} labelCol={{ span: 24 }}
            >
                <Select
                    options={contracts}
                    placeholder={IntlMessageText.getText({id: "userSignPage.contract"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0, marginBottom: 0}} name="orderNumber"
                      label={<IntlMessage id={'userSignPage.orderNumber'}/>} labelCol={{ span: 24 }}
            >
                <Input placeholder={IntlMessageText.getText({id: 'userSignPage.orderNumber'})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"applicationDate"}
                      label={<IntlMessage id={'userSignPage.applicationDate'}/>}
                      labelCol={{ span: 24 }}
            >
                <Input className={classes.formInput} style={{maxWidth: 200}} type={"date"}
                       placeholder={IntlMessageText.getText({id: "userSignPage.applicationDate"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name="category"
                      label={<IntlMessage id={'categoryCourse'}/>} labelCol={{span: 24}}
            >
                <Select
                    options={categories}
                    placeholder={IntlMessageText.getText({id: "categoryCourse"})}/>
            </FormItem>

            <MyText size={"small"}>
                <IntlMessage id={'userSignPage.courses'}/>
            </MyText>
            <Flex style={{width: "100%"}} wrap={"wrap"} align={"flex-start"} justify={"flex-start"} gap={20}>
                {
                    courses.map(course => {
                        return (
                            <Card
                                title={
                                    <Flex onClick={() => {
                                        form.setFieldValue(course.id + ".hoursNumber", undefined);
                                        if (selectedCourses.includes(course)) {
                                            setSelectedCourses(selectedCourses.filter(c => c !== course));
                                        } else {
                                            setSelectedCourses([...selectedCourses, course]);
                                        }
                                        // const newValue = isSaturday === "true" ? "false" : "true";
                                        // form.setFieldValue("group." + group.id + ".isSaturday", newValue);
                                    }} style={{
                                        width: "100%", height: "100%",
                                        backgroundColor: selectedCourses.includes(course) ? clrs.blueForIcon : clrs.white,
                                        color: selectedCourses.includes(course) ? clrs.white : clrs.black
                                    }} align={"center"} justify={"center"} >
                                        <MyText size={"small"} style={{textAlign: "center"}}>
                                            {LocalName.getName(course)}
                                        </MyText>
                                    </Flex>
                                }
                                variant={"borderless"}
                                style={{
                                    width: "100%",
                                    maxWidth: 300
                                }}
                                className={cl.myCardBlock}
                            >
                                {
                                    selectedCourses.includes(course) && type !== 'ADDITIONAL_AGREEMENT' &&
                                    <FormItem
                                        rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                                        className={classes.formInputBlock} name={course.id + ".hoursNumber"}>
                                        <Select
                                            options={[
                                                { value: '5', label: '5' },
                                                { value: '10', label: '10' },
                                                { value: '15', label: '15' },
                                                { value: '20', label: '20' },
                                                { value: '25', label: '25' },
                                                { value: '30', label: '30' },
                                                { value: '35', label: '35' },
                                                { value: '40', label: '40' },
                                                { value: '45', label: '45' },
                                                { value: '50', label: '50' },
                                                { value: '55', label: '55' },
                                                { value: '60', label: '60' },
                                                { value: '65', label: '65' },
                                                { value: '70', label: '70' },
                                                { value: '75', label: '75' },
                                                { value: '80', label: '80' },
                                                { value: '85', label: '85' },
                                                { value: '90', label: '90' },
                                                { value: '95', label: '95' },
                                                { value: '100', label: '100' },
                                                { value: '105', label: '105' },
                                                { value: '110', label: '110' },
                                                { value: '115', label: '115' },
                                                { value: '120', label: '120' },
                                            ]}
                                            placeholder={t('userSignPage.hoursNumber')}/>
                                    </FormItem>
                                }

                            </Card>
                        )
                    })
                }
            </Flex>

            <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                <GreyButton onClick={handleSend} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'send'}/></GreyButton>
            </Flex>
        </Form>
    );
};

export default SendUserSign;