import React from 'react';
import classes from './Footer.module.css';
import {Flex, Image} from "antd";
import HorizontalDivider from "../../../components/Divider/HorizontalDivider";
import {clrs} from "../../../constants/colors";
import LogoWhite from "../Logo/LogoWhite";
import aitu_logo_white from '../../../images/aitu_white_logo.png';
import MyText from "../../../components/UI/Text/MyText";
import {Link} from "react-router-dom";
import IntlMessage from "../../../components/IntlMessage/IntlMessage";
const Footer = () => {
    return (
        <div className={classes.mainBlock}>
            <Flex align={"center"} justify={"space-between"} gap={50} wrap={"wrap"} style={{width: "100%",maxWidth: 1440, position: "relative"}}>
                <Flex vertical align={"flex-start"} justify={"center"} gap={20}>
                    <LogoWhite />
                    <Flex vertical align={"flex-start"} justify={"flex-start"}>
                        <MyText medium>
                            Центр компетенций и совершенства
                        </MyText>
                        <MyText medium>
                            ТОО «Astana IT University»
                        </MyText>
                    </Flex>

                    <MyText medium>
                        Tel: +7 (7172) 645 714 (вн. 2123)
                    </MyText>
                    <MyText medium>
                        +7 771 325 6171 запись на курсы
                    </MyText>
                </Flex>
                <Flex vertical gap={15} align={"flex-start"} justify={"center"}>
                    <Link to={'/about-us'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'aboutUs'}/>
                        </MyText>
                    </Link>
                    <Link to={'/contacts'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'contacts'}/>
                        </MyText>
                    </Link>
                    <Link to={'/foundation'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'foundationPage.foundation'}/>
                        </MyText>
                    </Link>
                    <Link to={'/general-english'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'generalEnglish'}/>
                        </MyText>
                    </Link>
                    <Link to={'/robotics'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'roboticsPage.robotics'}/>
                        </MyText>
                    </Link>
                    <Link to={'/mba-fintech'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'mba-fintech'}/>
                        </MyText>
                    </Link>
                    <Link to={'/mba-executive'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'mba-executive'}/>
                        </MyText>
                    </Link>
                    <Link to={'/additional-master'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'additionalMaster'}/>
                        </MyText>
                    </Link>
                    <Link to={'/certificates'} className={classes.hoverText}>
                        <MyText uppercase strong>
                            <IntlMessage id={'certificates'}/>
                        </MyText>
                    </Link>
                </Flex>
                <Flex vertical align={"flex-start"} justify={"center"} gap={20}>
                    <Image src={aitu_logo_white} width={100} preview={false}/>
                    <Flex vertical align={"flex-start"} justify={"flex-start"}>
                        <MyText medium>
                            ТОО «Astana IT University»
                        </MyText>
                        <MyText medium>
                            проспект Мангилик Ел, 55/11
                        </MyText>
                        <MyText medium>
                            Бизнес-центр EXPO, блок C1
                        </MyText>
                        <MyText medium>
                            Астана, Казахстан, 010000
                        </MyText>
                    </Flex>

                    <MyText medium>
                        +7 (7172) 645-710 приемная комиссия
                    </MyText>
                </Flex>
                {/*<HorizontalDivider style={{backgroundColor: clrs.whiterBlacker}}/>*/}
                {/*<Flex vertical gap={20}>*/}
                {/*    <LogoWhite />*/}
                {/*    <p>© CCE, 2024</p>*/}
                {/*</Flex>*/}
            </Flex>
        </div>
    );
};

export default Footer;