import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";
import ApplicationService from "./ApplicationService";

const OrderService = {};
OrderService.getNextGroupNumber = async function (groupName) {
    if (groupName === undefined) {
        groupName = null;
    }
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/order/get/next-group-number`,
        params: {
            groupName: groupName,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
OrderService.saveOrder = async function (orderBodyRequest) {
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/order/save`,
        data: orderBodyRequest,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
OrderService.downloadOrderById = async function (orderId) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/order/generate`,
        params: {
            orderId: orderId
        },
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        responseType: "blob"
    });
}
OrderService.deleteOrder = async function (orderId) {
    let data = new FormData();
    data.append("orderId", orderId);

    return axios({
        method: "delete",
        url: API_BASE_URL + `/api/admin/order/delete`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

OrderService.getAllOrdersPageable = async function (page, limit) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/order/get`,
        params: {
            page: page - 1,
            limit: limit,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

OrderService.searchStudents = async function (categoryId, fullName) {
    if (categoryId === undefined) {
        categoryId = null;
    }
    if (fullName === undefined) {
        fullName = null;
    }
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/order/search/students`,
        params: {
            categoryId: categoryId,
            name: fullName ? fullName : "",
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

OrderService.getStudentsListAsExcel = async function () {

    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/order/students/list/excel`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        responseType: "blob"
    });
}
export default OrderService;