import React from 'react';
import img1 from "./123.png"
import img2 from "./234.png"
import img3 from "./345.png"
import {clrs} from "../../constants/colors";
import {useWindowSize} from "@uidotdev/usehooks";

const GeneralEnglishBanner = () => {
    const size = useWindowSize();

    if (size.width <= 450) {
        return (
            <div style={{width: 300, height: 330, position: "relative"}}>
                <div style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: 180,
                    height: 270,
                    zIndex: 1,
                    boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${img2})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}>
                </div>
                <div style={{
                    position: "absolute",
                    right: 0,
                    top: 30, width: 100, height: 140, zIndex: 2, boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${img3})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}>
                </div>
                <div style={{
                    position: "absolute",
                    left: 20, bottom: 0,
                    width: 270, height: 150,
                    zIndex: 3,
                    boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}>
                </div>
            </div>
        );
    }

    if (size.width <= 570) {
        return (
            <div style={{width: 400, height: 450, position: "relative"}}>
                <div style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: 225,
                    height: 300,
                    zIndex: 1,
                    boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${img2})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}>
                </div>
                <div style={{
                    position: "absolute",
                    right: 0,
                    top: 30, width: 140, height: 180, zIndex: 2, boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${img3})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}>
                </div>
                <div style={{
                    position: "absolute",
                    left: 20, bottom: 0,
                    width: 350, height: 200,
                    zIndex: 3,
                    boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                    backgroundImage: `url(${img1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}>
                </div>
            </div>
        );
    }

    return (
        <div style={{width: 500, height: 560, position: "relative"}}>
            <div style={{
                position: "absolute",
                left: 10,
                top: 20,
                width: 300,
                height: 350,
                zIndex: 1,
                boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                backgroundImage: `url(${img2})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}>
            </div>
            <div style={{
                position: "absolute",
                right: 0,
                top: 80, width: 160, height: 200, zIndex: 2, boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                backgroundImage: `url(${img3})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}>
            </div>
            <div style={{
                position: "absolute",
                left: 50, bottom: 0,
                width: 400, height: 250,
                zIndex: 3,
                boxShadow: '0 0 10px 4px rgba(0, 0, 0, 0.1)',
                backgroundImage: `url(${img1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}>
            </div>
        </div>
    );
};

export default GeneralEnglishBanner;