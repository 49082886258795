import React, {useEffect, useState} from 'react';
import {useWindowSize} from "@uidotdev/usehooks";
import {Collapse, Flex, Form, Image, Input, message, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import ApplicationService from "../../services/ApplicationService";
import CategoryService from "../../services/CategoryService";
import {LocalName} from "../../utils/LocalName";
import MyText from "../../components/UI/Text/MyText";
import Header from "../components/Header/Header";
import NewBanner from "../Home/components/Banner/NewBanner";
import {clrs} from "../../constants/colors";
import {Fade} from "react-awesome-reveal";
import {CalendarOutlined, CaretRightOutlined} from "@ant-design/icons";
import GraduateIcon from "../../components/icons/GraduateIcon";
import DiscussionIcon from "../../components/icons/DiscussionIcon";
import DurationIcon from "../../components/icons/DurationIcon";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import cl from "../Home/components/Banner/Banner.module.css";
import FormItem from "antd/es/form/FormItem";
import classes from "../Foundation/foundation.module.css";
import {EmailValidator} from "../../utils/EmailValidator";
import InputMask from "react-input-mask";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import Footer from "../components/Footer/Footer";
import goalIcon from './images/target.png';
import aboutProgram1 from './images/famous_white.png';
import aboutProgram2 from './images/manual_white.png';
import aboutProgram3 from './images/adaptive_white.png';
import aboutProgram4 from './images/teacher_white.png';
import HorizontalDivider from "../../components/Divider/HorizontalDivider";
import VerticalDivider from "../../components/Divider/VerticalDivider";
import deadlineIcon from './images/deadline.png';
import formatIcon from './images/online-event.png';
import bannerImg from './images/banner.png';
import GeneralEnglishBanner from "./GeneralEnglishBanner";

const GeneralEnglishPage = () => {
    const size = useWindowSize();

    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);
    const email = Form.useWatch('email', form);
    const telNumber = Form.useWatch('telNumber', form);
    const categoryId = Form.useWatch('categoryId', form);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesListManager, setCategoriesListManager] = useState([]);

    const [loading, setLoading] = useState(true);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setLoading(true);
                await ApplicationService.saveApplication(
                    name,
                    email,
                    telNumber.endsWith('_') ? telNumber.slice(0, -1) : telNumber,
                    categoryId)
                    .then(() => {
                        message.destroy();
                        if (categoriesListManager.includes(categoryId)) {
                            message.success(<IntlMessage id={'successApplicationSendManager'}/>, 5);
                        } else {
                            message.success(<IntlMessage id={'successApplicationSend'}/>, 5);
                        }
                        form.resetFields();
                        setLoading(false);
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setLoading(false);
                setCategoriesList(r.data);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
        setCategoriesListManager(
            categoriesList
                .filter(category => category.formType !== "FOUNDATION")
                .map(category => category.id)
        );
    }, [categoriesList]);

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "150px 20px 20px 20px"}} align={"center"}
                  justify={"center"} vertical>
                <Flex gap={75} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    {/*<NewBanner/>*/}
                    <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"space-between"} vertical={size.width <= 1300}>
                        <Flex vertical gap={20} style={{maxWidth: 700}}>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"left"}>
                                <MyText strong style={{fontSize: size.width <= 768 ? "2rem" : "3rem"}}>
                                    <IntlMessage id={'generalEnglishPage.banner1'}/>
                                </MyText>
                                <MyText size={size.width <= 768 ? "bigger" : "large"} style={{fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.banner2'}/>
                                </MyText>
                            </Fade>

                        </Flex>
                        <Fade triggerOnce={true} cascade damping={0.15}>
                            <GeneralEnglishBanner/>
                        </Fade>

                        {/*<img src={bannerImg} alt="" style={{width: 500, height: 500}}/>*/}
                    </Flex>
                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <HorizontalDivider style={{maxWidth: 500}}/>
                    </Flex>

                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <Image width={100} src={goalIcon} preview={false}/>
                            <MyText size={"large"} strong>
                                <IntlMessage id={'generalEnglishPage.goal'}/>
                            </MyText>
                            <MyText size={"bigger"} style={{maxWidth: 800, textAlign: "center", fontWeight: 400}}>
                                <IntlMessage id={'generalEnglishPage.goal.text'}/>
                            </MyText>
                        </Fade>

                    </Flex>

                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <HorizontalDivider style={{maxWidth: 500}}/>
                    </Flex>

                    <Flex gap={size.width <= 950 ? 40 : 75} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical={size.width <= 950}>
                        <Flex gap={20} align={"center"} justify={"flex-start"} style={{height: size.width <= 950 ? "auto" : 100}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong center>
                                    <IntlMessage id={'generalEnglishPage.feature.1.top'}/>
                                </MyText>
                                <MyText style={{maxWidth: 200, textAlign: "center", fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.feature.1.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        {
                            size.width <= 950 ?
                                <HorizontalDivider style={{maxWidth: 200}}/>
                                :
                                <div style={{height: 100}}>
                                    <HorizontalDivider/>
                                </div>
                        }

                        <Flex gap={20} align={"center"} justify={"flex-start"} style={{height: size.width <= 950 ? "auto" : 100}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong center>
                                    <IntlMessage id={'generalEnglishPage.feature.2.top'}/>
                                </MyText>
                                <MyText style={{maxWidth: 200, textAlign: "center", fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.feature.2.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        {
                            size.width <= 950 ?
                                <HorizontalDivider style={{maxWidth: 200}}/>
                                :
                                <div style={{height: 100}}>
                                    <HorizontalDivider/>
                                </div>
                        }
                        <Flex gap={20} align={"center"} justify={"flex-start"} style={{height: size.width <= 950 ? "auto" : 100}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong center>
                                    <IntlMessage id={'generalEnglishPage.feature.3.top'}/>
                                </MyText>
                                <MyText style={{maxWidth: 200, textAlign: "center", fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.feature.3.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        {
                            size.width <= 950 ?
                                <HorizontalDivider style={{maxWidth: 200}}/>
                                :
                                <div style={{height: 100}}>
                                    <HorizontalDivider/>
                                </div>
                        }
                        <Flex gap={20} align={"center"} justify={"flex-start"} style={{height: size.width <= 950 ? "auto" : 100}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong center>
                                    <IntlMessage id={'generalEnglishPage.feature.4.top'}/>
                                </MyText>
                                <MyText style={{maxWidth: 200, textAlign: "center", fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.feature.4.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>

            <Flex gap={75} style={{width: "100%", backgroundColor: clrs.blackerBlue, position: "relative", marginTop: 50, padding: "50px 20px"}} vertical
                  align={"center"} justify={"center"}>
                <Flex gap={75} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                        <MyText size={"large"} strong style={{color: "white"}}>
                            <IntlMessage id={'generalEnglishPage.aboutProgram'}/>
                        </MyText>
                    </Fade>
                    <Flex gap={50} style={{width: "100%", position: "relative"}} vertical
                          align={"center"} justify={"flex-start"}>
                        <Flex gap={50} style={{width: "100%", position: "relative"}}
                              align={"center"} justify={"space-between"} vertical={size.width <= 768}>
                            <Flex gap={20} align={size.width <= 768 ? "center" : "flex-start"} justify={"flex-start"} style={{width: "100%"}} vertical>
                                <Flex align={"center"} justify={"center"} gap={20}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <Image src={aboutProgram1} width={60} preview={false}/>
                                        <MyText size={"bigger"} strong style={{color: "white"}}>
                                            <IntlMessage id={'generalEnglishPage.aboutProgram.1.1'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                                <Fade delay={500} triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <MyText style={{color: "white", fontWeight: 300, textAlign: size.width <= 768 ? "center" : "left" }}>
                                        <IntlMessage id={'generalEnglishPage.aboutProgram.1.2'}/>
                                    </MyText>
                                </Fade>
                            </Flex>
                            <Flex gap={20} align={size.width <= 768 ? "center" : "flex-start"} justify={"flex-start"} style={{width: "100%"}} vertical>
                                <Flex align={"center"} justify={"center"} gap={20}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <Image src={aboutProgram2} width={60} preview={false}/>
                                        <MyText size={"bigger"} strong style={{color: "white"}}>
                                            <IntlMessage id={'generalEnglishPage.aboutProgram.2.1'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                                <Fade delay={500} triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <MyText style={{color: "white", fontWeight: 300, textAlign: size.width <= 768 ? "center" : "left" }}>
                                        <IntlMessage id={'generalEnglishPage.aboutProgram.2.2'}/>
                                    </MyText>
                                </Fade>
                            </Flex>
                        </Flex>
                        <Flex gap={50} style={{width: "100%", position: "relative"}}
                              align={"center"} justify={"space-between"} vertical={size.width <= 768}>
                            <Flex gap={20} align={size.width <= 768 ? "center" : "flex-start"} justify={"flex-start"} style={{width: "100%"}} vertical>
                                <Flex align={"center"} justify={"center"} gap={20}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <Image src={aboutProgram3} width={60} preview={false}/>
                                        <MyText size={"bigger"} strong style={{color: "white"}}>
                                            <IntlMessage id={'generalEnglishPage.aboutProgram.3.1'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                                <Fade delay={500} triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <MyText style={{color: "white", fontWeight: 300, textAlign: size.width <= 768 ? "center" : "left" }}>
                                        <IntlMessage id={'generalEnglishPage.aboutProgram.3.2'}/>
                                    </MyText>
                                </Fade>
                            </Flex>
                            <Flex gap={20} align={size.width <= 768 ? "center" : "flex-start"} justify={"flex-start"} style={{width: "100%"}} vertical>
                                <Flex align={"center"} justify={"center"} gap={20}>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                        <Image src={aboutProgram4} width={60} preview={false}/>
                                        <MyText size={"bigger"} strong style={{color: "white"}}>
                                            <IntlMessage id={'generalEnglishPage.aboutProgram.4.1'}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                                <Fade delay={500} triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                    <MyText style={{color: "white", fontWeight: 300, textAlign: size.width <= 768 ? "center" : "left" }}>
                                        <IntlMessage id={'generalEnglishPage.aboutProgram.4.2'}/>
                                    </MyText>
                                </Fade>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"}
                  justify={"center"} vertical>
                <Flex gap={75} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>

                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <Image width={100} src={deadlineIcon} preview={false}/>
                            <MyText size={"large"} strong center>
                                <IntlMessage id={'generalEnglishPage.deadline'}/>
                            </MyText>
                            <MyText size={"bigger"} style={{maxWidth: 800, textAlign: "center", fontWeight: 400}}>
                                <IntlMessage id={'generalEnglishPage.deadline.1'}/>
                            </MyText>
                            <MyText size={"bigger"} italic style={{maxWidth: 800, textAlign: "center", fontWeight: 400}}>
                                <IntlMessage id={'generalEnglishPage.deadline.2'}/>
                            </MyText>
                        </Fade>

                    </Flex>
                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <HorizontalDivider style={{maxWidth: 500}}/>
                    </Flex>
                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <Image width={100} src={formatIcon} preview={false}/>
                            <MyText size={"large"} strong>
                                <IntlMessage id={'generalEnglishPage.format'}/>
                            </MyText>
                            <MyText size={"bigger"} style={{maxWidth: 800, textAlign: "center", fontWeight: 400}}>
                                <IntlMessage id={'generalEnglishPage.format.1'}/>
                                <br/>
                                <IntlMessage id={'generalEnglishPage.format.2'}/>
                            </MyText>
                        </Fade>
                    </Flex>

                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <HorizontalDivider style={{maxWidth: 500}}/>
                    </Flex>

                    <Flex gap={size.width <= 768 ? 40 : 75} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical={size.width <= 768}>
                        <Flex gap={20} align={"center"} justify={"flex-start"} style={{height: size.width <= 768 ? "auto" : 100}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong>
                                    <IntlMessage id={'generalEnglishPage.feature.v2.1.top'}/>
                                </MyText>
                                <MyText style={{maxWidth: 200, textAlign: "center", fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.feature.v2.1.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                        {
                            size.width <= 768 ?
                                <HorizontalDivider style={{maxWidth: 200}}/>
                                :
                                <div style={{height: 100}}>
                                    <HorizontalDivider/>
                                </div>
                        }
                        <Flex gap={20} align={"center"} justify={"flex-start"} style={{height: size.width <= 768 ? "auto" : 100}} vertical>
                            <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                                <MyText size={"bigger"} strong>
                                    <IntlMessage id={'generalEnglishPage.feature.v2.2.top'}/>
                                </MyText>
                                <MyText style={{maxWidth: 200, textAlign: "center", fontWeight: 300}}>
                                    <IntlMessage id={'generalEnglishPage.feature.v2.2.bottom'}/>
                                </MyText>
                            </Fade>
                        </Flex>
                    </Flex>

                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <HorizontalDivider style={{maxWidth: 500}}/>
                    </Flex>

                    <Flex
                        gap={30}
                        align={"flex-start"}
                        justify={"center"}
                        style={{
                            width: "100%",
                            border: "1px solid #D7D7D7",
                            borderRadius: 20,
                            padding: "30px 15px"
                        }}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <Flex gap={10} vertical align={"center"} justify={"center"}>
                                <MyText strong size={"large"} style={{color: clrs.blackerBlue, textAlign: "center"}}>
                                    <IntlMessage id={'foundationPage.contactToCall'}/>
                                </MyText>
                                <MyText size={"bigger"} style={{color: clrs.whiterBlacker, maxWidth: 200, textAlign: "center"}}>
                                    +7 (771) 325-61-71
                                    <br/>
                                    +7 (7172)-645-714
                                </MyText>

                            </Flex>
                        </Fade>

                    </Flex>

                    <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}}
                          align={"center"} justify={"center"} vertical>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <MyText size={"large"} strong>
                                <IntlMessage id={'generalEnglishPage.faq.title'}/>
                            </MyText>
                        </Fade>
                        <Fade triggerOnce={true} cascade damping={0.15}>
                            <Collapse
                                defaultActiveKey={[1,2,3,4,5]}
                                style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    borderRadius: 20
                                }}
                                items={[
                                    {
                                        key: '1',
                                        label: <MyText strong>
                                            <IntlMessage id={'generalEnglishPage.faq.1.q'}/>
                                        </MyText>,
                                        children: <MyText>
                                            <IntlMessage id={'generalEnglishPage.faq.1.a'}/>
                                        </MyText>,
                                        style: {
                                            padding: 0,
                                            backgroundColor: clrs.yellow,
                                        }
                                    },
                                    {
                                        key: '2',
                                        label: <MyText strong>
                                            <IntlMessage id={'generalEnglishPage.faq.2.q'}/>
                                        </MyText>,
                                        children: <MyText>
                                            <IntlMessage id={'generalEnglishPage.faq.2.a'}/>
                                        </MyText>,
                                        style: {
                                            padding: 0,
                                            backgroundColor: clrs.yellow,
                                        }
                                    },
                                    {
                                        key: '3',
                                        label: <MyText strong>
                                            <IntlMessage id={'generalEnglishPage.faq.3.q'}/>
                                        </MyText>,
                                        children: <MyText>
                                            <IntlMessage id={'generalEnglishPage.faq.3.a'}/>
                                        </MyText>,
                                        style: {
                                            padding: 0,
                                            backgroundColor: clrs.yellow,
                                        }
                                    },
                                    {
                                        key: '4',
                                        label: <MyText strong>
                                            <IntlMessage id={'generalEnglishPage.faq.4.q'}/>
                                        </MyText>,
                                        children: <MyText>
                                            <IntlMessage id={'generalEnglishPage.faq.4.a'}/>
                                        </MyText>,
                                        style: {
                                            padding: 0,
                                            backgroundColor: clrs.yellow,
                                        }
                                    },
                                    {
                                        key: '5',
                                        label: <MyText strong>
                                            <IntlMessage id={'generalEnglishPage.faq.5.q'}/>
                                        </MyText>,
                                        children: <MyText>
                                            <IntlMessage id={'generalEnglishPage.faq.5.a'}/>
                                        </MyText>,
                                        style: {
                                            padding: 0,
                                            backgroundColor: clrs.yellow,
                                        }
                                    },
                                ]}
                            />
                        </Fade>

                    </Flex>

                </Flex>
            </Flex>

            {/* Banner form */}
            <Flex style={{width: "100%", position: "relative", padding: "100px 20px 20px 20px"}} align={"center"}
                  justify={"center"} vertical>
                <Flex gap={75} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>

                    <Flex style={{width: "100%", position: "relative"}} gap={20} align={"center"} justify={"center"}>
                        <Fade triggerOnce={true} cascade style={{width: '100%'}}>
                            <Form form={form} onSubmit={handleSubmit}>
                                <BlockLoading isLoading={loading}/>
                                <div>
                                    <p className={cl.bannerFormText}><IntlMessage id={'bannerText2'}/></p>
                                    <p className={cl.bannerFormText}><IntlMessage id={'bannerText3'}/></p>
                                    <br/>
                                </div>

                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormNameError'}/>,
                                },
                                ]} className={classes.formInputBlock} name="name">
                                    <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'name'})}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormEmailError'}/>,
                                },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (EmailValidator.isValid(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t('emailRules')));
                                        },
                                    }),
                                ]} className={classes.formInputBlock} name="email">
                                    <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'email'})}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'bannerFormTelError'}/>,
                                },
                                ]} className={classes.formInputBlock} name="telNumber">
                                    <InputMask
                                        mask="+9 (999) 999-9999"
                                        className={classes.formInput}
                                        placeholder={IntlMessageText.getText({id: 'telNumber'})}
                                    >
                                        {(inputProps) => <Input {...inputProps} />}
                                    </InputMask>
                                    {/*<Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'telNumber'})}/>*/}
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'requiredField'}/>,
                                },
                                ]} className={classes.formInput} style={{width: "100%", padding: 0}} name="categoryId">
                                    <Select
                                        options={categories}
                                        placeholder={<IntlMessage id={'categoryCourse'}/>}
                                    />
                                </FormItem>
                                <FormItem className={classes.formInputBlock}>
                                    <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                                        <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                                            <IntlMessage id={'send'}/>
                                        </GreyButton>
                                    </Flex>
                                </FormItem>
                            </Form>
                        </Fade>
                    </Flex>

                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

export default GeneralEnglishPage;