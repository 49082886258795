import React, {useState} from 'react';
import classes from './header.module.css';
import LogoBlack from "../Logo/LogoBlack";
import {Drawer, Dropdown, Flex, Image, Popover} from "antd";
import GreyButton from "../../../components/UI/Buttons/GreyButton";
import IntlMessage from "../../../components/IntlMessage/IntlMessage";
import {Link, useNavigate} from "react-router-dom";
import {useWindowSize} from "@uidotdev/usehooks";
import burger from '../../../images/burger-menu-5.svg';
import {Fade} from "react-awesome-reveal";
const Header = () => {
    const [open, setOpen] = useState(false);
    const size = useWindowSize();

    const navigate = useNavigate();

    if (size.width <= 768) {
        return (
            <div className={classes.header}>
                <div className={classes.wrapper}>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                        <Image preview={false} width={40} src={burger} onClick={() => {setOpen(true)}} wrapperStyle={{cursor: "pointer"}}/>
                    </Fade>
                    <Drawer width={300} placement={'left'} open={open} onClose={() => {setOpen(false)}}>
                        <Flex style={{width: "100%"}} gap={35} vertical align={"center"} justify={"center"}>
                            <LogoBlack/>
                            <Flex gap={30} align={"center"} vertical justify={"center"}>
                                {/*<Link to={'/mba'} className={classes.hoverText}>*/}
                                {/*    <IntlMessage id={'mba'}/>*/}
                                {/*</Link>*/}
                                <Link to={'/about-us'} className={classes.hoverText}>
                                    <IntlMessage id={'aboutUs'}/>
                                </Link>
                                <Link to={'/contacts'} className={classes.hoverText}>
                                    <IntlMessage id={'contacts'}/>
                                </Link>
                                <Link to={'/foundation'} className={classes.hoverText}>
                                    <IntlMessage id={'foundationPage.foundation'}/>
                                </Link>
                                <Link to={'/general-english'} className={classes.hoverText}>
                                    <IntlMessage id={'generalEnglish'}/>
                                </Link>
                                <Link to={'/robotics'} className={classes.hoverText}>
                                    <IntlMessage id={'roboticsPage.robotics'}/>
                                </Link>
                                <Link to={'/additional-master'} className={classes.hoverText}>
                                    <IntlMessage id={'additionalMaster'}/>
                                </Link>
                                <Link to={'/mba-fintech'} className={classes.hoverText}>
                                    <IntlMessage id={'mba-fintech'}/>
                                </Link>
                                <Link to={'/mba-executive'} className={classes.hoverText}>
                                    <IntlMessage id={'mba-executive'}/>
                                </Link>
                                <Link to={'/certificates'} className={classes.hoverText}>
                                    <IntlMessage id={'certificates'}/>
                                </Link>
                                <Link to={'/login'}>
                                    <GreyButton>
                                        <IntlMessage id={'login'}/>
                                    </GreyButton>
                                </Link>

                            </Flex>
                        </Flex>
                    </Drawer>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.header}>
            <div className={classes.wrapper}>
                <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                    <LogoBlack/>
                </Fade>
                <Flex gap={30} align={"center"} justify={"center"}>
                    {/*<Link to={'/mba'} className={classes.hoverText}>*/}
                    {/*    <IntlMessage id={'mba'}/>*/}
                    {/*</Link>*/}
                    <Fade triggerOnce={true} cascade damping={0.25} direction={"down"}>
                        <Link to={'/about-us'} className={classes.hoverText}>
                            <IntlMessage id={'aboutUs'}/>
                        </Link>
                        <Link to={'/contacts'} className={classes.hoverText}>
                            <IntlMessage id={'contacts'}/>
                        </Link>

                        <Popover placement={"bottom"} content={(
                            <Flex style={{padding: "0 0 5px 0"}} align={"center"} justify={"center"} gap={15} vertical>
                                <Link to={'/mba-fintech'} className={classes.hoverText}>
                                    <IntlMessage id={'mba-fintech'}/>
                                </Link>
                                <Link to={'/mba-executive'} className={classes.hoverText}>
                                    <IntlMessage id={'mba-executive'}/>
                                </Link>
                            </Flex>
                        )}>
                            <Link to={''} className={classes.hoverText}>
                                <IntlMessage id={'mba'}/>
                            </Link>
                        </Popover>

                        <Popover placement={"bottom"} content={(
                            <Flex style={{padding: "0 0 5px 0"}} align={"center"} justify={"center"} gap={15} vertical>
                                <Link to={'/foundation'} className={classes.hoverText}>
                                    <IntlMessage id={'foundationPage.foundation'}/>
                                </Link>
                                <Link to={'/general-english'} className={classes.hoverText}>
                                    <IntlMessage id={'generalEnglish'}/>
                                </Link>
                                <Link to={'/robotics'} className={classes.hoverText}>
                                    <IntlMessage id={'roboticsPage.robotics'}/>
                                </Link>
                                <Link to={'/additional-master'} className={classes.hoverText} style={{width: 200}}>
                                    <IntlMessage id={'additionalMaster'}/>
                                </Link>
                            </Flex>
                        )}>
                            <Link to={''} className={classes.hoverText}>
                                <IntlMessage id={'learn'}/>
                            </Link>

                        </Popover>

                        <Link to={'/certificates'} className={classes.hoverText}>
                            <IntlMessage id={'certificates'}/>
                        </Link>
                        <Link to={'/login'}>
                            <GreyButton>
                                <IntlMessage id={'login'}/>
                            </GreyButton>
                        </Link>
                    </Fade>

                </Flex>
            </div>
        </div>
    );
};

export default Header;