import React from 'react';
import {CustomIconComponentProps} from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons";


const svg = () => {
    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m16 4.875-.46875.25-13.5 7-1.71875.875 1.6875.84375v8.4375c-.597656.347656-1 .980469-1 1.71875 0 1.105469.894531 2 2 2s2-.894531 2-2c0-.738281-.402344-1.371094-1-1.71875v-7.40625l2 1.03125v5.09375c0 .441406.203125.839844.4375 1.09375s.519531.40625.8125.5625c.589844.308594 1.289063.527344 2.15625.71875 1.734375.386719 4.046875.625 6.59375.625s4.859375-.238281 6.59375-.625c.867188-.191406 1.566406-.410156 2.15625-.71875.292969-.15625.578125-.308594.8125-.5625s.4375-.652344.4375-1.09375v-5.09375l3.96875-2.03125 1.71875-.875-1.71875-.875-13.5-7zm0 2.25 11.3125 5.875-1.78125.90625c-.226562-.238281-.5-.414062-.78125-.5625-.585937-.308594-1.289062-.527344-2.15625-.71875-1.730469-.386719-4.035156-.625-6.59375-.625s-4.863281.238281-6.59375.625c-.867187.191406-1.570312.410156-2.15625.71875-.28125.148438-.554687.324219-.78125.5625l-1.78125-.90625zm0 6.875c2.441406 0 4.636719.222656 6.15625.5625.757813.167969 1.367188.363281 1.6875.53125.101563.054688.117188.09375.15625.125v3.8125c-.417969-.152344-.875-.289062-1.40625-.40625-1.734375-.386719-4.046875-.625-6.59375-.625s-4.859375.238281-6.59375.625c-.53125.117188-.988281.253906-1.40625.40625v-3.8125c.039063-.03125.054688-.070312.15625-.125.320313-.167969.929688-.363281 1.6875-.53125 1.519531-.339844 3.714844-.5625 6.15625-.5625zm0 6c2.425781 0 4.632813.222656 6.15625.5625.632813.140625 1.03125.289063 1.375.4375-.34375.148438-.742187.296875-1.375.4375-1.523437.339844-3.730469.5625-6.15625.5625s-4.632812-.222656-6.15625-.5625c-.632812-.140625-1.03125-.289062-1.375-.4375.34375-.148437.742188-.296875 1.375-.4375 1.523438-.339844 3.730469-.5625 6.15625-.5625z"/>
        </svg>
    );
};

const GraduateIcon = (props: Partial<CustomIconComponentProps>) => {
    return (
        <Icon component={svg} {...props}/>
    );
};

export default GraduateIcon;