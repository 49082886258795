import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const GroupService = {};

GroupService.getGroups = async function (page, limit, categoryId) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/group/get`,
        params: {
            page: page - 1,
            limit: limit,
            categoryId: categoryId === undefined ? null : categoryId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return {data, hasMore};
}

GroupService.getGroup = async function (groupId) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/teacher/group/get/${groupId}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

GroupService.getGroupsByTeacher = async function (page, limit, teacherId) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/teacher/group/get`,
        params: {
            page: page - 1,
            limit: limit,
            id: teacherId === undefined ? null : teacherId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    return response.data;
}

GroupService.getNextGroupNumber = async function (courseId) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/group/get/next-group-number`,
        params: {
            courseId: courseId
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

GroupService.saveGroup = async (nameKz, nameRu, nameEng, number, category, course, teacher, teacherContractNumber, teacherContractDate, students) => {
    let data = new FormData();
    data.append("nameKz", nameKz);
    data.append("nameRu", nameRu);
    data.append("nameEng", nameEng);
    data.append("number", number);
    data.append("categoryId", category);
    data.append("courseId", course);
    data.append("teacherId", teacher);
    data.append("teacherContractNumber", teacherContractNumber);
    data.append("teacherContractDate", teacherContractDate);
    if (students !== undefined) {
        data.append("students", students);
    }

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/group/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default GroupService;