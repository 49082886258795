import React, {useEffect, useState} from 'react';
import IntlMessage, {IntlMessageText} from "../../../../components/IntlMessage/IntlMessage";
import {Card, Col, Flex, message, Row} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import GroupService from "../../../../services/GroupService";
import {useSelector} from "react-redux";
import BlockLoading from "../../../../components/LoadingComponents/BlockLoading";
import {LocalName} from "../../../../utils/LocalName";
import {clrs} from "../../../../constants/colors";
import GreyButton from "../../../../components/UI/Buttons/GreyButton";
import logout from "../Logout/Logout";
import {CalendarOutlined, FolderOutlined, TeamOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";

const TeacherGroupsPage = () => {
    const navigate = useNavigate();
    const {user} = useSelector(state => state.user);
    const [groups, setGroups] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        handleFetchData();
    }, []);

    async function handleFetchData() {
        setLoading(true);
        await GroupService.getGroupsByTeacher(1, 1000, user.id)
            .then((r) => {
                setGroups(r);
                setLoading(false);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'ordersPage.groups'}/></MyText>
            <BlockLoading isLoading={isLoading}/>
            <Flex style={{width: '100%'}} justify={"flex-start"} align={"center"}>
                {!isLoading &&
                    <Row style={{width: '100%'}} gutter={[16,16]} align={"middle"} justify={"start"}>
                        {
                            groups.map((group) => (
                                <Col span={8} key={group.id} >
                                    <Card  title={LocalName.getName(group) + " " + group.number}>
                                        <Flex vertical gap={20} align={"flex-start"} justify={"center"}>
                                            <MyText><FolderOutlined /> {LocalName.getName(group.category)}</MyText>
                                            <MyText><CalendarOutlined /> <IntlMessage id={"ordersPage.academicYear"}/>:{group.academicYear}</MyText>
                                            <MyText><TeamOutlined /> <IntlMessage id={"tg.students"}/>{group.students.length}</MyText>
                                            <GreyButton onClick={() => navigate(`/p/group/${group.id}`)} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                                                Открыть
                                            </GreyButton>
                                        </Flex>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                }
            </Flex>
        </Flex>
    );
};

export default TeacherGroupsPage;