import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useWindowSize} from "@uidotdev/usehooks";
import classes from '../global_styles.module.css';
import PlatformHeader from "./components/Header/PlatformHeader";
import {Drawer, Flex, message} from "antd";
import PlatformNavigation from "./components/Navigation/PlatformNavigation";
import './platform.module.css';
import {setClosed} from "../../store/slices/platform/PlatformNavSlice";
import HomePage from "./pages/Home/HomePage";
import NotFound from "./pages/404/NotFound";
import Logout from "./pages/Logout/Logout";
import {clearUser} from "../../store/slices/userSlice";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import ApplicationsPage from "./pages/Applications/ApplicationsPage";
import CoursesPage from "./pages/Courses/CoursesPage";
import GlobalValuePage from "./pages/GlobalValues/GlobalValuePage";
import ContractsPage from "./pages/Contracts/ContractsPage";
import TeachersPage from "./pages/Teachers/TeachersPage";
import OrdersPage from "./pages/Orders/OrdersPage";
import UsersPage from "./pages/Users/UsersPage";
import GroupsPage from "./pages/Groups/GroupsPage";
import SettingsPage from "./pages/Settings/SettingsPage";
import DirectorApplicationsPage from "./pages/DirectorApplications/DirectorApplicationsPage";
import UserSignPage from "./pages/UserSign/UserSignPage";
import TeacherGroupsPage from "./pages/TeacherGroups/TeacherGroupsPage";
import TeacherGroupPage from "./pages/TeacherGroups/TeacherGroupPage";

const Platform = () => {
    const isClosedNav = useSelector(state => state.platformNav.isClosed);
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const size = useWindowSize();

    useEffect(() => {
        if (localStorage.getItem('isClosedNav') === null) {
            let windowWidth = window.innerWidth;
            if (windowWidth > 768) {
                dispatch(setClosed(false));
            } else {
                dispatch(setClosed(true));
            }
        }
    }, []);

    useEffect(() => {
        let dateExpiration = new Date(localStorage.getItem('ExpirationDate'));
        let now = new Date();
        if (localStorage.getItem('ExpirationDate')) {
            if (dateExpiration < now) {
                localStorage.removeItem('Authorization');
                localStorage.removeItem('ExpirationDate');
                dispatch(clearUser());
                message.warning(<IntlMessage id={'sessionExpired'}/>, 5);
            }
        }
    }, [navigate]);

    function setClosedDrawer() {
        dispatch(setClosed(true));
    }

    if (size.width <= 1024) {
        return (
            <Flex className={classes.main}>
                <PlatformHeader/>
                <Flex className={classes.inner_main}>
                    <Drawer
                        title={null}
                        placement={'left'}
                        closable={false}
                        onClose={setClosedDrawer}
                        open={!isClosedNav}
                        width={300}
                        drawerStyle={{padding: 0}}
                        bodyStyle={{padding: 0}}
                    >
                        <PlatformNavigation/>
                    </Drawer>
                    <Flex className={classes.body2}>
                        <Flex align={"flex-start"} justify={"flex-start"} style={{width: "100%", maxHeight: "100%", overflowX: "scroll", padding: size.width > 768 ? "15px 25px" : "15px"}}>
                            <RoleRouting/>
                        </Flex>

                    </Flex>
                </Flex>
            </Flex>
        );
    }

    return (
        <div className={classes.main}>
            <PlatformHeader/>
            <Flex className={classes.inner_main}>
                <Flex className={[classes.nav, isClosedNav ? classes.closed : ""].join(" ")}>
                    <Flex className={classes.nav_inner}>
                        <PlatformNavigation/>
                    </Flex>
                </Flex>
                <Flex className={[classes.body, isClosedNav ? classes.bodyMax : ""].join(" ")}>
                    <Flex align={"flex-start"} justify={"flex-start"} style={{width: "100%", maxHeight: "100%", overflowX: "scroll", padding: size.width > 768 ? "15px 25px" : "15px"}}>
                        <RoleRouting/>
                    </Flex>

                </Flex>
            </Flex>
        </div>
    );
};

const RoleRouting = () => {
    const {user, isLoading} = useSelector(state => state.user);

    if (user?.role.roleName === 'admin') {
        return (
            <Routes>
                <Route path='/home' element={<HomePage/>}/>
                <Route path='/applications' element={<ApplicationsPage/>}/>
                <Route path='/courses' element={<CoursesPage/>}/>
                <Route path='/globalData' element={<GlobalValuePage/>}/>
                <Route path='/contracts' element={<ContractsPage/>}/>
                <Route path='/teachers' element={<TeachersPage/>}/>
                <Route path='/users' element={<UsersPage/>}/>
                <Route path='/groups' element={<GroupsPage/>}/>
                <Route path='/orders' element={<OrdersPage/>}/>
                <Route path='/user-sign' element={<UserSignPage/>}/>
                <Route path='/logout' element={<Logout/>}/>
                <Route path='/*' element={<NotFound/>}/>
            </Routes>
        )
    }

    if (user?.role.roleName === 'director' || user?.role.roleName === 'rector') {
        return (
            <Routes>
                <Route path='/applications' element={<DirectorApplicationsPage/>}/>
                <Route path='/user-sign' element={<UserSignPage/>}/>
                <Route path='/logout' element={<Logout/>}/>
            </Routes>
        )
    }

    if (user?.role.roleName === 'teacher') {
        return (
            <Routes>
                <Route path='/groups' element={<TeacherGroupsPage/>}/>
                <Route path='/group/:id' element={<TeacherGroupPage/>}/>
                <Route path='/settings' element={<SettingsPage/>}/>
                <Route path='/logout' element={<Logout/>}/>
                <Route path='/*' element={<NotFound/>}/>
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path='/settings' element={<SettingsPage/>}/>
            <Route path='/user-sign' element={<UserSignPage/>}/>
            <Route path='/logout' element={<Logout/>}/>
            <Route path='/*' element={<NotFound/>}/>
        </Routes>
    );
};

export default Platform;