import React, {useEffect, useState} from 'react';
import Header from "../components/Header/Header";
import {Flex, Form, Image, Input, message, Select} from "antd";
import Footer from "../components/Footer/Footer";
import image from './banner-image.webp';
import diplome from './add-master-diplom.jpg'
import transcript1 from './transcript-1.jpg'
import transcript2 from './transcript-2.jpg'
import MyText from "../../components/UI/Text/MyText";
import {Fade} from "react-awesome-reveal";
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import cl from "../Home/components/Banner/Banner.module.css";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import FormItem from "antd/es/form/FormItem";
import classes from "../Foundation/foundation.module.css";
import {EmailValidator} from "../../utils/EmailValidator";
import InputMask from "react-input-mask";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import {clrs} from "../../constants/colors";
import {useWindowSize} from "@uidotdev/usehooks";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import ApplicationService from "../../services/ApplicationService";
import CategoryService from "../../services/CategoryService";
import {LocalName} from "../../utils/LocalName";
import {CaretRightOutlined, FileDoneOutlined, FormOutlined, IdcardOutlined} from "@ant-design/icons";
import styles from "./AdditionalMaster.module.css";

const AdditionalMasterPage = () => {
    const windowSize = useWindowSize();

    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);
    const email = Form.useWatch('email', form);
    const telNumber = Form.useWatch('telNumber', form);
    const categoryId = Form.useWatch('categoryId', form);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoriesListManager, setCategoriesListManager] = useState([]);


    const [loading, setLoading] = useState(true);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setLoading(true);
                await ApplicationService.saveApplication(
                    name,
                    email,
                    telNumber.endsWith('_') ? telNumber.slice(0, -1) : telNumber,
                    categoryId)
                    .then(() => {
                        message.destroy();
                        if (categoriesListManager.includes(categoryId)) {
                            message.success(<IntlMessage id={'successApplicationSendManager'}/>, 5);
                        } else {
                            message.success(<IntlMessage id={'successApplicationSend'}/>, 5);
                        }
                        form.resetFields();
                        setLoading(false);
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setLoading(false);
                setCategoriesList(r.data);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return {value: category.id, label: LocalName.getName(category)}
            })
        )
        setCategoriesListManager(
            categoriesList
                .filter(category => category.formType !== "FOUNDATION")
                .map(category => category.id)
        );
    }, [categoriesList]);

    return (
        <div>
            <Header/>
            <Flex gap={80} style={{width: "100%", position: "relative", padding: "100px 20px 20px 20px"}} align={"center"}
                  justify={"center"} vertical>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Flex style={{
                        width: "100%",
                        borderBottomRightRadius: 20,
                        borderBottomLeftRadius: 20,
                        height: 600,
                        position: "relative",
                        overflow: "hidden"
                    }} align={"flex-start"} justify={"center"} vertical>
                        <Fade triggerOnce={true} style={{zIndex: 1}} cascade damping={0.15} direction={"down"}>
                            <MyText style={{
                                fontSize: windowSize.width >= 1024 ? "3rem" : "2rem",
                                color: "white",
                                zIndex: 1,
                                paddingLeft: 20,
                                fontWeight: "bold"
                            }}>
                                <IntlMessage id={"amBannerText1"}/>
                            </MyText>
                            <MyText
                                style={{fontSize: "2rem", color: "white", zIndex: 1, paddingLeft: 20, fontWeight: 300}}>
                                <IntlMessage id={"amBannerText2"}/>
                            </MyText>
                        </Fade>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            backgroundImage: `url(${image})`,
                            backgroundPosition: "center",
                            backgroundAttachment: "fixed",
                        }}/>
                        <div style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            backgroundColor: "black",
                            opacity: 0.7
                        }}/>
                    </Flex>
                </Flex>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                        <MyText size={"bigger"} center>
                            <IntlMessage id={"amText1"}/>
                        </MyText>
                    </Fade>
                </Flex>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                        <MyText strong size={"larger"} center>
                            <IntlMessage id={"requiredDocuments"}/>
                        </MyText>
                    </Fade>
                    <Flex
                        gap={30}
                        align={"center"}
                        justify={"space-around"}
                        style={{
                            width: "100%",
                            border: "1px solid #D7D7D7",
                            borderRadius: 20,
                            padding: "30px 15px"
                        }}
                        vertical={windowSize.width < 768}
                    >
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <InfoCard text={<IntlMessage id={"idDocumentCopy"}/>}
                                      icon={<IdcardOutlined style={{fontSize: "2.5rem"}}/>}/>
                            <InfoCard
                                text={<IntlMessage id={"diplomaCopy"}/>}
                                icon={<FileDoneOutlined style={{fontSize: "2.5rem"}}/>}/>
                            <InfoCard text={<IntlMessage id={"workPaperCopy"}/>}
                                      icon={<FormOutlined style={{fontSize: "2.5rem"}}/>}/>
                            <InfoCard text={<IntlMessage id={"nameChangeCopy"}/>}
                                      icon={<FormOutlined style={{fontSize: "2.5rem"}}/>}/>
                        </Fade>
                    </Flex>
                </Flex>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}
                          style={{marginTop: 30, marginBottom: 30}}>
                        <MyText size={"bigger"} center uppercase style={{color: clrs.whiterRed}}>
                            <IntlMessage id={"amDeadline"}/>
                        </MyText>
                    </Fade>
                </Flex>
                <Flex gap={20} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                        <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                            <CaretRightOutlined style={{color: clrs.yellow}}/>
                            <IntlMessage id={"amDocText1"}/>
                        </MyText>
                        <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                            <CaretRightOutlined style={{color: clrs.yellow}}/>
                            <IntlMessage id={"amDocText2"}/>
                        </MyText>
                        <MyText style={{textAlign: "justify", fontSize: "1.2rem"}}>
                            <CaretRightOutlined style={{color: clrs.yellow}}/>
                            <IntlMessage id={"amDocText3"}/>
                        </MyText>
                    </Fade>
                </Flex>

                {/*<Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>*/}
                {/*    <MyText size={"bigger"} style={{textAlign: "center"}}>*/}
                {/*        По окончанию обучения, завершившему образовательную программу магистратуры*/}
                {/*        научного-педагогического профиля, выдается соответствующее свидетельство к основному*/}
                {/*        диплому.*/}
                {/*    </MyText>*/}
                {/*</Fade>*/}

                <MyText strong center size={"large"} style={{maxWidth: 1000}}><IntlMessage id={"amOpTitle"}/></MyText>

                <Flex gap={10} style={{width: "100%", maxWidth:1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <MyText strong size={"large"}><IntlMessage id={"amOpTb1Code"}/> <IntlMessage
                        id={"amOpTb1Title"}/></MyText>
                    <table className={styles.studyPlanTable}>
                        <tr>
                            <th><MyText center strong><IntlMessage id={"amOpComponent"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpDisciplineName"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpDisciplineCode"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpCreditNum"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpAcademHrsNum"}/></MyText></th>
                        </tr>
                        <tr className={styles.sectionHeader}>
                            <td colSpan="3"><MyText center strong><IntlMessage id={"amOpBasicDiscipline"}/></MyText>
                            </td>
                            <td colSpan="1"><MyText center strong><IntlMessage
                                id={"amOpBasicDisciplineCN"}/></MyText></td>
                            <td colSpan="1"><MyText center strong><IntlMessage
                                id={"amOpBasicDisciplineAN"}/></MyText></td>
                        </tr>
                        <tbody className={styles.sectionContent}>
                        <tr>
                            <td rowSpan="3"><IntlMessage id={"amOpBdVk"}/></td>
                            <td><IntlMessage id={"amOpHisPhilScience"}/></td>
                            <td>HPS</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpHighSchPedagogy"}/></td>
                            <td>none</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpPedPractice"}/></td>
                            <td>PP 5206</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td rowSpan="2"><IntlMessage id={"amOpBdKv"}/></td>
                            <td><IntlMessage id={"amOpTb1BD1Text"}/></td>
                            <td><IntlMessage id={"amOpTb1BD1Code"}/></td>
                            <td style={{textAlign: "center"}}>5</td>
                            <td style={{textAlign: "center"}}>150</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpTb1BD2Text"}/></td>
                            <td><IntlMessage id={"amOpTb1BD2Code"}/></td>
                            <td style={{textAlign: "center"}}>5</td>
                            <td style={{textAlign: "center"}}>150</td>
                        </tr>
                        </tbody>
                        <tr className={styles.sectionHeader}>
                            <td colSpan="3"><MyText center strong><IntlMessage
                                id={"amOpProfilDiscipline"}/></MyText></td>
                            <td colSpan="1"><MyText center strong><IntlMessage id={"amOpTb1PDCN"}/></MyText></td>
                            <td colSpan="1"><MyText center strong><IntlMessage id={"amOpTb1PDAN"}/></MyText></td>
                        </tr>
                        <tbody className={styles.sectionContent}>
                        <tr>
                            <td><IntlMessage id={"amOpPdVk"}/></td>
                            <td><IntlMessage id={"amOpTb1PD1Text"}/></td>
                            <td><IntlMessage id={"amOpTb1PD1Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1PD1CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1PD1AN"}/></td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpPdKv"}/></td>
                            <td><IntlMessage id={"amOpTb1PD2Text"}/></td>
                            <td><IntlMessage id={"amOpTb1PD2Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1PD2CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1PD2AN"}/></td>
                        </tr>
                        </tbody>
                        <tr className={styles.sectionHeader}>
                            <td colSpan="3"><MyText center strong><IntlMessage id={"amOpTotal"}/></MyText></td>
                            <td><MyText center strong><IntlMessage id={"amOpTb1CN"}/></MyText></td>
                            <td><MyText center strong><IntlMessage id={"amOpTb1AN"}/></MyText></td>
                        </tr>
                    </table>
                </Flex>

                <Flex gap={10} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <MyText size={"large"} strong><IntlMessage id={"amOpTb2Code"}/> <IntlMessage
                        id={"amOpTb2Title"}/></MyText>
                    <table className={styles.studyPlanTable}>
                        <tr>
                            <th><MyText center strong><IntlMessage id={"amOpComponent"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpDisciplineName"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpDisciplineCode"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpCreditNum"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpAcademHrsNum"}/></MyText></th>
                        </tr>
                        <tr className={styles.sectionHeader}>
                            <td colspan="3"><MyText center strong><IntlMessage id={"amOpBasicDiscipline"}/></MyText>
                            </td>
                            <td colSpan="1"><MyText center strong><IntlMessage
                                id={"amOpBasicDisciplineCN"}/></MyText></td>
                            <td colSpan="1"><MyText center strong><IntlMessage
                                id={"amOpBasicDisciplineAN"}/></MyText></td>
                        </tr>
                        <tbody className={styles.sectionContent}>
                        <tr>
                            <td rowspan="3"><IntlMessage id={"amOpBdVk"}/></td>
                            <td><IntlMessage id={"amOpHisPhilScience"}/></td>
                            <td>HPS</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpHighSchPedagogy"}/></td>
                            <td>none</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpPedPractice"}/></td>
                            <td>PP 5206</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td rowspan="2"><IntlMessage id={"amOpBdKv"}/></td>
                            <td><IntlMessage id={"amOpTb2BD1Text"}/></td>
                            <td><IntlMessage id={"amOpTb2BD1Code"}/></td>
                            <td style={{textAlign: "center"}}>5</td>
                            <td style={{textAlign: "center"}}>150</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpTb2BD2Text"}/></td>
                            <td><IntlMessage id={"amOpTb2BD2Code"}/></td>
                            <td style={{textAlign: "center"}}>5</td>
                            <td style={{textAlign: "center"}}>150</td>
                        </tr>
                        </tbody>
                        <tr className={styles.sectionHeader}>
                            <td colspan="3"><MyText center strong><IntlMessage
                                id={"amOpProfilDiscipline"}/></MyText></td>
                            <td colspan="1"><MyText center strong><IntlMessage id={"amOpTb2PDCN"}/></MyText></td>
                            <td colspan="1"><MyText center strong><IntlMessage id={"amOpTb2PDAN"}/></MyText></td>
                        </tr>
                        <tbody id="pd-section" class="section-content">
                        <tr>
                            <td rowspan="2"><IntlMessage id={"amOpPdVk"}/></td>
                            <td><IntlMessage id={"amOpTb2PD1Text"}/></td>
                            <td><IntlMessage id={"amOpTb2PD1Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2PD1CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2PD1AN"}/></td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpTb2PD2Text"}/></td>
                            <td><IntlMessage id={"amOpTb2PD2Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2PD2CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2PD2AN"}/></td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpPdKv"}/></td>
                            <td><IntlMessage id={"amOpTb2PD3Text"}/></td>
                            <td><IntlMessage id={"amOpTb2PD3Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2PD3CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2PD3AN"}/></td>
                        </tr>
                        </tbody>
                        <tr className={styles.sectionHeader}>
                            <td colSpan="3"><MyText center strong><IntlMessage id={"amOpTotal"}/></MyText></td>
                            <td><MyText center strong><IntlMessage id={"amOpTb2CN"}/></MyText></td>
                            <td><MyText center strong><IntlMessage id={"amOpTb2AN"}/></MyText></td>
                        </tr>
                    </table>
                </Flex>


                <Flex gap={10} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <MyText size={"large"} strong><IntlMessage id={"amOpTb3Code"}/> <IntlMessage
                        id={"amOpTb3Title"}/></MyText>
                    <table className={styles.studyPlanTable}>
                        <tr>
                            <th><MyText center strong><IntlMessage id={"amOpComponent"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpDisciplineName"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpDisciplineCode"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpCreditNum"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpAcademHrsNum"}/></MyText></th>
                        </tr>
                        <tr className={styles.sectionHeader}>
                            <td colspan="3"><MyText center strong><IntlMessage id={"amOpBasicDiscipline"}/></MyText>
                            </td>
                            <td colSpan="1"><MyText center strong><IntlMessage
                                id={"amOpBasicDisciplineCN"}/></MyText></td>
                            <td colSpan="1"><MyText center strong><IntlMessage
                                id={"amOpBasicDisciplineAN"}/></MyText></td>
                        </tr>
                        <tbody className={styles.sectionContent}>
                        <tr>
                            <td rowspan="3"><IntlMessage id={"amOpBdVk"}/></td>
                            <td><IntlMessage id={"amOpHisPhilScience"}/></td>
                            <td>HPS</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpHighSchPedagogy"}/></td>
                            <td>none</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpPedPractice"}/></td>
                            <td>PP 5206</td>
                            <td style={{textAlign: "center"}}>4</td>
                            <td style={{textAlign: "center"}}>120</td>
                        </tr>
                        <tr>
                            <td rowspan="2"><IntlMessage id={"amOpBdKv"}/></td>
                            <td><IntlMessage id={"amOpTb3BD1Text"}/></td>
                            <td><IntlMessage id={"amOpTb3BD1Code"}/></td>
                            <td style={{textAlign: "center"}}>5</td>
                            <td style={{textAlign: "center"}}>150</td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpTb3BD2Text"}/></td>
                            <td><IntlMessage id={"amOpTb3BD2Code"}/></td>
                            <td style={{textAlign: "center"}}>5</td>
                            <td style={{textAlign: "center"}}>150</td>
                        </tr>
                        </tbody>
                        <tr className={styles.sectionHeader}>
                            <td colspan="3"><MyText center strong><IntlMessage
                                id={"amOpProfilDiscipline"}/></MyText></td>
                            <td colspan="1"><MyText center strong>10</MyText></td>
                            <td colspan="1"><MyText center strong>300</MyText></td>
                        </tr>
                        <tbody id="pd-section" class="section-content">
                        <tr>
                            <td><IntlMessage id={"amOpPdVk"}/></td>
                            <td><IntlMessage id={"amOpTb3PD1Text"}/></td>
                            <td><IntlMessage id={"amOpTb3PD1Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3PD1CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3PD1AN"}/></td>
                        </tr>
                        <tr>
                            <td><IntlMessage id={"amOpPdKv"}/></td>
                            <td><IntlMessage id={"amOpTb3PD2Text"}/></td>
                            <td><IntlMessage id={"amOpTb3PD2Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3PD2CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3PD2AN"}/></td>
                        </tr>
                        </tbody>
                        <tr className={styles.sectionHeader}>
                            <td colSpan="3"><MyText center strong><IntlMessage id={"amOpTotal"}/></MyText></td>
                            <td><MyText center strong><IntlMessage id={"amOpTb3CN"}/></MyText></td>
                            <td><MyText center strong><IntlMessage id={"amOpTb3AN"}/></MyText></td>
                        </tr>
                    </table>
                </Flex>


                <Flex gap={10} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"flex-start"}>
                    <MyText size={"large"} strong><IntlMessage id={'costs'}/></MyText>
                    <table className={styles.studyPlanTable}>
                        <tr>
                            <th><MyText center strong><IntlMessage id={"opName"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"opCode"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"amOpCreditNum"}/></MyText></th>
                            <th><MyText center strong><IntlMessage id={"totapPrice"}/></MyText></th>
                        </tr>
                        <tbody className={styles.sectionContent}>
                        <tr>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1Title"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb1TotalCost"}/> <IntlMessage
                                id={"tenge"}/></td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2Title"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb2TotalCost"}/> <IntlMessage
                                id={"tenge"}/></td>
                        </tr>
                        <tr>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3Title"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3Code"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3CN"}/></td>
                            <td style={{textAlign: "center"}}><IntlMessage id={"amOpTb3TotalCost"}/> <IntlMessage
                                id={"tenge"}/></td>
                        </tr>
                        </tbody>

                    </table>
                </Flex>


                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Flex vertical justify={"center"} align={"center"} gap={25}
                          style={{width: "100%", position: "relative"}}>
                        <Fade triggerOnce={true} cascade damping={0.15} direction={"down"}>
                            <h1 style={{margin: 0}}>
                                <MyText strong uppercase center size={"large"}><IntlMessage
                                    id={"graduateDiplomas"}/></MyText>
                            </h1>
                        </Fade>

                        <Flex gap={windowSize.width <= 1024 ? "large" : "middle"} justify={"center"}
                              align={"center"}
                              style={{width: "100%"}} vertical={windowSize.width <= 1024}>
                            <Fade triggerOnce={true} cascade damping={0.15}>
                                <Flex gap={40} vertical align={"center"}>
                                    <div style={{width: "100%", height: "100%", maxWidth: 400, maxHeight: 250}}>
                                        {/*<img style={{maxWidth: "100%", maxHeight: "100%"}} src={diplome} alt=""/>*/}
                                        <Image style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}}
                                               src={diplome}/>
                                    </div>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={200}>
                                        <MyText center>
                                            <IntlMessage id={"apMSDegreeCertificate"}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                                <Flex gap={40} vertical align={"center"}>
                                    {/*<img style={{maxWidth: "100%", maxHeight: "100%"}} src={diplome} alt=""/>*/}
                                    <Image.PreviewGroup>
                                        <div style={{width: "100%", height: "100%", maxWidth: 400, maxHeight: 250}}>
                                            <Image width={400} height={250} style={{objectFit: "contain"}}
                                                   src={transcript1}/>
                                            <Image
                                                style={{display: "none"}}
                                                src={transcript2}
                                            />
                                        </div>
                                    </Image.PreviewGroup>
                                    <Fade triggerOnce={true} cascade damping={0.15} direction={"down"} delay={200}>
                                        <MyText center>
                                            <IntlMessage id={"apMSDegreeTranscript"}/>
                                        </MyText>
                                    </Fade>
                                </Flex>
                            </Fade>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex style={{
                width: "100%",
                position: "relative",
                padding: "50px 20px 50px 20px",
                backgroundColor: "#EFEFEF"
            }} align={"center"}
                  justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"flex-start"} justify={"flex-start"}>
                    <Fade triggerOnce={true} cascade style={{width: '100%'}}>
                        <Form form={form} onSubmit={handleSubmit}>
                            <BlockLoading isLoading={loading}/>
                            <div>
                                <p className={cl.bannerFormText}><IntlMessage id={'bannerText2'}/></p>
                                <p className={cl.bannerFormText}><IntlMessage id={'bannerText3'}/></p>
                                <br/>
                            </div>

                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'bannerFormNameError'}/>,
                            },
                            ]} className={classes.formInputBlock} name="name">
                                <Input rootClassName={classes.formInput}
                                       placeholder={IntlMessageText.getText({id: 'name'})}/>
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'bannerFormEmailError'}/>,
                            },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (EmailValidator.isValid(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('emailRules')));
                                    },
                                }),
                            ]} className={classes.formInputBlock} name="email">
                                <Input rootClassName={classes.formInput}
                                       placeholder={IntlMessageText.getText({id: 'email'})}/>
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'bannerFormTelError'}/>,
                            },
                            ]} className={classes.formInputBlock} name="telNumber">
                                <InputMask
                                    mask="+9 (999) 999-9999"
                                    className={classes.formInput}
                                    placeholder={IntlMessageText.getText({id: 'telNumber'})}
                                >
                                    {(inputProps) => <Input {...inputProps} />}
                                </InputMask>
                                {/*<Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'telNumber'})}/>*/}
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} className={classes.formInput} style={{width: "100%", padding: 0}} name="categoryId">
                                <Select
                                    options={categories}
                                    placeholder={<IntlMessage id={'categoryCourse'}/>}
                                />
                            </FormItem>
                            <FormItem className={classes.formInputBlock}>
                                <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue}
                                                fontColor={clrs.white}>
                                        <IntlMessage id={'send'}/>
                                    </GreyButton>
                                </Flex>
                            </FormItem>
                        </Form>
                    </Fade>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

const InfoCard = ({text, icon}) => {
    const windowSize = useWindowSize();
    return (
        <Flex gap={10} align={"center"} justify={"center"} vertical>
            {icon}
            <MyText size={windowSize.width <= 768 ? "small" : "default"} strong style={{color: clrs.blackerBlue, maxWidth: 360, textAlign: "center"}}>
                {text}
            </MyText>
        </Flex>
    )
}

const StructureCard = ({text, icon}) => {
    return (
        <Flex gap={10} align={"center"} justify={"center"} vertical
              style={{
                  border: '1px solid black',
                  padding: 60,
                  borderRadius: 4
              }}>
            {icon}
            <MyText strong style={{color: clrs.blackerBlue, maxWidth: 400, textAlign: "center"}}>
                {text}
            </MyText>
        </Flex>
    )
}

export default AdditionalMasterPage;